import { Row, Col, Card, Button } from "antd";
import React from "react";
import Comment from "../../../../assets/images/Comment.png";
import Menu from "../../../../assets/icons/menu.svg";
import { useNavigate } from "react-router-dom";

const BookNameOutline = ({ link, name, title, description, mod = "auther" }) => {
	const navigate = useNavigate();

	const styles = {
		outlineHeading: {
			fontWeight: 800,
			fontSize: "26px",
			lineHeight: "26px",
			letterSpacing: "-0.5px",
			marginBottom: "10px",
			fontFamily: "Inter SemiBold",
		},
		outlineDesc: {
			fontSize: "14px",
			color: "#575A60",
			paddingBottom: "10px",
		},
		outlineCardLink: {
			fontWeight: 400,
			fontSize: "14px",
			letterSpacing: "-0.02em",
			textDecoration: "underline",
			color: "#575A60",
		},
		cardStyle: {
			boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06)",
			borderRadius: "8px",
		},
		backButton: {
			fontWeight: "bolder",
			fontSize: "15px",
			cursor: "pointer",
		},
		commentButton: {
			height: "10px",
			padding: "10px",
			background: "#FF3742",
		},
		menuIcon: {
			background: "#EBE9F1",
			borderRadius: "15px",
			width: "23px",
			height: "30px",
		},
	};

	return (
		<div className="site-card-border-less-wrapper">
			<Card bordered={false} style={styles.cardStyle}>
				<Row>
					<Col sm={20} xs={20} md={20} lg={20} xl={20}>
						{mod !== 'guest' && <span onClick={() => navigate(link)} style={styles.backButton}>
							<i className="fa-sharp fa-solid fa-chevron-left"></i>
						</span>}
						<div className="ml-3" style={{ marginTop: "-25px" }}>
							<h1 style={styles.outlineHeading}>
								{name} {title ? ":" : ""} {title}
							</h1>
							<div style={{ width: "80%", paddingBottom: "10px" }}>
								<p style={styles.outlineDesc}>{description}</p>
								<p style={styles.outlineCardLink}>
									Learn how to use this feature
								</p>
							</div>
						</div>
					</Col>
					{/* <Col sm={4} xs={4} md={4} lg={4} xl={4}>
						<div className="d-flex ml-2 align-items-center">
							{mod !== 'guest' && (<><img src={Comment} alt="Comment" className="mr-1" />
								<Button type="primary" danger style={styles.commentButton}>
									2 comments
								</Button></>)}
							{mod !== 'guest' && <div className="ml-5">
								<img src={Menu} alt="menu-icon" style={styles.menuIcon} />
							</div>}
						</div>
					</Col> */}
				</Row>
			</Card>
		</div>
	);
};

export default BookNameOutline;
