import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { UNAUTHENTICATED_ROUTES } from "../constant/routeConstant";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import GuestView from "../pages/screen/guestview/guestview";
import { authenticatedRoutes } from "./authenticated";

const NotFound = () => <div>404 - Page Not Found</div>;

const Unauthenticated = () => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();

  const publicRoutes = [
    { path: UNAUTHENTICATED_ROUTES.SIGN_IN, element: <SignIn /> },
    { path: UNAUTHENTICATED_ROUTES.SIGN_UP, element: <SignUp /> },
    { path: "/guest-view/:type/:id", element: <GuestView /> },
  ];

  const isAuthenticatedRoute = authenticatedRoutes.some((route) =>
    location.pathname.includes(route.path)
  );

  if (isAuthenticatedRoute || location.pathname === "/*" || location.pathname === "/") {
    return <Navigate to="/sign-in" />;
  }

  return (
    <div className="App">
      <Routes>
        {publicRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </div>
  );
};

export default Unauthenticated;
