import { Form, Input, Card, Spin, Button, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { signUpFunc } from "../../redux/Slice/AuthSlice";
import "./login.scss";

const { Title, Text } = Typography;

const SignUp = () => {
	const dispatch = useDispatch();
	const { success, loading } = useSelector((state) => state.auth);
	const navigate = useNavigate();

	const register = (e) => {
		let data = { ...e };
		dispatch(signUpFunc({ data }));

		if (success) {
			// navigate("/sign-in");
		}
	};

	const antIcon = (
		<LoadingOutlined
			style={{
				fontSize: 24,
			}}
			spin
		/>
	);

	return (
		<section
			style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", backgroundColor: "#f0f2f5" }}
			className="login-container"
		>
			<Card className="cont" style={{ width: 400, padding: "20px", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
				<Title level={2} className="signin_heading" style={{ textAlign: "center", marginBottom: "20px" }}>Sign Up</Title>
				<div className="form sign-in">
					<Form
						onFinish={register}
						layout="vertical"
						initialValues={{
							remember: true,
						}}
						autoComplete="off"
						className="gx-signin-form gx-form-row0"
					>
						<Form.Item
							label="Name"
							name="name"
							rules={[
								{
									required: true,
									message: "Name is required",
								},
							]}
						>
							<Input placeholder="Enter your name" />
						</Form.Item>
						<Form.Item
							label="Email"
							name="email"
							rules={[
								{
									required: true,
									message: "Email is required",
								},
								{
									type: "email",
									message: "The input is not valid E-mail!",
								},
							]}
						>
							<Input placeholder="Enter your email" />
						</Form.Item>
						<Form.Item
							label="Password"
							name="password"
							rules={[
								{
									required: true,
									message: "Password is required",
								},
							]}
						>
							<Input.Password placeholder="Enter your password" />
						</Form.Item>
						<Button type="primary" htmlType="submit" className="submit" disabled={loading} block>
							Sign Up {loading ? <Spin indicator={antIcon} className="pl-2" /> : ""}
						</Button>
					</Form>
					<Text style={{ display: "block", textAlign: "center", marginTop: "20px" }}>
						Already have an account? <Link to="/sign-in">Sign In</Link>
					</Text>
				</div>
			</Card>
		</section>
	);
};

export default SignUp;
