import React, { useEffect, useState } from "react";

import { Drawer, Button } from "antd";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import newBook from "../../assets/images/newbook.png";
import "./addEvent.css";
import { getColorData } from "../../redux/Slice/ColorSlice";
import UserGallery from "../gallery/UserGallery";
import TextInput from "../Inputs/TextInput";
import TextAreaInput from "../Inputs/TextAreaInput";
import axios from "axios";

const EditOutlineDrawer = ({
	outline,
	open,
	close,
	books,
	getOutlineByBook,
}) => {
	const selectColor = {
		value: "",
		label: "-- Select Color --",
	};
	const selectBook = {
		value: "",
		label: "-- Select Book --",
	};

	const [outlineName, setOutlineName] = useState("");
	const [outlineTitle, setOutlineTitle] = useState("");
	const [outlineDescription, setOutlineDescription] = useState("");
	const [selectedFile, setSelectedFile] = useState(null);
	const [showGallery, setShowGallery] = useState(false);
	const [loading, setLoading] = useState(false);
	const { colors } = useSelector((state) => state.colors);

	const [colorOptions, setColorOptions] = useState([]);
	const [selectedColor, setSelectedColor] = useState(null);
	const [selectedBook, setSelectedBook] = useState(null);

	const [error, setError] = useState({});

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getColorData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (outline) {
			setOutlineName(outline.outline_name);
			setOutlineTitle(outline.outline_title);
			setOutlineDescription(outline.description);
			setSelectedFile({
				id: outline.image_id,
				image_url: outline.image_url,
			});
			setSelectedColor({
				value: outline.color_id,
				label: outline.color,
			});
			setSelectedBook({
				value: outline.book.book_id,
				label: outline.book.book_name,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [outline]);

	useEffect(() => {
		const options = colors.map((color) => {
			return {
				value: color.id,
				label: color.color,
			};
		});

		setColorOptions(() => [selectColor, ...options]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colors]);

	const updateOutline = (e) => {
		const token = localStorage.getItem("token");
		const outlineData = {
			book_id: selectedBook.value,
			outline_name: outlineName,
			outline_title: outlineTitle,
			description: outlineDescription,
			color_id: selectedColor.value,
			image_id: selectedFile?.id ?? null,
		};

		setLoading(true);
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/outlines/${outline.id}`,
				outlineData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: "application/json",
					},
				}
			)
			.then(() => {
				getOutlineByBook(selectedBook);
				setSelectedBook(null);
				setSelectedColor(null);
				setSelectedFile(null);
				setOutlineName("");
				setOutlineTitle("");
				setOutlineDescription("");
				setError({});
				setLoading(false);
				close();
			})
			.catch((error) => {
				if (error.response.status === 422) {
					setError(error.response.data.errors);
				}
				setLoading(false);
			});
	};

	return (
		outline && (
			<Drawer
				title={"Edit Outline"}
				placement="right"
				onClose={close}
				open={open}
				className="p-1 drawer"
				zIndex={0}
				autoFocus={false}
				mask={false}
			>
				<form>
					<label>Artwork</label>
					{showGallery && (
						<UserGallery
							setSelectedFile={(g) => {
								setSelectedFile(g);
							}}
							setShowGallery={setShowGallery}
						/>
					)}
					<div className="artwork mt-3">
						<button
							className="btn-artwork"
							type="button"
							onClick={() => setShowGallery(!showGallery)}
						>
							<img src={selectedFile?.image_url ?? newBook} alt="Book Icon" />
						</button>
						{selectedFile && (
							<div className="artworkContainer mt-2">
								<button
									onClick={() => {
										setSelectedFile(null);
									}}
									type="button"
									className="btn-remove"
								>
									Remove
								</button>
							</div>
						)}
					</div>
					<div className="mt-3">
						<div className="form-input">
							<label>
								Select Book: <span className="required">*</span>
							</label>
							<Select
								options={[selectBook, ...books]}
								onChange={(e) => {
									setSelectedBook(e);
								}}
								isSearchable={true}
								className="select-book"
								value={selectedBook}
							/>
							{error.book_id && <span className="error">{error.book_id}</span>}
						</div>
					</div>
					<div className="mt-3">
						<TextInput
							id="name"
							name="outline_name"
							label="Name"
							maxLength={30}
							required
							value={outlineName}
							onChange={(e) => setOutlineName(e.target.value)}
							error={error.outline_name}
						/>
					</div>
					<div className="mt-3">
						<TextInput
							id="title"
							name="outline_title"
							label="Title"
							maxLength={30}
							required
							value={outlineTitle}
							error={error.outline_title}
							onChange={(e) => setOutlineTitle(e.target.value)}
						/>
					</div>
					<div className="mt-3">
						<TextAreaInput
							id="description"
							name="description"
							label="Description"
							maxLength={2048}
							showCount={true}
							value={outlineDescription}
							error={error.description}
							onChange={(e) => setOutlineDescription(e.target.value)}
						/>
					</div>
					<div className="mt-3">
						<div className="form-input">
							<label>Select Color: </label>
							<Select
								options={colorOptions}
								onChange={(e) => {
									setSelectedColor(e);
								}}
								isSearchable={true}
								className="select-book"
								value={selectedColor}
							/>
							{error.color_id && (
								<span className="error">{error.color_id}</span>
							)}
						</div>
					</div>

					<div className="buttons-container mt-3 d-flex space-between">
						<Button
							type="primary"
							className="mr-2"
							loading={loading}
							disabled={loading}
							onClick={updateOutline}
						>
							Save
						</Button>
						<Button type="ghost" onClick={() => close()}>
							Cancel
						</Button>
					</div>
				</form>
			</Drawer>
		)
	);
};

export default EditOutlineDrawer;
