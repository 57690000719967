import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  .chapterModal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
`;

const ModalContent = styled.div`
  padding: 1rem;
  text-align: center;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
`;

const FeatureItem = styled.li`
  font-size: 14px;
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  font-size: 14px;
  margin-bottom: 2rem;
`;

const SubscribeButton = styled.button`
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #1890ff;
  border-radius: 5px;
  text-decoration: none;
`;

export const SubscriptionModal = ({ open, ok, cancel, features }) => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate('/plans')
  }
  return (
    <StyledModal
      className="chapterModal"
      title="Package Subscription"
      open={open}
      onOk={ok}
      onCancel={cancel}
      mask={true}
      centered={true}
      width={500}
    >
      <ModalContent>
        <Title>You have not subscribed to the following features:</Title>
        <FeatureList>
          {features?.map((feature) => (
            <FeatureItem key={feature.feature}>- {feature.feature}  🚫</FeatureItem>
          ))}
        </FeatureList>
        <Description>
          Subscribe now to unlock all features and enhance your experience.
        </Description>
        <SubscribeButton onClick={handleRedirect}>Subscribe Now</SubscribeButton>
      </ModalContent>
    </StyledModal>
  );
};
