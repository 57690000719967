import { Modal, Image } from "antd";
import React, { useEffect, useState } from "react";
import useDebounce from "../../../../hooks/useDebounce";
import TextInput from "../../../../components/Inputs/TextInput";
import axios from "axios";

const CreateNewGroup = ({ styles, onCancel }) => {
	const [groupName, setGroupName] = useState("");
	const [searchText, setSearchText] = useState("");
	const searchValue = useDebounce(searchText, 100);
	const [users, setUsers] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);

	const headers = {
		headers: {
			Authorization: `Bearer ${localStorage.getItem("token")}`,
			Accept: "application/json",
		},
	};

	useEffect(() => {
		searchUsers(searchValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	const searchUsers = (s) => {
		if (s) {
			setLoading(true);
			axios(
				`${process.env.REACT_APP_API_URL}/search-contact?query=${s}`,
				headers
			)
				.then((response) => {
					setUsers(response.data.data);
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
				});
		} else {
			setUsers([]);
		}
	};

	const selectUser = (id) => {
		if (selectedUsers.includes(id)) {
			setSelectedUsers(selectedUsers.filter((user) => user !== id));
		} else {
			setSelectedUsers([...selectedUsers, id]);
		}
	};

	const saveGroup = () => {
		const payload = {
			group_name: groupName,
			members: selectedUsers,
		};
		setLoading(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}/groups`, payload, headers)
			.then((response) => {
				setUsers(response.data.data);
				setError({});
				setLoading(false);
				onCancel();
			})
			.catch((err) => {
				setLoading(false);
				setError(err.response.data.errors);
			});
	};
	return (
		<Modal
			title="Create New Group"
			okText="Save Group"
			onOk={saveGroup}
			open={true}
			centered={true}
			onCancel={onCancel}
			confirmLoading={loading}
		>
			<TextInput
				id="groupName"
				name="groupName"
				required={true}
				value={groupName}
				onChange={(e) => setGroupName(e.target.value)}
				label="Group Name"
				error={error.group_name}
			/>
			<TextInput
				id="searchUsers"
				name="searchUsers"
				required={true}
				value={searchText}
				onChange={(e) => setSearchText(e.target.value)}
				label="Select Users"
			/>
			{users.length > 0 &&
				users.map((user) => (
					<div className={`${styles.searchContacts}`}>
						<button
							type="button"
							onClick={() => selectUser(user.id)}
							className={
								selectedUsers.includes(user.id) ? styles.contactSelected : ""
							}
						>
							<div className={styles.searchContactImage}>
								<Image src={user.avatar} preview={false} />
							</div>
							<div className={styles.chatDetails}>{user.name}</div>
						</button>
					</div>
				))}
		</Modal>
	);
};

export default CreateNewGroup;
