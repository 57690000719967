import { Drawer, Input, Button, Select, Switch, Form, Checkbox } from "antd";
// import face1 from "../assets/images/face-1.jpg";
// import face2 from "../assets/images/face-2.jpg";
// import douglas from "../assets/images/douglas.png";

import React, { useCallback, useEffect, useState } from "react";
import "./outline-side-modal/outlinemodal.css";
import newBook from "../assets/images/newbook.png";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { getColorData } from "../redux/Slice/ColorSlice";
import { getBookOfSeries } from "../redux/Slice/SeriesSlice";
import UserGallery from "./gallery/UserGallery";

export const UpdateSeriesDrawer = ({ open, close, onSubmit, id }) => {
	const [selectedFile, setSelectedFile] = useState({});
	const [showGallery, setShowGallery] = useState(false);

	const { colors } = useSelector((state) => state.colors);
	const { seriesData, booksOfSeries } = useSelector((state) => state.series);
	const { book_data } = useSelector((state) => state.books);

	const [bookList, setBookList] = useState({
		books: [],
	});

	const updateData =
		seriesData[0]?.length > 0 &&
		seriesData[0]?.filter((series) => series?.id === id);

	const seriesName = updateData[0]?.series_name;
	const description = updateData[0]?.series_description;
	const color = updateData[0]?.color_id;
	const [image, setImage] = useState();
	const image_id = updateData[0]?.image_id;
	const isFinished = updateData[0]?.is_finished;
	const checkedBooks = updateData[0]?.books;
	console.log("checkedBooks", checkedBooks);

	const handleChange = (e) => {
		let value = e;
		setBookList({
			...bookList,
			value,
		});
	};
	const dispatch = useDispatch();

	const colors_data = useCallback(() => {
		dispatch(getColorData());
	}, [dispatch]);

	const [form] = Form.useForm();

	const checkedBookValue = checkedBooks.map((book) => book.id);
	useEffect(() => {
		colors_data();
		dispatch(getBookOfSeries(id));
		setImage(updateData[0]?.image_url ?? newBook);
		setSelectedFile({
			id: updateData[0]?.image_id,
			image_url: updateData[0]?.image_url,
		});
		form.setFieldsValue({
			image_id: image,
			series_name: seriesName,
			series_description: description,
			color_id: color,
			is_finished: isFinished,
			books: checkedBooks,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = (e) => {
		let data = { ...e };
		if (selectedFile?.image_url || image) {
			data.image_id = selectedFile?.id ?? image_id;
		}
		if (selectedFile?.image_url == null) {
			data.image_id = null;
		}
		data.is_finished = e.is_finished === true ? 1 : 0;
		data.books = bookList.value;

		onSubmit(data);
	};

	return (
		<>
			<Drawer
				title="Update Series"
				placement="right"
				onClose={close}
				open={open}
				className="p-1 drawer data-drawer"
				zIndex={1}
				autoFocus={false}
				mask={false}
				style={{ position: "fixed", width: "auto" }}
			>
				<Form form={form} onFinish={handleSubmit}>
					<label>Artwork</label>
					{showGallery && (
						<UserGallery
							setSelectedFile={setSelectedFile}
							setShowGallery={setShowGallery}
						/>
					)}
					<div className="artwork mt-3">
						<button
							className="btn-artwork"
							type="button"
							onClick={() => setShowGallery(!showGallery)}
						>
							<img src={selectedFile?.image_url ?? newBook} alt="Book Icon" />
						</button>
						{selectedFile && (
							<div className="artworkContainer mt-2">
								<button
									onClick={(e) => {
										e.preventDefault();

										setSelectedFile(null);
									}}
									type="button"
									className="btn-remove"
								>
									Remove
								</button>
							</div>
						)}
					</div>
					<Form.Item
						label="Title"
						name="series_name"
						labelCol={{ span: 24 }}
						rules={[
							{
								required: true,
								message: "Name is required",
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label="Description"
						name="series_description"
						labelCol={{ span: 24 }}
						rules={[
							{
								required: true,
								message: "Description is required",
							},
						]}
					>
						<TextArea
							style={{ padding: "6px" }}
							name="book_description"
						></TextArea>
					</Form.Item>
					<Form.Item
						label="Color"
						name="color_id"
						labelCol={{ span: 24 }}
						rules={[
							{
								required: true,
								message: "Color is required",
							},
						]}
					>
						<Select options={colors} placeholder="Select Color" />
					</Form.Item>

					<div className="mt-2">
						<label htmlFor="checkbox">Other books in series name</label>
						<br />
						<div
							id="checkbox-group"
							style={{ display: "flex", flexDirection: "column" }}
						>
							<Checkbox.Group
								options={book_data[0]}
								defaultValue={checkedBookValue}
								onChange={handleChange}
								name="books"
							/>
						</div>
						<br />
					</div>

					<div className="mt-1 ">
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<label htmlFor="finish" className="mr-5">
								Is the Series finished
							</label>
							<span className="ml-5">
								<Form.Item name="is_finished">
									<Switch defaultChecked={isFinished === 1 ? true : false} />
								</Form.Item>
							</span>
						</div>
					</div>

					<div className="buttons-container d-flex mt-2">
						<button
							type="submit"
							className="mr-2"
							style={{ cursor: "pointer" }}
						>
							Update
						</button>
						<Button onClick={() => close()} type="ghost">
							Cancel
						</Button>
					</div>
				</Form>
			</Drawer>
		</>
	);
};
