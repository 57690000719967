import React, { createContext, useState } from "react";

export const ChatDataContext = createContext({
	selectedFeature: {},
	setSelectedFeature: () => {},
});

export default function ChatContext({ children }) {
	const [selectedFeature, setSelectedFeature] = useState({});

	return (
		<ChatDataContext.Provider
			value={{
				selectedFeature,
				setSelectedFeature,
			}}
		>
			{children}
		</ChatDataContext.Provider>
	);
}
