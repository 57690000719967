import React from 'react'
import { Dropdown, Image } from 'antd';
import lightingBoltIcon from "../../../../assets/images/lighting-bolt-icon.png";
import CommandIcon from "../components/CommandIcon";

const CommandMenu = ({handleCommand, styles}) => {
    const commands = [
		{
		  key: '1',
		  label: <CommandIcon commandType="timeline" title="Timeline" description="/timeline [query]" onClick={handleCommand} styles={styles} iconColor="#AED8FF"/>,
		},
		{
			key: '2',
			label: <CommandIcon commandType="brainstorm" title="Brainstorm" description="/brainstorm [query]" onClick={handleCommand} styles={styles} iconColor="#7E5DEF"/>,
		},
		{
			key: '3',
			label: <CommandIcon commandType="outline" title="Outline" description="/outline [query]" onClick={handleCommand} styles={styles} iconColor="#52DDD2"/>,
		},
		{
			key: '4',
			label: <CommandIcon commandType="plot" title="Plot" description="/plot [query]" onClick={handleCommand} styles={styles} iconColor="#FEB62A"/>,
		},
		{
			key: '5',
			label: <CommandIcon commandType="book" title="Book" description="/book [query]" onClick={handleCommand} styles={styles} iconColor="#FFFFFF"/>,
		},
		{
			key: '6',
			label: <CommandIcon commandType="series" title="Series" description="/series [query]" onClick={handleCommand} styles={styles} iconColor="#FFFFFF"/>,
		},
	]
  return (
    <Dropdown
        menu={{items: commands}}
        placement="top"
        trigger={['click']}
        arrow
    >
        <Image src={lightingBoltIcon} preview={false} style={{cursor: "pointer"}} />
    </Dropdown>
  )
}

export default CommandMenu