import React from "react";
import { Input } from "antd";
const TextInput = ({
	name,
	label,
	required,
	error,
	maxLength,
	value,
	onChange,
	placeholder,
	className = "",
}) => {
	return (
		<div className="form-input mb-3">
			<label htmlFor={name}>
				{label} {required && <span className="required">*</span>}
			</label>
			<Input
				id={name}
				className={className}
				status={error ? "error" : ""}
				maxLength={maxLength}
				onChange={onChange}
				value={value}
				placeholder={placeholder}
			/>
			{error && <span className="error">{error}</span>}
		</div>
	);
};

export default TextInput;
