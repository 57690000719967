// reducers/wordsReducer.js
export const INCREMENT_WORDS_COUNT = 'INCREMENT_WORDS_COUNT';
export const RESET_WORDS_COUNT = 'RESET_WORDS_COUNT';

const initialState = {
  count: 0,
  lastUpdate: new Date().toISOString().slice(0, 10), 
};

const wordsReducer = (state = initialState, action) => {
  const today = new Date().toISOString().slice(0, 10);
  switch (action.type) {
    case INCREMENT_WORDS_COUNT:
      if (state.lastUpdate === today) {
        return {
          ...state,
          count: state.count + action.payload,
        };
      } else {
        return {
          ...state,
          count: action.payload,
          lastUpdate: today,
        };
      }
    case RESET_WORDS_COUNT:
      return {
        ...state,
        count: 0,
        lastUpdate: today,
      };
    default:
      return state;
  }
};

export const incrementWordsCount = (count) => ({
  type: INCREMENT_WORDS_COUNT,
  payload: count,
});

export const resetWordsCount = () => ({
  type: RESET_WORDS_COUNT,
});

export default wordsReducer; 
