import moment from "moment";

export function disabledDate(current) {
	// Can not select days before today and today
	return current && current < moment().startOf('day');
}
export const footer = () => 
	<div style={{ display: "flex", justifyContent: "space-between", padding: "0 10px" }}>
		<div>HH</div>
		<div>MM</div>
	</div>
