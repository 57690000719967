import {
  Space,
  Col,
  Row,
  Input,
  Button,
  Form,
  Select,
  Modal,
  Popover,
  message,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import OutlineChapterCard from "../../../components/cards/outline-cards/outline-chapter/OutlineChapterCard";
import face1 from "../../../assets/images/face-1.jpg";
import face2 from "../../../assets/images/face-2.jpg";
import face3 from "../../../assets/images/face-3.jpg";
import Menuu from "../../../assets/icons/menu.svg";
import OutlineSideModal from "../../../components/outline-side-modal/OutlineEventDrawer";
import EventItemCard from "../../../components/event-item-button/EventIem";
import BookNameOutline from "../../../components/cards/outline-cards/book-name-outline/BookNameOutlineCard";
import UpdateChapterModal from "../../../components/UpdateChapterModal";
import FullPageSpinner from "../../../components/loader/FullPageSpinner";

import {
  deleteChapter,
  getChapters,
  updateChapter,
} from "../../../redux/Slice/ChapterSlice";
import { getOutline } from "../../../redux/Slice/OutlineSlice";
import {
  deleteChapterCard,
  getChapterCards,
} from "../../../redux/Slice/ChapterCardsSlice";
import { AUTHENTICATED_ROUTES } from "../../../constant/routeConstant";
import { apiServices } from "../../../services/apiServices/Api";

import "../../../components/outline-side-modal/outlinemodal.css";
import { axiosInstance } from "../../../services/apiServices/config";
import { useOutlineDetail } from "../../../hooks/outline/useOutlineDetial";

const Content = ({ c_id, close }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const deletechapter = (cid) => {
    dispatch(deleteChapter(cid));
    setTimeout(() => {
      dispatch(getChapters({ id }));
    }, 1000);
    close();
  };

  const showModal = () => setIsModalOpen(true);
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);

  const EditChapter = (data) => {
    try {
      const payload = { id: c_id, data: data };
      dispatch(updateChapter(payload));
      setTimeout(() => {
        dispatch(getChapters({ id }));
        // dispatch(getChapterCards(c_id));
      }, 1000);
      close();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="edit">
        <button
          onClick={(e) => {
            e.preventDefault();
            showModal();
            close();
          }}
        >
          Edit
          <span className="ml-2">
            <i className="fa fa-check"></i>
          </span>
        </button>
      </div>

      <div className="delete-btn">
        <button onClick={() => deletechapter(c_id)}>
          Delete
          <span className="ml-2">
            <i className="fa fa-times"></i>
          </span>
        </button>
      </div>
      <UpdateChapterModal
        open={isModalOpen}
        ok={handleOk}
        cancel={handleCancel}
        onSubmit={EditChapter}
        id={id}
        chapter_id={c_id}
      />
    </div>
  );
};

const EditContent = ({ c_id, close, tasks, chapterId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [todos, setTodos] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();

  const getTodos = async (id) => {
    try {
      const response = await apiServices.get(
        `${process.env.REACT_APP_API_URL}/card-tasks-list/${id}`,
        {}
      );
      const data = response.data;
      setTodos(data);
      return data;
    } catch (err) {
      console.log("Error while getting todo", err);
    }
  };

  const deletechapter = (cid) => {
    dispatch(deleteChapterCard(cid));
    setTimeout(() => {
      dispatch(getChapters({ id }));
    }, 1000);
    close();
  };

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  const EditCard = async (data) => {
    try {
      const res = await axiosInstance.put(`/chapters-cards/${data.id}`, data).then((res) => {
        if (res.data) {
          message.success('successfully updated');
          dispatch(getChapters({ id }));
          // dispatch(getChapterCards({id}));
          dispatch(getOutline({ id }));
          close();
        } else {
          message.error('something went wrong');
        }
      })
      return true
    } catch (e) {
      console.log(e);
      return false
    }
  };

  return (
    <div>
      <button
        className="edit p-1 px-5"
        style={{ border: 'none' }}
        onClick={() => {
          showModal();
          close();
        }}
      >
        Edit
      </button>
      <div className="delete-btn p-1">
        <button onClick={() => deletechapter(c_id)}>
          Delete
        </button>
      </div>
      <OutlineSideModal
        open={isModalOpen}
        close={handleCancel}
        onsubmit={EditCard}
        chapterCardId={c_id}
        chapter_id={chapterId}
      />
    </div>
  );
};

const OutlineDetail = (props) => {
  const { mod = "auther" } = props;
  const {
    hasEditor,
    id,
    isModalOpen,
    popupVisible,
    openIndex,
    title,
    name,
    desc,
    chapters,
    loading,
    chapterCards,
    colors,
    form,
    showModal,
    handleOk,
    handleCancel,
    SubmitChapters,
    close,
    open,
    isEditorModalVisible,
    showEditorModal,
    handleEditorModalOk,
    handleEditorModalCancel,
  } = useOutlineDetail({ mod });

  const avatar = [
    { id: 1, imgName: face1 },
    { id: 2, imgName: face2 },
    { id: 3, imgName: face3 },
  ];

  return (
    <div className="main-container">
      <BookNameOutline 
        link={AUTHENTICATED_ROUTES.OUT_LINE} 
        name={loading ? "Loading Name..." : name} 
        description={loading ? "Loading Description..." : desc} 
        mod={mod} 
      />
      {loading ? <FullPageSpinner /> : (<>
        <Row gutter={24}>
          {chapters[0]?.data?.chapters?.length > 0 &&
            chapters[0]?.data?.chapters?.map((c) => (
              <Col sm={12} xs={12} lg={6} md={6} xl={6} key={c.id}>
                <div className="chapter-name mt-4 mb-3" style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="d-flex">
                      <span
                        style={{
                          width: "18px",
                          height: "18px",
                          background: c.color_code,
                          borderRadius: "110px",
                        }}
                        className="mr-2"
                      ></span>
                      <p style={{ color: "black", fontWeight: "bold" }} id={c.id}>
                        {c.chapter_name}
                      </p>
                    </div>

                    {hasEditor && mod !== 'guest' ? <Popover
                      open={c.id + 'card' === openIndex && popupVisible}
                      id={c.id}
                      placement="right"
                      content={<Content c_id={c.id} path_id={id} close={close} />}
                      title="Actions"
                      trigger="click"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={Menuu}
                        alt="Menu Icon"
                        id={c.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => open(c.id + 'card')}
                      />
                    </Popover> : (mod !== 'guest' && <img
                      src={Menuu}
                      alt="Menu Icon"
                      id={c.id}
                      style={{ cursor: "pointer" }}
                      onClick={showEditorModal}
                    />)}
                  </div>
                </div>
                {c.cards?.map((card) => (
                  <div key={card?.id}>
                    {hasEditor && mod !== 'guest' ? <Popover
                      open={card?.id === openIndex && popupVisible}
                      content={
                        <EditContent
                          tasks={card?.tasks}
                          close={close}
                          c_id={card?.id}
                          chapterId={c.id}
                          path_id={id}
                        />
                      }
                      trigger="click"
                      placement="right"
                      style={{ cursor: "pointer" }}
                    >
                      {/* <FullPageSpinner /> */}
                      <OutlineChapterCard
                        id={card?.id}
                        Data={card}
                        open={() => open(card?.id) && close()}
                        pill={card?.card_title}
                        desc={card?.card_description}
                        color={card?.foreground_color}
                        tasks={card?.tasks}
                        mod={mod}
                        hasEditor={hasEditor}
                        bgcolor={card?.color_code}
                        sent="1"
                        comments="5"
                        avatar={avatar}
                      />
                    </Popover> : (
                      <>
                        {/* <FullPageSpinner /> */}
                        <OutlineChapterCard
                          id={card?.id}
                          Data={card}
                          open={() => open(card?.id) && close()}
                          pill={card?.card_title}
                          desc={card?.card_description}
                          color={card?.foreground_color}
                          tasks={card?.tasks}
                          bgcolor={card?.color_code}
                          sent="1"
                          comments="5"
                          avatar={avatar}
                          showEditorModal={showEditorModal}
                          hasEditor={hasEditor}
                          mod={mod}
                        />
                      </>
                    )}

                  </div>
                ))}
                {mod !== 'guest' && <EventItemCard event_id={c.id} hasEditor={hasEditor && mod !== 'guest'} showEditorModal={showEditorModal} />}
              </Col>
            ))}
          {mod !== 'guest' && <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Button onClick={hasEditor && mod !== 'guest' ? showModal : showEditorModal} className="mt-4">
              <Space>New Chapter</Space>
            </Button>
            <Modal
              className="chapterModal"
              title="Add New Chapter"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              mask={false}
              width={350}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                position: "relative",
                marginTop: "12%",
                padding: "1.2rem",
                height: "20rem",
              }}
            >
              <Form
                layout="horizontal"
                initialValues={{ remember: true }}
                form={form}
                onFinish={SubmitChapters}
                autoComplete="off"
                className="gx-signin-form gx-form-row0"
                style={{ maxWidth: 1000 }}
              >
                <Form.Item
                  style={{ padding: ".5rem" }}
                  label="Chapter Name"
                  name="chapter_name"
                  labelCol={{ span: 24 }}
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  style={{ padding: ".5rem" }}
                  label="Color"
                  name="color_id"
                  labelCol={{ span: 24 }}
                  rules={[{ required: true, message: "Color is required" }]}
                >
                  <Select options={colors} placeholder="Select Color" />
                </Form.Item>
                <Form.Item style={{ padding: ".5rem", textAlign: "center" }}>
                  <Button type="primary" htmlType="submit">
                    Add Chapter
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </Col>}
        </Row>
      </>)}
      <Modal
        title="Access Denied"
        visible={isEditorModalVisible}
        onOk={handleEditorModalOk}
        onCancel={handleEditorModalCancel}
        centered
        footer={null}
      >
        <p>You do not have permission to perform this action.</p>
      </Modal>
    </div>
  );
};

export default OutlineDetail;
