import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { apiServices } from "../../services/apiServices/Api";
// get dashboard
export const getDashboard = createAsyncThunk(
    "dashboard/getDashboard",
    async () => {
        try {
            const response = await apiServices.get("/get-dashboard",
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("token")}`,
                    },
                });
            return response;
        } catch (err) {
            return err;
        }
    }
);
const initialState = {
    task_count: 0,
    calender_count: 0,
    totalBooks: 0,
    totalSeries: 0,
    loading: false,
    error: null,
};
const DashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        dashboardSeliceReset : (state) => {
            Object.assign(state, initialState);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDashboard.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDashboard.fulfilled, (state, action) => {
                state.loading = false;
                state.task_count = action.payload.complete;
                state.calender_count = action.payload.total_task;
                state.totalBooks = action.payload.books;
                state.totalSeries = action.payload.series;
            })
            .addCase(getDashboard.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});
const DashBoardReducer = DashboardSlice.reducer;
export const { dashboardSeliceReset } = DashboardSlice.actions;
export default DashBoardReducer;