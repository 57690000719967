import React, { useContext } from "react";
import styles from "./Card.module.css";
import { ChatDataContext } from "../../../../../context/ChatContext";

const BookCard = ({ item, setChatFeature, removeCommands }) => {
	const chatContext = useContext(ChatDataContext);

	return (
		<button
			type="button"
			className={styles.btnCard}
			onClick={() => {
				chatContext.setSelectedFeature({
					...chatContext.selectedFeature,
					book: item,
				});
				removeCommands();
				setChatFeature("");
			}}
		>
			<img src={item.image_url} alt="Card" />
			<div>{item.book_name}</div>
		</button>
	);
};

export default BookCard;
