import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Modal } from "antd";
import { useLocation } from "react-router-dom";
import { SubscriptionModal } from "./subscriptionodal";

const PackageSubscriptionHook = forwardRef(({ checkPackageSubscription, title, feature, packageName }, ref) => {
    const [packagesDetails, setPackagesDetails] = useState([]);
    const [newMarkerOpened, setNewMarkerOpened] = useState(false);
    const { token } = useSelector((state) => state.auth);
    const [packageModal, setPackageModal] = useState(false);
    const location = useLocation();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const showPackageModal = () => {
        setPackageModal(true);
    };

    const handlePackageOk = () => {
        setPackageModal(false);
    };
    const handlePackageCancel = () => {
        setPackageModal(false);
    };

    useImperativeHandle(ref, () => ({
        showModalAction() {
            showModal();
        },
    }));

    useEffect(() => {
        getPackageDetails();
        checkSubscription();
    }, []);

    useEffect(() => {
        if (token?.user?.package_options?.length === 0) {
            // showPackageModal();
        }
    }, [token]);

    useEffect(() => {
        return () => {
            setPackageModal(false);
        };
    }, [location]);

    const checkSubscription = async () => {
        const features = Array.isArray(feature) ? feature : [feature];

        const filter = token.user?.package_options?.filter(
            (item) => features.some(f => item?.slug === f)
        );

        const subscriptionStatus = {};

        features.forEach(f => {
            subscriptionStatus[f] = filter && filter.some(item => item?.slug === f && item?.available);
        });

        const subscriptionMessages = features.map(f => {
            return {
                feature: f,
                status: subscriptionStatus[f] ? true : false,
                message: subscriptionStatus[f] ? `You have access to this feature ${f}` : `You don't have access to this feature ${f}`
            };
        });

        if (Object.values(subscriptionStatus).every(status => status)) {
            checkPackageSubscription(subscriptionMessages);
            setIsButtonDisabled(false);
        } else {
            const array = [];
            for (const key in packagesDetails) {
                const find = packagesDetails[key]?.options.filter(
                    (item) => features.some(f => item?.slug === f)
                );
                if (find && find.some(f => f?.available)) {
                    array.push(packagesDetails[key]);
                }
            }
            if (array.length > 0) {
                checkPackageSubscription(subscriptionMessages);
                setIsButtonDisabled(false);
            } else {
                checkPackageSubscription(subscriptionMessages);
                setIsButtonDisabled(true);
                // showPackageModal();
            }
        }
    };

    const getPackageDetails = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}/packages`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            if (data) {
                setPackagesDetails(data?.data);
            }
        } catch (error) {
            console.debug(error);
        }
    };

    const showModal = () => {
        setNewMarkerOpened(true);
    };

    const handleUpdateOk = () => {
        setNewMarkerOpened(false);
    };
    const handleUpdateCancel = () => {
        setNewMarkerOpened(false);
    };

    return (
        <>
            <SubscriptionModal
                open={packageModal}
                ok={handlePackageOk}
                cancel={handlePackageCancel}
            />
        </>
    );
});

export const mapFeaturesToButtons = (featureMap, action) => {
    return action.reduce((acc, item) => {
        const buttonKey = featureMap[item.feature];
        if (buttonKey) {
            acc[buttonKey] = item.status;
            acc[`${buttonKey}Message`] = item.message;
        }
        return acc;
    }, {});
};

export default PackageSubscriptionHook;