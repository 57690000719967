import React, { useEffect, useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getBookOfSeries } from "../redux/Slice/SeriesSlice";
import { useDispatch, useSelector } from "react-redux";
import ListCard from "./ListCard";
import FullPageSpinner from "./loader/FullPageSpinner";
import { HeaderDataContext } from "../context/HeaderContext";
import { deleteBooksData, getBookData, updateBook } from "../redux/Slice/BookSlice";
import { UpdateBookDrawer } from "./UpdateBookDrawer";

const BookOfSeriesCards = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { booksOfSeries, loading } = useSelector((state) => state.series);
	const [openIndex, setOpenIndex] = useState(false);
	const [popupVisible, setPopupVisible] = useState(false);


	const path = useLocation().pathname.split("/");
	let pathName = "";

	if (path[1] === "out-line") {
		pathName = "out-line/list";
	}
	if (path[1] === "plot-line") {
		pathName = "plot-line/list";
	}
	if (path[1] === "time-line") {
		pathName = "time-line/list";
	}
	if (path[1] === "brain-storm") {
		pathName = "brain-storm/list";
	}
	const handleOpenChange = (newOpen) => {
		setPopupVisible(newOpen);
	};
	const open = (index) => {
		setPopupVisible(true);
		setOpenIndex(index);
	};
	const close = () => {
		setPopupVisible(false);
	};
	useEffect(() => {
		dispatch(getBookOfSeries(id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="main-container">
			<div className="card-container mt-2">
				<div className="p-3">
					{booksOfSeries[0]?.length === 0 ? (
						<h4 style={{ textAlign: "center" }}>This Series has no books</h4>
					) : (
						booksOfSeries?.length > 0 &&
						booksOfSeries[0]?.map((book) => {
							return loading ? (
								<FullPageSpinner />
							) : (
								<ListCard
								key={book.id}
								style={{
									backgroundColor: book.color_code,
									color: book.foreground_color,
									cursor: "pointer",
								}}
								foreground_color={book.foreground_color}
								color_code={book.color_code}
								id={book.id}
								name={book.book_name}
								description={book.book_description}
								content={<Content id={book.id} close={close} />}
								pathname={pathName}
								type="series"
								icon={book.image_url}
								open={open}
								openIndex={openIndex}
								updated={book.updated_at}
								popupVisible={popupVisible}
								handleOpenChange={handleOpenChange}
							/>
							);
						})
					)}
				</div>
			</div>
		</div>
	);
};

const Content = ({ id, close, seriesId }) => {
	const [updateBooks, setUpdateBook] = useState(false);

	const dispatch = useDispatch();
	const onAddData = (data) => {
		try {
			const payload = { id: id, data: data };
			dispatch(updateBook(payload));
			dispatch(getBookData());
			setTimeout(() => {
				dispatch(getBookOfSeries(seriesId));
			}, 500);

			closeDrawer();
		} catch (e) {
			console.log(e);
		}
	};
	const deleteBooks = (id) => {
		dispatch(deleteBooksData(id));
		setTimeout(() => {
			dispatch(getBookData());
		}, 1100);
	};
	const openDrawer = () => {
		close();
		setUpdateBook(true);
	};
	const closeDrawer = () => {
		close();
		setUpdateBook(false);
	};

	return (
		<div>
			<div className="edit">
				<button
					onClick={() => {
						openDrawer();
						close();
					}}
				>
					Edit
					<span className="ml-2">
						<i className="fa fa-check"></i>
					</span>
				</button>
			</div>

			<div className="delete-btn">
				<button
					onClick={() => {
						deleteBooks(id);
						close();
					}}
				>
					Delete
					<span className="ml-2">
						<i className="fa fa-times"></i>
					</span>
				</button>
			</div>
			<UpdateBookDrawer
				open={updateBooks}
				onSubmit={onAddData}
				close={closeDrawer}
				id={id}
			/>
		</div>
	);
};

export const BookOfSeriesDashboard = () => {
	const header = useContext(HeaderDataContext);
	const { id } = useParams();
	const dispatch = useDispatch();
	const { booksOfSeries } = useSelector((state) => state.series);
	const [popupVisible, setPopupVisible] = useState(false);
	const [openIndex, setOpenIndex] = useState(false);
	const [loading, setLoading] = useState(true)


	const path = useLocation().pathname.split("/");
	let pathName = "";

	if (path[1] === "out-line") {
		pathName = "out-line/list";
	}
	if (path[1] === "plot-line") {
		pathName = "plot-line/list";
	}
	if (path[1] === "time-line") {
		pathName = "time-line/list";
	}
	if (path[1] === "brain-storm") {
		pathName = "brain-storm/list";
	}

	console.log(path)
	const handleOpenChange = (newOpen) => {
		setPopupVisible(newOpen);
	};
	const open = (index) => {
		setPopupVisible(true);
		setOpenIndex(index);
	};
	const close = () => {
		setPopupVisible(false);
	};

	useEffect(() => {
		header.setHeading("Books of Series");
		if (booksOfSeries[0] && booksOfSeries[0].length > 0) {
			header.setHeading(`Books of ${booksOfSeries[0][0].series_name} Series`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [booksOfSeries]);

	useEffect(() => {
		dispatch(getBookOfSeries(id)).then(() => setLoading(false)).catch(() => setLoading(true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="main-container">
			<div className="card-container mt-2">
				<div className="p-3">
					{loading ? (
						<FullPageSpinner />
					) : (
						<>
							{booksOfSeries[0]?.length === 0 ? (
								<h4 style={{ textAlign: "center" }}>
									This Series has no books
								</h4>
							) : (
								booksOfSeries?.length > 0 &&
								booksOfSeries[0]?.map((book) => (
									<ListCard
								key={book.id}
								style={{
									backgroundColor: book.color_code,
									color: book.foreground_color,
									cursor: "pointer",
								}}
								foreground_color={book.foreground_color}
								color_code={book.color_code}
								id={book.id}
								name={book.book_name}
								description={book.book_description}
								content={<Content id={book.id} seriesId={id} close={close} />}
								pathname={pathName}
								type="series"
								icon={book.image_url}
								open={() => {
									open(book.id);
								}}
								openIndex={openIndex}
								updated={book.updated_at}
								popupVisible={popupVisible}
								handleOpenChange={handleOpenChange}
							/>
								))
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
export default BookOfSeriesCards;
