import React from "react";
import { Input } from "antd";
const TextAreaInput = ({
	name,
	label,
	required,
	error,
	maxLength,
	showCount,
	onChange,
	value,
}) => {
	return (
		<div className="form-input">
			<label htmlFor={name}>
				{label} {required && <span className="required">*</span>}
			</label>
			<Input.TextArea
				id={name}
				status={error ? "error" : ""}
				maxLength={maxLength}
				showCount={showCount}
				autoSize={{ minRows: 4, maxRows: 8 }}
				onChange={onChange}
				value={value}
			/>
			{error && <span className="error">{error}</span>}
		</div>
	);
};

export default TextAreaInput;
