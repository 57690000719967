import { notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { useDispatch, useSelector } from "react-redux";
import SpeechRecognition from "react-speech-recognition";
import { useSpeechRecognition } from "react-speech-recognition";
import { axiosInstance } from "../../services/apiServices/config";
import { blobToBase64, dataURLtoFile } from "../../helpers";
import axios from "axios";
import { incrementWordsCount } from "../../redux/Slice/counterSlice";
import { hasEditorAccess } from "../../redux/Slice/SharedWork";

const useBrainstormDetails = ({id, mod}) => {
    const sharedId = new URLSearchParams(window.location.search).get('sharedId');
	const email = new URLSearchParams(window.location.search).get('email')
	const key = new URLSearchParams(window.location.search).get('key')
	const [notifyApi, contextHolder] = notification.useNotification();

	const recorder = useAudioRecorder();
	const dispatch = useDispatch();
    const { brainStorms } = useSelector((state) => state.sharedWork);

    let hasEditor = hasEditorAccess(brainStorms, id, sharedId);
	let geustData = {
		email,
		key
	}

	console.log(id, 'id')
	const refRoundTitle = useRef();
	const roundTranscript = useRef();

	const [loading, setLoading] = useState(true);
	const [disableInput, setDisableInput] = useState(false);
	const [roundLoading, setRoundLoading] = useState(false);
	const [error, setError] = useState({});
	const [isError, setIsError] = useState(true);
	const [errorMessage, setErrorMessage] = useState(true);
	const [brainstorm, setBrainstorm] = useState(null);
	const [recordBlob, setRecordBlob] = useState(null);
	const [bsTranscript, setBSTranscript] = useState(null);
	const [addNew, setAddNew] = useState(false);
	const [roundIndex, setRoundIndex] = useState(0);
	const {
		transcript,
		// listening,
		resetTranscript,
		// browserSupportsSpeechRecognition,
	} = useSpeechRecognition();

    const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);


	useEffect(() => {
		getBrainstorm({id,mod,geustData});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!recorder.recordingBlob) return;
		setRecordBlob(recorder.recordingBlob);
		setDisableInput(false);
	}, [recorder.recordingBlob]);

	useEffect(() => {		
		transcript && setBSTranscript(transcript);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transcript]);

	const getBrainstorm = async ({id, mod, geustData}) => {
		try {
			const response = await axiosInstance.get(`/brainstorms/${id}$${mod === 'guest' ? `?email=${geustData.email}&key=${geustData.key}` : ''}`);
			setBrainstorm(response.data.data);
			setLoading(false);
			setIsError(false);
		} catch (error) {
			setIsError(true);
			setLoading(false);
			setErrorMessage(
				error.response?.status === 404
					? "Brainstorm not found"
					: "Something went wrong."
			);
		}
	};

	const countWords = () => {
		if (bsTranscript && typeof bsTranscript === 'string') {
		  const wordsArray = bsTranscript.trim().split(/\s+/);
		  return wordsArray.length;
		}
		return 0; 
	  };

	const onAddNew = () => {
		setRecordBlob(null);
		setAddNew(true);
	};

	const toggleRecording = () => {
		if (recorder.isRecording) {
			SpeechRecognition.stopListening();
			recorder.stopRecording();
			setBSTranscript(transcript);
		} else {
			recorder.startRecording();
			SpeechRecognition.startListening({
				continuous: true,
			});
			setDisableInput(true);
		}
	};

	const saveRound = () => {
		if (!recordBlob) {
			notifyApi.warning({
				message: "Warning!",
				description: "Please record audio.",
				placement: "top",
			});

			return false;
		}

		blobToBase64(recordBlob).then((base64) => {
			const recordedFile = dataURLtoFile(base64, "brainstorm.webm");
			const formData = new FormData();
			formData.append("brainstorm_id", brainstorm.id);
			formData.append("audio_file", recordedFile);
			formData.append("title", refRoundTitle.current.input.value);
			formData.append("transcript", bsTranscript);
			const token = localStorage.getItem("token");
			setRoundLoading(true);
			axios
				.post(`${process.env.REACT_APP_API_URL}/brainstorm-rounds`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				})
				.then(() => {
					setError({});
					refRoundTitle.current.input.value = "";
					const wordsCount = countWords();
					dispatch(incrementWordsCount(wordsCount));
					setBSTranscript("");
					setRoundLoading(false);
					setAddNew(false);
					setRecordBlob(null);
					resetTranscript();
					notifyApi.success({
						message: "Success!",
						description: "Brainstorm round added successfully.",
						placement: "top",
					});
					getBrainstorm({id});
				})
				.catch((error) => {
					if (error.response?.data?.errors) {
						setError(error.response.data.errors);
					} else {
						notifyApi.error({
							message: "Error!",
							description: "Something went wrong.",
						});
					}
					setRoundLoading(false);
				});
		});
	};

	const deleteRound = async (id) => {
		try {
			await axiosInstance.delete(`/brainstorm-rounds/${id}`);
			notifyApi.success({
				message: "Success!",
				description: "Brainstorm Round deleted successfully.",
				placement: "top",
			});
			getBrainstorm(brainstorm.id);
			setRoundIndex(0);
		} catch (error) {
			notifyApi.warning({
				message: "Warning!",
				description: "Brainstorm Round could not be deleted.",
				placement: "top",
			});
		}
	};

	const selectRound = (index) => {
		setRoundIndex(index);
		setAddNew(false);
	};

    const showEditorModal = () => {
		setIsEditorModalVisible(true);
	};

	const handleEditorModalOk = () => {
		setIsEditorModalVisible(false);
	};

	const handleEditorModalCancel = () => {
		setIsEditorModalVisible(false);
	};

	return {
        hasEditor,
		loading,
		isError,
		errorMessage,
		contextHolder,
		brainstorm,
		addNew,
		recorder,
		toggleRecording,
		error,
		recordBlob,
		bsTranscript,
		setBSTranscript,
		onAddNew,
		saveRound,
		refRoundTitle,
		roundTranscript,
		disableInput,
		roundLoading,
		deleteRound,
		roundIndex,
		selectRound,
        isEditorModalVisible,
		showEditorModal,
		handleEditorModalOk,
		handleEditorModalCancel
	};
};

export { useBrainstormDetails };