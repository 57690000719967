import { useEffect, useState, useCallback } from "react";
import { Menu, Layout, Modal, message, Spin, Avatar, Tooltip } from "antd";
import { NavLink, useLocation } from "react-router-dom";

import AppLogo from "../../assets/icons/App-Logo.svg";
import GreenDot from "../../assets/icons/online-pill.svg";
import WhiteIcon from "../../assets/icons/SidebarIcon/WhiteIcon.svg";

import CustomButton from "../button/Button";
import { ROUTES } from "../../constant/routeConstant";
import useDebounce from "../../hooks/useDebounce";
import axios from "axios";
import TextInput from "../Inputs/TextInput";
import { useSelector } from "react-redux";

const { Sider } = Layout;

const Sidenav = () => {
	const token = localStorage.getItem("token");
	const { pathname } = useLocation();
	const ADMIN_ROUTE = ROUTES.ADMIN;
	const ADMIN_LENGTH = ADMIN_ROUTE.length;
	const OTHER_ROUTE = ROUTES.OTHER;
	const FREEUSER_OTHER_ROUTE = ROUTES.FREEUSEROTHER
	const FREE_USER = ROUTES.FREEUSER;
	const route = pathname;

	const { user: authUser } = useSelector((state) => state.auth);
	const [showInvite, setShowInvite] = useState(false);
	const [searchText, setSearchText] = useState("");
	const searchValue = useDebounce(searchText, 100);
	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);

	const inviteUser = async () => {
		if (!selectedUser) {
			return message.error("Please select a user");
		}
		setLoading(true);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/invitation/send`,
				{ to_id: selectedUser.id },
				{
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: "application/json",
					},
				}
			);
			if (response.data.contact) {
				message.success("User invited successfully.");
				handleCancel();
			} else {
				message.error(response.data.message);
			}
		} catch (error) {
			if (error.response.status === 403) {
				message.error("You do not have permission to invite user.");
			} else if (error.response.status === 422) {
				if (error.response.data.contact) {
					message.error(error.response.data.message);
				} else {
					setError(error.response.data.errors);
				}
			} else {
				message.error("An error occurred while inviting the user.");
			}
		} finally {
			setLoading(false);
		}
	};

	const searchUsers = useCallback(async (s) => {
		if (s.length > 0) {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/invitation/search-user?search=${s}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
							Accept: "application/json",
						},
					}
				);
				setUsers(response.data.data);
			} catch (error) {
				message.error(error.response.data.message);
			}
		} else {
			setUsers([]);
		}
	}, [token]);

	const selectUser = (user) => {
		setSelectedUser(user);
		setUsers([]);
	};

	const handleCancel = () => {
		setSelectedUser(null);
		setUsers([]);
		setShowInvite(false);
		setSearchText("");
		setError({});
	};

	useEffect(() => {
		searchUsers(searchValue);
	}, [searchValue, searchUsers]);

	const renderMenu = (item, index) => {
		const { selectedIcon, unSelectedIcon } = item;

		return (
			<Menu.Item key={index}>
				<NavLink style={{ textDecoration: "none" }} to={item.route}>
					<div className="align-center">
						{selectedIcon ? (
							<img
								src={route === item.route ? selectedIcon : unSelectedIcon}
								className="mr-3 mb-0-3 icon-20"
								alt="Menu Icon"
							/>
						) : (
							<img
								src={WhiteIcon}
								className="mr-3 mb-0-3 icon-20"
								alt="Menu Icon"
							/>
						)}
						<span
							className={`text-16 font-w-600 ${route === item.route ? "blue-color" : null
								}`}
						>
							{item.label}
						</span>
					</div>
				</NavLink>
			</Menu.Item>
		);
	};

	console.log(selectedUser)
	return (
		<Sider
			collapsedWidth="0"
			trigger={null}
			width={250}
			className="zIndex-1 main-sidebar"
		>
			<div className="siderbar-header" style={{ paddingTop: "23px" }}>
				<img src={AppLogo} alt="App logo" />
			</div>
			<div className="sidebar-container">
				<Menu mode="inline">
					{(authUser[0]?.user?.package ? ADMIN_ROUTE : FREE_USER).map((item, index) => renderMenu(item, index))}

					<div className="divider mb-4" />

					{(authUser[0]?.user?.package ? OTHER_ROUTE : FREEUSER_OTHER_ROUTE).map((item, index) =>
						renderMenu(item, index + (authUser[0]?.user?.package ? ADMIN_LENGTH : 0))
					)}
				</Menu>
			</div>

			<div className="text-align-center">
				{authUser[0]?.user?.package && <CustomButton
					buttonTitle="Invite Users"
					type="hyperlink"
					spacing="margin-0"
					verticalSpacing="margin-0"
					onClick={() => setShowInvite(true)}
				/>}

				<div className="display-flex place-center mb-3" style={{ paddingTop: authUser[0]?.user?.package && "48px" }}>
					<img src={GreenDot} className="mr-2" alt="Online Icon" />
					<p className="text-12 font-w-400">Partially Degraded Service</p>
				</div>
			</div>
			<Modal
				title="Invite User"
				centered
				open={showInvite}
				onOk={inviteUser}
				onCancel={handleCancel}
				okText="Invite"
				confirmLoading={loading}
			>
				<TextInput
					id="user"
					user="user"
					required={false}
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
					error={error?.to_id}
					placeholder="Search users..."
				/>

				{!loading && <div className="selected-users">
					{users.map((user) => (
						<div
							className="user mt-2"
							key={user.id}
							onClick={() => selectUser(user)}
							style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
						>
							<Avatar src={user.profile_image} alt="User Avatar" />
							<p style={{ marginLeft: '10px' }}>{user.name}</p>
						</div>
					))}
				</div>}
				
				{selectedUser && !loading && (
					<div className="selected-users selected">
						<div
							className="user mt-2"
							key={selectedUser.id}
							style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', backgroundColor: '#f0f2f5', padding: '10px', borderRadius: '5px' }}
						>
							<Avatar src={selectedUser.profile_image} alt="User Avatar" />
							<p style={{ marginLeft: '10px' }}>{selectedUser.name}</p>
							<button
								style={{ marginLeft: 'auto', backgroundColor: '#ff4d4f', color: '#fff', border: 'none', borderRadius: '5px', padding: '5px 10px', cursor: 'pointer' }}
								onClick={() => setSelectedUser(null)}
							>
								Remove
							</button>
						</div>
					</div>
				)}
			</Modal>
		</Sider>
	);
};

export default Sidenav;
