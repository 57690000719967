import { Button, Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChaptersList } from "../redux/Slice/ChapterSlice";
const UpdateChapterModal = ({ open, ok, cancel, onSubmit, id, chapter_id }) => {
	const { colors } = useSelector((state) => state.colors);
	const { allChapters, loading } = useSelector((state) => state.chapters);
	const dispatch = useDispatch();

	const handleSubmit = (e) => {
		let data = { ...e };
		data.outline_id = parseInt(id);
		onSubmit(data);
	};

	const [form] = Form.useForm();

	const [updateData, setUpdateData] = useState(null);
	const [name, setName] = useState(null);
	const [color, setColor] = useState(null);

	useEffect(() => {
		if (allChapters[0]?.data?.length > 0) {
			const chapterData = allChapters[0]?.data?.filter((c) => c.id === chapter_id);
			setUpdateData(chapterData);
			setName(chapterData[0]?.chapter_name);
			setColor(chapterData[0]?.color_id);
		}
	}, [allChapters, chapter_id]);

	useEffect(() => {
		dispatch(getChaptersList(id));
	}, [dispatch, id]);

	useEffect(() => {
		if (open) {
			form.setFieldsValue({
				chapter_name: name,
				color_id: color,
			});
		}
	}, [open, name, color, form]);

	return (
		<Modal
			className="chapterModal"
			title="Edit Chapter"
			open={open}
			onOk={ok}
			onCancel={cancel}
			mask={false}
			width={400}
			style={{
				marginLeft: "auto",
				marginRight: "auto",
				position: "relative",
				marginTop: "10%",
				padding: "1.5rem",
				height: "auto",
			}}
		>
			<Form
				layout="vertical"
				initialValues={{
					remember: true,
				}}
				onFinish={handleSubmit}
				form={form}
				autoComplete="off"
				className="gx-signin-form gx-form-row0"
				style={{
					maxWidth: 600,
					margin: "0 auto",
				}}
			>
				<Form.Item
					style={{ padding: "0.5rem 0" }}
					label="Chapter Name"
					name="chapter_name"
					labelCol={{ span: 24 }}
					rules={[
						{
							required: true,
							message: "Name is required",
						},
					]}
				>
					<Input value={name} onChange={(e) => setName(e.target.value)} />
				</Form.Item>
				<Form.Item
					style={{ padding: "0.5rem 0" }}
					label="Color"
					name="color_id"
					labelCol={{ span: 24 }}
					rules={[
						{
							required: true,
							message: "Color is required",
						},
					]}
				>
					<Select options={colors} placeholder="Select Color" onChange={(value) => setColor(value)} value={color} />
				</Form.Item>
				<Form.Item style={{ padding: "0.5rem 0", textAlign: "center" }}>
					<Button type="primary" htmlType="submit">
						Update Chapter
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default UpdateChapterModal;

