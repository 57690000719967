import React from 'react'

const CommandIcon = ({commandType, title, description = "", onClick, styles, iconColor}) => {
  return (
    <div className={styles.chatCommand}>
        <button onClick={() => onClick(commandType)}>
            <div className={styles.commandIcon}><span className={styles.plus} style={{color: iconColor}}>+</span></div> <span className={styles.title}>{title}</span> {description && <span className={styles.description}>{description}</span>}
        </button>
    </div>
  )
}

export default CommandIcon