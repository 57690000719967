import { Col, Row, Button, Tooltip } from "antd";
import React from "react";
import "./bstorm.css";
import RoundList from "./RoundList";
import AddNewRound from "./AddNewRound";
import RoundDetail from "./RoundDetail";

const BSRounds = ({
	hasEditor,
	mod,
	showEditorModal,
	rounds,
	onAddNew,
	addNew,
	roundIndex,
	selectRound,
	bsTranscript,
	setBSTranscript,
	saveRound,
	recordBlob,
	refRoundTitle,
	error,
	disableInput,
	roundLoading,
	deleteRound,
}) => {
	return (
		<Row className="mt-3">
			<Col sm={24} xs={24} md={7} lg={7} xl={7}>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div className="bs-rounds-list">
						{mod !== 'guest' && <div className="round-header">
							<div className="brainstorm-rounds-heading">Brainstorm Rounds</div>
							<div className="action">
								<Tooltip title="Add new round">
									<Button type="primary" onClick={hasEditor ? onAddNew : showEditorModal}>
										+
									</Button>
								</Tooltip>
							</div>
						</div>}
						<hr />
						{rounds.length > 0 && (
							<RoundList
								rounds={rounds}
								roundIndex={roundIndex}
								selectRound={selectRound}
								deleteRound={deleteRound}
								hasEditor={hasEditor}
								mod={mod}
							/>
						)}
					</div>
					<div className="br-list-line"></div>
				</div>
			</Col>
			<Col sm={24} xs={24} md={17} lg={17} xl={17}>
				{addNew ? (
					<AddNewRound
						{...{
							refRoundTitle,
							disableInput,
							error,
							bsTranscript,
							setBSTranscript,
							recordBlob,
							saveRound,
							roundLoading,
						}}
					/>
				) : (
					rounds.length > 0 && <RoundDetail round={rounds[roundIndex]} />
				)}
			</Col>
		</Row>
	);
};

export default BSRounds;
