import React, { useState, useEffect } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBooksData,
  getBookData,
  setBookSelice,
  updateBook,
} from "../../redux/Slice/BookSlice";
import { UpdateBookDrawer } from "../UpdateBookDrawer";
import ListCard from "../ListCard";
import { Pagination, Spin } from "antd";
import { apiServices } from "../../services/apiServices/Api";
import { axiosInstance } from "../../services/apiServices/config";
import { CommentsDrawer } from "../comments";
const Content = ({ id, close, disable, openSubscriptionModal }) => {
  const [updateBooks, setUpdateBook] = useState(false);
  const dispatch = useDispatch();
  const onAddData = (data) => {
    try {
      const payload = { id: id, data: data };
      dispatch(updateBook(payload));
      setTimeout(() => {
        dispatch(getBookData());
      }, 1000);
      closeDrawer();
    } catch (e) {
      console.log(e);
    }
  };
  const deleteBooks = (id) => {
    dispatch(deleteBooksData(id));
    setTimeout(() => {
      dispatch(getBookData());
    }, 1100);
  };
  const openDrawer = () => {
    close();
    setUpdateBook(true);
  };
  const closeDrawer = () => {
    close();
    setUpdateBook(false);
  };
  return (
    <div>
      <div className="edit">
        <button
          onClick={() => {
            if (!disable) {
              openDrawer();
              close();
            } else {
              openSubscriptionModal();
            }
          }}
        >
          Edit
          <span className="ml-2">
            <i className="fa fa-check"></i>
          </span>
        </button>
      </div>
      <div className="delete-btn">
        <button
          onClick={() => {
            if (!disable) {
              deleteBooks(id);
              close();
            } else {
              openSubscriptionModal();
            }
          }}
        >
          Delete
          <span className="ml-2">
            <i className="fa fa-times"></i>
          </span>
        </button>
      </div>
      <UpdateBookDrawer
        open={updateBooks}
        onSubmit={onAddData}
        close={closeDrawer}
        id={id}
      />
    </div>
  );
};
const Books = ({ disable = false, openSubscriptionModal = () => { } }) => {
  const dispatch = useDispatch();
  const [popupVisible, setPopupVisible] = useState(false);
  const [openIndex, setOpenIndex] = useState(false);
  const [page, setpage] = useState(1);
  const [itemsPerPage] = useState(5);
  const { loading, book_data, totalPages } = useSelector((state) => state.books);
  console.log(book_data, 'boot')
  const close = () => {
    setPopupVisible(false);
  };
  const open = (index) => {
    setPopupVisible(true);
    setOpenIndex(index);
  };
  const handleOpenChange = (newOpen) => {
    setPopupVisible(newOpen);
  };
  console.log(totalPages, 'total page')
  useEffect(() => {
    let wait = false;
    if (!loading && !wait) {
      dispatch(getBookData());
    }
    return () => {
      wait = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getBookData({ page }));
  }, [page])
  const indexOfLastItem = page * itemsPerPage;

  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setpage(pageNumber);

  const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState({ isOpen: false, id: null });
  const [commentsState, setCommentsState] = useState({});
  const [newCommentState, setNewCommentState] = useState("");

  const showCommentDrawer = (comments, id) => {
    setCommentsState(prevState => ({
      ...prevState,
      [id]: comments.map(i => ({ author: 'you', comment: i.comment }))
    }));
    setIsCommentDrawerOpen({ id, isOpen: true });
  };

  const handleCommentDrawerClose = () => {
    setIsCommentDrawerOpen({ isOpen: false, id: null });
  };

  const handleAddComment = async () => {
    if (newCommentState.trim()) {
      const postComments = await axiosInstance.post('/comments', {
        comment_type: "book",
        comment: newCommentState,
        book_id: isCommentDrawerOpen.id
      });
      if (postComments.data) {
        const item = book_data[0].find(i => i.id == isCommentDrawerOpen.id);
        if (item) {
          const updatedComments = [...item.comments, { id: item.comments.length + 1, author: 'You', comment: newCommentState }];
          const updatedItem = { ...item, comments: updatedComments };
          const updatedOutlineData = book_data[0].map(i => i.id === isCommentDrawerOpen.id ? updatedItem : i);
          console.log(updatedOutlineData, 'log')
          dispatch(setBookSelice(updatedOutlineData));
        }
        setCommentsState(prevState => ({
          ...prevState,
          [isCommentDrawerOpen.id]: [...prevState[isCommentDrawerOpen.id], { id: prevState[isCommentDrawerOpen.id].length + 1, author: 'You', comment: newCommentState }]
        }));
        setNewCommentState("");
      }
    }
  };

  return (
    <div className="card-container mt-2">
      <div className="p-3">
        {book_data[0]?.length === 0 ? (
          <h4 className="text-center">
            No book to display, Click on Create New Book to add New book
          </h4>
        ) : (
          book_data[0]?.length > 0 &&
          book_data[0].map((book) => (
            <ListCard
              key={book.id}
              style={{
                backgroundColor: book.color_code,
                color: book.foreground_color,
              }}
              foreground_color={book.foreground_color}
              color_code={book.color_code}
              id={book.id}
              name={book.book_name}
              description={book.book_description}
              content={
                <Content
                  id={book.id}
                  close={close}
                  disable={disable}
                  openSubscriptionModal={openSubscriptionModal}
                />
              }
              comments={book.comments}
              pathname={"/book-detial"}
              type={"book"}
              icon={book.image_url}
              open={() => open(book.id)}
              updated={book.updated_at}
              openIndex={openIndex}
              popupVisible={popupVisible}
              handleOpenChange={handleOpenChange}
              setNewComment={setNewCommentState}
              handleAddComment={handleAddComment}
              showCommentDrawer={() => showCommentDrawer(book.comments, book.id)}
            />
          ))
        )}
        <div className="d-flex justify-content-end">
          <Pagination
            current={page}
            pageSize={itemsPerPage}
            total={totalPages * itemsPerPage}
            onChange={paginate}
            showSizeChanger={false}
            className="pagination"
            disabled={loading}
          />
        </div>
        <CommentsDrawer
          mod="auther"
          hasEditor={true}
          isCommentDrawerOpen={isCommentDrawerOpen.isOpen}
          handleCommentDrawerClose={handleCommentDrawerClose}
          comments={commentsState[isCommentDrawerOpen.id] || []}
          newComment={newCommentState}
          setNewComment={setNewCommentState}
          handleAddComment={handleAddComment}
        />
      </div>
    </div>
  );
};
export const ActivityList = ({
  disable = false,
  openSubscriptionModal = () => { },
}) => {
  const dispatch = useDispatch();
  const { colors } = useSelector((state) => state.colors)
  const { seriesData } = useSelector((state) => state.series)
  const [popupVisible, setPopupVisible] = useState(false);
  const [openIndex, setOpenIndex] = useState(false);
  const { book_data } = useSelector((state) => state.books);
  const [recentUpdates, setRecentUpdates] = useState([]);
  const [page, setpage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const close = () => {
    setPopupVisible(false);
  };
  const open = (index) => {
    setPopupVisible(true);
    setOpenIndex(index);
  };
  const handleOpenChange = (newOpen) => {
    setPopupVisible(newOpen);
  };
  useEffect(() => {
    let wait = false;
    if (!loading && !wait) {
      dispatch(getBookData());
    }
    return () => {
      wait = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setLoading(true);
    fetchRecentUpdates(page);
    dispatch(getBookData({ page }))
  }, [page]);
  const fetchRecentUpdates = async (page) => {
    try {
      const response = await apiServices.get(`/recent-updates?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.data) {
        setLoading(false);
        setRecentUpdates(response.data);
        setTotalPages(response.last_page);
      } else {
        console.error("Empty response data or invalid structure:", response);
      }
    } catch (err) {
      console.error("Error fetching recent updates:", err);
    } finally {
      setLoading(false);
    }
  };
  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
  };
  return (
    <>
      <div className="card-container mt-2 mb-3 p-3">
        {loading ? (
          <Spin
            tip="Loading"
            size="large"
            className="display-flex center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100px",
            }}
          ></Spin>
        ) : recentUpdates.length === 0 ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            There is no activity in the past 3 days.
          </div>
        ) : (
          recentUpdates.map((item, index) => {
            const itemType = item.type ? item.type.toLowerCase() : "unknown";
            const data = item.data;
            let name, description, updated;
            if (itemType === "book") {
              name = data.book_name;
              description = data.book_description || "No description available";
              updated = data.updated_at;
            } else if (itemType === "brainstorm") {
              name = data.brainstorm_name;
              description = data.description || "No description available";
              updated = data.updated_at;
            } else if (itemType === "timeline") {
              name = data.name;
              description = data.description || "No description available";
              updated = data.updated_at;
            } else if (itemType === "outline") {
              name = data.outline_name;
              description = data.description || "No description available";
              updated = data.updated_at;
            }
            const getSeriesName = (seriesId) => {
              return seriesId ? seriesData[0]?.find(i => i.id == seriesId)?.series_name : "No Series";
            };
            return (
              <ListCard
                key={index}
                listType="activity"
                style={{
                  backgroundColor: data.color.color_code,
                  color: "#222222",
                }}
                foreground_color={data.color.foreground_color}
                color_code="#D0D0D0"
                id={data.id}
                series_name={getSeriesName(data.series_id)}
                name={name}
                image_id={data.image_id}
                description={description}
                content={
                  <div>
                    {/* Replace with your Content component */}
                    Content Here
                  </div>
                }
                type={itemType}
                icon={item.image_url}
                open={() => { }}
                updated={updated}
                popupVisible={false}
                handleOpenChange={() => { }}
              />
            );
          })
        )}
        {recentUpdates.length !== 0 && !loading && <Pagination
          current={page}
          pageSize={itemsPerPage}
          total={totalPages * itemsPerPage}
          onChange={handlePageChange}
          showSizeChanger={false}
          rootClassName="list-pagination"
        />}
      </div>
      {/* <h2 className="sub-title" style={{ padding: "20px 0" }}>
        Your Books:
      </h2>
      <div className="card-container mt-2">
        <div className="p-3">
          {book_data[0]?.length === 0 ? (
            <h4 style={{ textAlign: "center" }}>
              No book to display, Click on Create New Book to add New book
            </h4>
          ) : (
            book_data[0]?.length > 0 &&
            book_data[0]?.slice(0, 3).map((book) => (
              <ListCard
                key={book.id}
                style={{
                  backgroundColor: book.color_code,
                  color: book.foreground_color,
                }}
                foreground_color={book.foreground_color}
                color_code={book.color_code}
                id={book.id}
                name={book.book_name}
                description={book.book_description}
                content={
                  <Content
                    id={book.id}
                    close={close}
                    disable={disable}
                    openSubscriptionModal={openSubscriptionModal}
                  />
                }
                type={"book"}
                icon={book.image_url}
                open={() => open(book.id)}
                updated={book.updated_at}
                openIndex={openIndex}
                popupVisible={popupVisible}
                handleOpenChange={handleOpenChange}
              />
            ))
          )}
        </div>
      </div> */}
    </>
  );
};
export default Books;