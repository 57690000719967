import { useState, useEffect, useContext, useRef } from "react";
import { HeaderDataContext } from "../../context/HeaderContext";
import { useParams } from "react-router-dom";
import { getBooks, getTimelines } from "../../helpers/GetList";
import { mapFeaturesToButtons } from "../../components/PackageSubscriptionHook";
import axios from "axios";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";

const useTimelines = () => {
	const ref = useRef(null);
	const dispatch = useDispatch();
	const { timelineData } = useSelector((state)=> state.timeline)
	const token = localStorage.getItem("token");
	const header = useContext(HeaderDataContext);
	header.setHeading("Timelines");
	const { id } = useParams();
	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [timelines, setTimelines] = useState([]);
	const selectBook = {
		value: "",
		label: "-- Any Book --",
	};
	const [selectedBook, setSelectedBook] = useState(selectBook);
	const [loading, setLoading] = useState(false);

	const [showAddNewDrawer, setShowAddNewDrawer] = useState(false);
	const [showEditDrawer, setShowEditDrawer] = useState(false);
	const [selectedTimeline, setSelectedTimeline] = useState(null);
	const [books, setBooks] = useState([]);

	const [subscriptionModal, setSubscriptionModal] = useState(false);
	const [controlledButtons, setControlledButtons] = useState({
		createTimeline: false,
		feature: ""
	});
	const [clickedButton, setClickedButton] = useState([]);

	useEffect(() => {
		setLoading(true)
		getBooks(id, setSelectedBook, setBooks);
		getTimelines(id, 1, setTimelines, setTotalRecords, dispatch)
		.then(()=> setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getTimelinesByBook = (book) => {
		setSelectedBook(book);
		getTimelines(book.value, 1, setTimelines, setTotalRecords, dispatch);
		setCurrentPage(1);
	};

	const handlePageClick = (page) => {
		setCurrentPage(page);
		getTimelines(selectedBook?.value, page, setTimelines, setTotalRecords, dispatch);
	};

	const editTimeline = (id) => {
		if(controlledButtons.createTimeline) {
			axios(`${process.env.REACT_APP_API_URL}/timelines/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			})
				.then((response) => {
					setSelectedTimeline(response.data.data.timeline);
					setShowEditDrawer(true);
				})
				.catch((error) => console.log(error));
		} else {
			setClickedButton([{feature: "timeline-feature"}]);
			openSubscriptionModal();
		}
	};

	const deleteTimeline = (id) => {
		if(controlledButtons.createTimeline) {
			axios
				.delete(`${process.env.REACT_APP_API_URL}/timelines/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			})
			.then(() => {
				getTimelines(selectedBook?.value, 1, setTimelines, setTotalRecords, dispatch);
				setCurrentPage(1);
			})
			.catch(() => message.error("Could not delete timeline."));
		} else {
			setClickedButton([{feature: "timeline-feature"}]);
			openSubscriptionModal();
		}
	};

	const checkSubscription = (action) => {
		const featureMap = {
			"timeline-feature": "createTimeline"
		};
		const updatedButtons = mapFeaturesToButtons(featureMap, action);
		setControlledButtons(updatedButtons);
	};

	const openSubscriptionModal = () => {
		setSubscriptionModal(true);
	};

	return {
		ref,
		dispatch,
		token,
		header,
		id,
		loading,
		totalRecords,
		currentPage,
		timelines,
		timelineData,
		selectBook,
		selectedBook,
		showAddNewDrawer,
		showEditDrawer,
		selectedTimeline,
		books,
		subscriptionModal,
		controlledButtons,
		clickedButton,
		setTotalRecords,
		setCurrentPage,
		setTimelines,
		setSelectedBook,
		setShowAddNewDrawer,
		setShowEditDrawer,
		setSelectedTimeline,
		setBooks,
		setSubscriptionModal,
		setControlledButtons,
		setClickedButton,
		getTimelinesByBook,
		handlePageClick,
		editTimeline,
		deleteTimeline,
		checkSubscription,
		openSubscriptionModal
	};
};

export { useTimelines };