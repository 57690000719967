import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiServices } from "../../services/apiServices/Api";
import { message } from "antd";

//post branstorm
export const postBrainStorm = createAsyncThunk(
	"brainstorm/postBrainStorm",
	(brainstorm) => {
		const brainstormData = apiServices
			.post(`/brainstorms`, brainstorm.data)
			.then((response) => response)

			.catch((err) => console.log(err));
		return brainstormData;
	}
);
//get brainstorm data
export const getBrainStorm = createAsyncThunk(
	"brainstorm/getBrainStorm",
	(id) => {
		const bs = apiServices
			.get(`/brainstorms-list${id ? "/" + id : ""}`)
			.then((response) => response)
			.catch((err) => err);
		return bs;
	}
);

//get single brainstorm
export const getSingleBrainsStorm = createAsyncThunk(
	"brainstorm/getSingleBrainsStorm",
	(id) => {
		const bs = apiServices
			.get(`/brainstorms/${id}`)
			.then((response) => response)
			.catch((err) => err);
		return bs;
	}
);

//delete brainstorm
export const deleteBrainStorm = createAsyncThunk(
	"brainstorm/deleteBrainStorm",
	async ({ id }) => {
		await apiServices.delete(`/brainstorms/${id}`).then((response) => response);

		return { id };
	}
);

//update brainstorm
export const updateBrainStorm = createAsyncThunk(
	"brainstorm/updateBrainStorm",
	({ id, data }) => {
		const brainstorm = apiServices
			.post(`/brainstorms/${id}`, data)
			.then((response) => response);
		return brainstorm;
	}
);

const initialState = {
	bstorm: [],

	loading: false,
	error: false,
	bs: [],
};

const BrainstormSlice = createSlice({
	name: "brainstorm",
	initialState,
	reducers: {
		brainstormSeliceReset : (state) => {
			state = initialState;
		},
		setBrainstormValues: (state, action) => {
			state.bstorm = action.payload;
		},
	},

	extraReducers: (builder) => {

    builder.addCase(postBrainStorm.pending, (state) => {
			state.loading = true;
		})
		.addCase(postBrainStorm.fulfilled, (state, action) => {
			state.loading = false;
			state.bstorm = [action.payload];
			message.success("Brainstorm created successfully");
		})
		.addCase(postBrainStorm.rejected, (state, action) => {
			state.loading = true;
			state.error = [action.payload];
			message.error("Brainstorm was not created");
		})
		.addCase(getBrainStorm.pending, (state) => {
			state.loading = true;
		})
		.addCase(getBrainStorm.fulfilled, (state, action) => {
			state.loading = false;
			state.bstorm = [...action.payload?.data];
		})
    .addCase(getBrainStorm.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})

		.addCase(deleteBrainStorm.pending, (state) => {
			state.loading = true;
		})
		.addCase(deleteBrainStorm.fulfilled, (state, action) => {
			state.loading = false;
			state.bstorm = action.payload;
			message.success("Brainstorm deleted successfully");
		})
		.addCase(deleteBrainStorm.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
			message.error("Brainstorm not deleted");
		})

		.addCase(updateBrainStorm.pending, (state) => {
			state.loading = true;
		})
		.addCase(updateBrainStorm.fulfilled, (state, action) => {
			state.loading = false;
			state.bstorm = [action.payload];
			message.success("Brainstorm updated successfully");
		})
		.addCase(updateBrainStorm.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
			message.error("Brainstorm not updated");
		})

		.addCase(getSingleBrainsStorm.pending, (state) => {
			state.loading = true;
		})
    .addCase(getSingleBrainsStorm.fulfilled, (state, action) => {
			state.loading = false;
			state.bs = [action.payload];
		})
		.addCase(getSingleBrainsStorm.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})
		// [deleteSeriesData.pending]: (state) => {
		//   state.loading = true;
		// },
		// [deleteSeriesData.fulfilled]: (state, action) => {
		//   state.loading = false;
		//   state.seriesData = action.payload;
		// },
		// [deleteSeriesData.rejected]: (state, action) => {
		//   state.loading = true;
		//   state.error = action.payload;
		// },
		// [updateSeries.pending]: (state) => {
		//   state.loading = true;
		// },
		// [updateSeries.fulfilled]: (state, action) => {
		//   state.loading = false;
		//   state.seriesData = [action.payload];
		//   console.log(action.payload);
		// },
		// [updateSeries.rejected]: (state, action) => {
		//   state.loading = true;
		//   state.error = action.payload;
		// },
		// [getBookOfSeries.pending]: (state) => {
		//   state.loading = true;
		// },
		// [getBookOfSeries.fulfilled]: (state, action) => {
		//   state.loading = false;
		//   state.books = [action.payload];
		// },
		// [getBookOfSeries.rejected]: (state, action) => {
		//   state.loading = true;
		//   state.error = action.payload;
		// },
	},
});
const BrainStormReducer = BrainstormSlice.reducer;
export const { setEdit, brainstormSeliceReset, setBrainstormValues } = BrainstormSlice.actions;
export default BrainStormReducer;
