import { Col, Row } from "antd";
import ArrowRight from "../../assets/icons/Arrow-Right.svg";
import Vector from "../../assets/images/Group 178.png";
import {
	ONBOARDING_CONTENT,
} from "../../constant/constant";
import TrendCard from "../../components/cards/TrendCard";
import CustomModal from "../../components/modal/CustomModal";
import { CreateNewBookDrawer } from "../../components/CreateNewBookDrawer";
import "./index.css";
import { SubscriptionModal } from "../../components/subscriptionodal";
import { useDashboard } from "../../hooks/dashboard/useDashboard";

const Dashboard = () => {
	const {
		header,
		navigate,
		isModalVisible,
		setModalVisible,
		showModal,
		setShowModal,
		currentPage,
		setCurrentPage,
		createBook,
		setCreateNewBook,
		cardStatus,
		setCardStatus,
		select,
		setSelect,
		subscriptionModal,
		setSubscriptionModal,
		controlledButtons,
		setControlledButtons,
		clickedButton,
		setClickedButton,
		user,
		count,
		error,
		task_count,
		calender_count,
		seriesData,
		book_data,
		user_name,
		dispatch,
		ref,
		createNewBookDrawer,
		closeCreateBookDrawer,
		onAddData,
		checkSubscription,
		openSubscriptionModal,
		booksContentSection,
		modalContent,
		activityLogSection,
		options,
		cards
	} = useDashboard();

	return (
		<>
			{showModal && (
				<CustomModal
					isVisible={isModalVisible}
					maskClosable={false}
					children={modalContent(ONBOARDING_CONTENT[currentPage])}
				/>
			)}
			<div className="main-container">
				<div className="display-flex">
					<div className="screen-title dark-black mr-3">
						Welcome back {user_name}
					</div>
					<div className="display-flex">
						<select
							onChange={(e) => setSelect(e.target.value)}
							value={select}
							defaultValue="All Books"
							placeholder="Select Book/Series"
							style={{
								border: "none",
								fontSize: "18px",
								fontWeight: "bolder",
								width: "200px",
								backgroundColor: "transparent",
							}}
						>
							{options.map((option) => (
								<option key={option.value} value={option.value}>
									{option.text}
								</option>
							))}
						</select>
					</div>
				</div>
				<Row justify="space-between" gutter={[16, 0]}>
					<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} align="left">
						<div className="calender-card">
							<h1 className="white font-w-600 mt-2">Check</h1>
							<h1 className="white font-w-600 ">Calender</h1>
							<div className="display-flex space-between mt-4">
								<h6 className="white text-16 font-w-600">
									{task_count ? task_count : 0}/{calender_count ? calender_count : 0} weekly Goals
								</h6>
								<img
									src={ArrowRight}
									className="icon-20"
									alt="Arrow Right Icon"
								/>
							</div>
						</div>
					</Col>
					{cards.map((item, index) => (
						<TrendCard {...item} key={index} />
					))}
				</Row>
				<Row style={{ padding: "20px" }}>
					<div
						style={{
							position: "absolute",
							marginTop: "5px",
							marginLeft: "80%",
						}}
					>
						<img src={Vector} alt="bitmap" />
					</div>
				</Row>
				<Row className="card-container mt-5">
					<Col xs={24} sm={24} md={17} lg={17} xl={17} xxl={17}>
						<div className="left-section">{booksContentSection()}</div>
					</Col>
					<Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
						<div className="activity-section">{activityLogSection()}</div>
					</Col>
				</Row>
			</div>
			<CreateNewBookDrawer
				status={cardStatus}
				onSubmit={onAddData}
				open={createBook}
				close={closeCreateBookDrawer}
			/>
			<SubscriptionModal
				open={subscriptionModal}
				ok={openSubscriptionModal}
				features={clickedButton}
				cancel={() => setSubscriptionModal(false)}
			/>
		</>
	);
};

export default Dashboard;