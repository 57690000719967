import { useState, useEffect, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, message } from "antd";
import { axiosInstance } from "../../services/apiServices/config.js";
import { postCalendarEvent, postCalenderList } from "../../redux/Slice/CalendarSlice";
import { getColorData, getTagsData, updateTags } from "../../redux/Slice/ColorSlice";
import { HeaderDataContext } from "../../context/HeaderContext";
import moment from "moment";
import axios from "axios";
import { mapFeaturesToButtons } from "../../components/PackageSubscriptionHook.js";

const useCalendar = (customStartDate, customEndDate) => {
    const header = useContext(HeaderDataContext);
    const { colors, tags } = useSelector((state) => state.colors);
    const { calendar_list } = useSelector((state) => state.calendar);
    header.setHeading("Calendar");

    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [clickedButton, setClickedButton] = useState([]);
    const [addEvent, setAddEventDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [calendarSubscription, setCalendarSubscription] = useState(false);
    const [addTagsModal, setAddTagsModal] = useState(false);
    const [tag, setTag] = useState('');
    const [editTagsModal, setEditTagsModal] = useState(false);
    const [filteredTagEditData, setFilteredTagEditData] = useState(null);
    const [tagsModalError, setTagsModalError] = useState('');
    const [tagsAddError, setTagsAddError] = useState('');
    const [tagsData, setTagsData] = useState([]);
    const [filteredCalendarData, setFilteredCalendarData] = useState([{ filtered: false, data: [] }]);
    const [newTag, setNewTag] = useState("");
    const [selectedColor, setSelectedColor] = useState(null);

    const ref = useRef();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const checkAndSetSubscriptionModal = (feature) => {
        if (!calendarSubscription[feature]) {
            setClickedButton([{ feature }]);
            setSubscriptionModal(true);
            return true;
        }
        return false;
    };

    console.log(tags, 'tags')
    const showDrawer = () => {
        if (!checkAndSetSubscriptionModal('calendar-create')) {
            setAddEventDrawer(true);
        }
    };

    const closeEventDrawer = () => setAddEventDrawer(false);

    const checkSubscription = (action) => {
        const featureMap = {
            "goals-calendar-feature": "calendar-create",
        };
        const updatedButtons = mapFeaturesToButtons(featureMap, action);
        setCalendarSubscription(updatedButtons);
    };

    const addData = async (data) => {
        dispatch(postCalendarEvent({ data })).then(() => {
            setLoading(true)
            dispatch(postCalenderList({ date_from: moment().startOf('month').format('DD-MM-YYYY'), date_to: moment().endOf('month').format('DD-MM-YYYY') })).then(()=> setLoading(false))
        });
        closeEventDrawer();
    };

    const handleTagsModal = (val) => {
        if (!checkAndSetSubscriptionModal('calendar-create')) {
            setAddTagsModal(val);
        }
    };

    const handleAddTags = (e) => {
        if (tagsAddError.length > 0 && selectedColor !== null && selectedColor !== undefined) {
            setTagsAddError("");
        }
        setTag(e.target.value);
    };

    const handleTagsAdd = () => {
        setLoading(true)
        axiosInstance.post("/tags", { title: tag, color_id: selectedColor }).then((response) => {
            if (response.status === 201) {
                try{
                    dispatch(updateTags([
                        ...tags,
                        {
                            id: response.data.data.id,
                            label: response.data.data.title,
                            value: response.data.data.title,
                            color: colors.find((color) => color.id === response.data.data.color_id)
                        },]));
                    setAddTagsModal(false);
                    message.success("Tag added successfully!");
                    setTag("");
                    setSelectedColor(null);
                    setTagsAddError('')
                    setTagsModalError('')
                } finally {
                    setLoading(false)
                }
            }
        });
    };


    useEffect(() => {
        setLoading(true)
        dispatch(getColorData());
        const startOfMonth = customStartDate ? moment(customStartDate).format('DD-MM-YYYY') : moment().startOf('month').format('DD-MM-YYYY');
        const endOfMonth = customEndDate ? moment(customEndDate).format('DD-MM-YYYY') : moment().endOf('month').format('DD-MM-YYYY');
        dispatch(postCalenderList({ date_from: startOfMonth, date_to: endOfMonth })).then(()=> setTimeout(() => {
            setLoading(false)
        }, 400));
    }, [dispatch, customStartDate, customEndDate]);

    useEffect(() => {
        dispatch(getTagsData());
    }, []);

    console.log(tagsData)

    const filterTagForEdit = (value) => {
        tagsModalError.length > 0 && setTagsModalError("");
        const filteredTag = tags.filter((item) => item.value === value);
        setFilteredTagEditData(filteredTag);
    };

    const handleEditTags = (e) => {
        const updatedTag = { ...filteredTagEditData[0], label: e };
        setFilteredTagEditData([updatedTag]);
        setTag('')
    };

    const Time = (time) => {
		var start = new Date(),
			s = time,
			parts = s.match(/(\d+):(\d+) (\w+)/),
			hours = /am/i.test(parts[3])
				? parseInt(parts[1], 10)
				: parseInt(parts[1], 10) + 24,
			minutes = parseInt(parts[2], 10);
		start.setHours(hours, minutes, 0);
		return start;
	};

    const convertDateTime = (eventDate, endDate, startTime, endTime) => {
		const formatDateTime = (date, time) => {
			// Parse the date and time strings into a moment object
			const dateTime = moment(`${date} ${time}`, "MMMM DD, YYYY hh:mm a");
			// Check if the date is valid
			if (!dateTime.isValid()) {
				console.log(date, time);
				throw new RangeError("Invalid time value");
			}
			// Format as 'YYYY-MM-DDTHH:MM:SS'
			return dateTime.format("YYYY-MM-DDTHH:mm:ss");
		};

		const start = formatDateTime(eventDate, startTime);
		const end = formatDateTime(endDate, endTime);

		return { start, end };
	};
    const handleTagsFilter = (e) => {
        const { checked, value } = e.target;
        if (checked) {
            const data = calendar_list.filter((item) => item.tag_id === value)
            console.log(calendar_list.map((item) => item.tag_id === value), 'log', value)
            setFilteredCalendarData([{ 
                filtered: true, 
                data: data.map((item) => {
                    const { start, end } = convertDateTime(item.event_date, item.event_date, item.start_time, item.end_time);

                    return {
                        title: item?.title,
                        start: start,
                        tag_id: item?.tag_id,
                        end: end,
                        color: colors.find((color) => color.id === tags.find((tag) => tag.id === item?.tag_id)?.color?.id)?.color_code,
                        description: item?.description,
                        id: item.id,
                        start_time: moment(Time(item?.start_time).getTime(), "hh:mm").format("HH:mm"),
                        end_time: moment(Time(item?.end_time).getTime(), "hh:mm").format("HH:mm"),
                        col: item?.color_id,
                        event_date: moment(item?.event_date).format("DD-MM-YYYY"),
                        location: item?.location,
                    };
                })
            }]);
        } else {
            setFilteredCalendarData([{ filtered: false, data: [] }])
        }
    }

    const handleAddTag = async () => {
        try {
            const response = await axios.post("/api/tags", { label: newTag, color_id: selectedColor });
            message.success("Tag added successfully!");
            handleTagsModal(false);
        } catch (error) {
            message.error("Failed to add tag.");
        }
    };

    const handleEditTag = async () => {
        if (!checkAndSetSubscriptionModal('calendar-create')) {
            try {
                const response = await axiosInstance.post(`/tags/${filteredTagEditData[0]?.id}`, { 
                    title: filteredTagEditData[0].label, 
                    id: filteredTagEditData[0]?.id, 
                    tag_id: filteredTagEditData[0]?.id, 
                    color_id: tags.find((item) => item.id === filteredTagEditData[0]?.id)?.color?.id 
                });
                message.success("Tag edited successfully!");
                dispatch(updateTags(tags.map((item) => item.id === filteredTagEditData[0]?.id ? { ...item, label: filteredTagEditData[0].label, color: colors.find((color) => color.id === selectedColor) } : item)));
                setEditTagsModal(false);
                setTag('')
                setSelectedColor(null)
                setTagsModalError('')
                setFilteredTagEditData(null)
            } catch (error) {
                message.error("Failed to edit tag.");
            }
        }
    };
    console.log(tags, 'tags')

    const handleDeleteTag = async () => {
        setLoading(true)
        if (!checkAndSetSubscriptionModal('calendar-create')) {
            try {
                const response = await axiosInstance.delete(`/tags/${filteredTagEditData[0]?.id}`);
                message.success("Tag deleted successfully!");
                dispatch(updateTags(tags.filter((item) => item.id !== filteredTagEditData[0]?.id)));
                setEditTagsModal(false);
                setTag('')
                setSelectedColor(null)
                setTagsModalError('')
                setFilteredTagEditData(null)
            } catch (error) {
                message.error("Failed to delete tag.");
            } finally {
                setLoading(false)
            }
        }
    };

    return {
        header,
        colors,
        addEvent,
        loading,
        calendarSubscription,
        addTagsModal,
        setAddTagsModal,
        tag,
        tags,
        setTag,
        editTagsModal,
        filteredTagEditData,
        ref,
        form,
        showDrawer,
        setNewTag,
        selectedColor,
        setSelectedColor,
        closeEventDrawer,
        checkSubscription,
        addData,
        handleTagsModal,
        handleAddTags,
        handleTagsAdd,
        filterTagForEdit,
        handleEditTags,
        setEditTagsModal,
        handleTagsFilter,
        filteredCalendarData,
        setFilteredTagEditData,
        handleAddTag,
        handleEditTag,
        handleDeleteTag,
        clickedButton,
        setClickedButton,
        subscriptionModal,
        setSubscriptionModal,
        tagsModalError,
        setTagsModalError,
        tagsAddError,
        setTagsAddError
    };
};

export default useCalendar;
