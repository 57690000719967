import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiServices } from "../../services/apiServices/Api";
import { message } from "antd";

//post
export const postTimeLine = createAsyncThunk(
	"timeline/postTimeLine",
	(timeline) => {
		const timelineData = apiServices
			.post(`/timelines`, timeline.data)
			.then((response) => {
				return response;
			})
			.catch((err) => console.log(err));
		// console.log(timelineData);
		return timelineData;
	}
);

//get timeline list data
export const getTimelineList = createAsyncThunk(
	"timeline/getTimelineList",
	(id) => {
		const timeline = apiServices
			.get(`/timelines-list${id ? "/" + id : ""}`)
			.then((response) => response)
			.catch((e) => e);

		// console.log(timeline);

		return timeline;
	}
);

//get single timeline
export const getTimeline = createAsyncThunk("timeline/getTimeline", ({id, mod, geustData}) => {
	const timeline = apiServices
		.get(`/timelines/${id}${mod === 'guest' ? `?email=${geustData.email}&key=${geustData.key}` : ''}`)
		.then((response) => response)
		.catch((e) => e);

	return timeline;
});

//delete timeline
export const deleteTimeline = createAsyncThunk(
	"timeline/deleteTimeline",
	async ({ id }) => {
		await apiServices.delete(`/timelines/${id}`).then((response) => response);

		return { id };
	}
);

//update timeline
export const updateTimeline = createAsyncThunk(
	"timeline/updateTimeline",
	async ({ id, data }) => {
		const timeline = apiServices
			.update(`/timelines/${id}`, data)
			.then((response) => {
				return response;
			});

		return timeline;
	}
);

//post event type
export const postEventType = createAsyncThunk(
	"timeline/postEventType",
	(timelin) => {
		const timeline = apiServices
			.post(`/timeline-event-types`, timelin.data)
			.then((response) => response)
			.catch((err) => console.log(err));
		return timeline;
	}
);

//get event type list

export const getEventTypeList = createAsyncThunk(
	"timeline/getEventTypeList",
	({id, mod, geustData}) => {
		const timeline = apiServices
			.get(`timeline-event-types-list/${id}${mod === 'guest' ? `?email=${geustData.email}&key=${geustData.key}` : ''}`)
			.then((response) => response)
			.catch((err) => console.log(err));
		return timeline;
	}
);

//delete event
export const deleteEvent = createAsyncThunk(
	"timeline/deleteEventType",
	(id) => {
		const timeline = apiServices
			.delete(`timeline-event-types/${id}`)
			.then((response) => response)
			.catch((err) => console.log(err));
		return timeline;
	}
);

//update event
export const updateEvent = createAsyncThunk(
	"timeline/updateEventType",
	({ id, data }) => {
		const timeline = apiServices
			.update(`timeline-event-types/${id}`, data)
			.then((response) => response)
			.catch((err) => console.log(err));
		return timeline;
	}
);

// //get books of series
// export const getBookOfSeries = createAsyncThunk(
//   "series/getBookOfSeries",
//   () => {
//     const seriesData = apiServices
//       .get(`/series-books`)
//       .then((response) => response)
//       .then(({ data }) => {
//         let result = data?.map((obj) => ({
//           ...obj,
//           value: obj.id,
//           label: obj.series_name,
//         }));
//         return result;
//       });
//     console.log("series of book", seriesData);
//     return seriesData;
//   }
// );

const initialState = {
	timelineData: [],

	loading: false,
	error: false,
	edit: false,

	events: [],
	timeline: [],
};

const TimelineSlice = createSlice({
	name: "timeline",
	initialState,
	reducers: {
		setEdit: (state, action) => {
			state.edit = action.payload.edit;

			state.body = action.payload.body;
		},
		timelineSeliceReset : (state) => {
			Object.assign(state, initialState);
		},
		setTimelinDataSelice: (state, action) => {
			state.timelineData = action.payload
		}
	},
	extraReducers: (builder) => {

		builder.addCase(postTimeLine.pending, (state) => {
			state.loading = true;
		})
		.addCase(postTimeLine.fulfilled, (state, action) => {
			state.loading = false;
			state.timelineData.push(action.payload);
			message.success("Timeline created successfully");
		})
		.addCase(postTimeLine.rejected, (state, action) => {
			state.loading = true;
			state.error = [action.payload];
			message.success("Timeline not created");
		})
		.addCase(getTimelineList.pending, (state) => {
			state.loading = true;
		})
		.addCase(getTimelineList.fulfilled, (state, action) => {
			state.loading = false;
			state.timelineData = [...action.payload.data];
		})
		.addCase(getTimelineList.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})
		.addCase(getTimeline.pending, (state) => {
			state.loading = true;
		})
		.addCase(getTimeline.fulfilled, (state, action) => {
			state.loading = false;
			state.timeline = [action.payload];
		})
		.addCase(getTimeline.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})
		.addCase(updateTimeline.pending, (state) => {
			state.loading = true;
		})
		.addCase(updateTimeline.fulfilled, (state, action) => {
			state.loading = false;
			state.timelineData = [action.payload];
			message.success("Timeline updated successfully");
		})
		.addCase(updateTimeline.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
			message.error("Timeline not updated");
		})
		.addCase(deleteTimeline.pending, (state) => {
			state.loading = true;
		})
		.addCase(deleteTimeline.fulfilled, (state, action) => {
			state.loading = false;

			state.timelineData = action.payload;
			message.success("Timeline deleted successfully");
		})
		.addCase(deleteTimeline.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
			message.error("Timeline not deleted");
		})
		.addCase(postEventType.pending, (state) => {
			state.loading = true;
		})
		.addCase(postEventType.fulfilled, (state, action) => {
			state.loading = false;
			state.events = [action.payload];
		})
		.addCase(postEventType.rejected, (state, action) => {
			state.loading = true;
			state.error = [action.payload];
		})
		.addCase(getEventTypeList.pending, (state) => {
			state.loading = true;
		})
		.addCase(getEventTypeList.fulfilled, (state, action) => {
			state.loading = false;
			state.events = [action.payload];
		})
		.addCase(getEventTypeList.rejected, (state, action) => {
			state.loading = true;
			state.error = [action.payload];
		})

		.addCase(deleteEvent.pending, (state) => {
			state.loading = true;
		})
		.addCase(deleteEvent.fulfilled, (state, action) => {
			state.loading = false;
			state.events = action.payload;
		})
		.addCase(deleteEvent.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})
		.addCase(updateEvent.pending, (state) => {
			state.loading = true;
		})
		.addCase(updateEvent.fulfilled, (state, action) => {
			state.loading = false;
			state.events = [action.payload];
		})
		.addCase(updateEvent.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})
	},
});

const TimelineReducer = TimelineSlice.reducer;
export const { setEdit, timelineSeliceReset, setTimelinDataSelice } = TimelineSlice.actions;
export default TimelineReducer;
