import React from "react";
import PropTypes from 'prop-types';
import { Image } from "antd";

const ChatContact = ({ styles, name, avatar, online, message }) => (
	<div className={styles.contact}>
		{avatar && (
			<div className={styles.contactImage}>
				<Image src={avatar} preview={false} />
				{online && <div className={styles.onlineMarker}></div>}
			</div>
		)}
		{name || message ? (
			<div className={styles.chatDetails}>
				{name && <p className={styles.contactName}>{name}</p>}
				{message && <p className={styles.contactMessage}>{message}</p>}
			</div>
		) : null}
	</div>
);

ChatContact.propTypes = {
	styles: PropTypes.object.isRequired,
	name: PropTypes.string,
	avatar: PropTypes.string,
	online: PropTypes.bool,
	message: PropTypes.string,
};

export default ChatContact;
