import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form } from 'antd';
import { getUsers } from '../../redux/Slice/UserSlice';
import { axiosInstance } from '../../services/apiServices/config';
import { getBookData } from '../../redux/Slice/BookSlice';

const useCreateNewBookDrawer = ({ open, close, onSubmit, drawerStatusProp }) => {
	const dispatch = useDispatch();
	const [selectedFile, setSelectedFile] = useState({});
	const [showGallery, setShowGallery] = useState(false);
	const [customErrors, setCustomError] = useState({});
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [currentUser, setCurrentUser] = useState({email: '', visible: false});

	const { colors } = useSelector((state) => state.colors);
	const { seriesData } = useSelector((state) => state.series);
	const { book_data } = useSelector((state) => state.books);
	const { users } = useSelector((state) => state.user);
	const form = useRef();
	const [forms] = Form.useForm();

	const [bookList, setBookList] = useState({
		books: [],
	});

	const handleChange = (e) => {
		let value = e.target.value;
		setBookList({
			...bookList,
			[e.target.name]: value,
		});
	};

	const handleClose = () => {
		close();
		forms.resetFields();
	};

	const handleSubmit = async (e) => {
		let data = { ...e };
		if (selectedFile?.image_url) {
			data.image_id = selectedFile?.id;
		}
		if (selectedFile?.image_url == null && drawerStatusProp !== 1) {
			data.image_id = null;
		}

		data.is_finished = e.is_finished ? 1 : 0;
		if (drawerStatusProp === 1 && "Create new series") {
			data.books = bookList.books;
		}
		form?.current?.resetFields();

		try {
			await onSubmit(data);
		} catch (error) {
			console.error("Failed to submit data", error);
		} finally {
			forms.resetFields();
		}
	};

	useEffect(()=> {
			handleCollaborators()
	}, [book_data])

	const handleCollaborators = async () => {
		if (drawerStatusProp !== 1) {
			try {
				await axiosInstance.post("/collaborators", {
					email: selectedUsers.map(i => i.email),
					collaboratable_id: book_data[0][0].id,
					collaboratable_type: "books",
					role: selectedUsers.map(i => i.role)
				});
			} catch (error) {
				console.error("Failed to add collaborator", error);
			}
		}
	}

	const handleCollaboratorRole = (email) => {
		setCurrentUser({email: email, visible: true});
	}

	const closeCollaboratorRole = () => {
		setCurrentUser({email: '', role: '', visible: false});
	}

	useEffect(() => {
		if (drawerStatusProp === 1) {
			setCustomError({});
		}
		if (users.length === 0) {
			dispatch(getUsers());
		}
	}, [drawerStatusProp, users]);

	return {
		form,
		forms,
		selectedFile,
		setSelectedFile,
		showGallery,
		setShowGallery,
		customErrors,
		handleChange,
		handleClose,
		handleSubmit,
		handleCollaboratorRole,
		closeCollaboratorRole,
		currentUser,
		selectedUsers,
		setSelectedUsers,
		colors,
		seriesData,
		book_data,
		bookList,
		drawerStatusProp,
		users
	};
};

export { useCreateNewBookDrawer };