import React from "react";
import styles from "./Card.module.css";
import threeBars from "../../../../../assets/images/three-bars.png";
import { Image } from "antd";
import menuIcon from "../../../../../assets/images/menu-icon.png";
import { useSelector } from "react-redux";

const FeatureCard = ({ timeline, outline, brainstorm, plot_planner, message, book, series, is_mine, id, handleMenuIcon }) => {
    const { user } = useSelector((state) => state?.auth);
	const handlePushToFeature = (id, type,key) => {
		console.log(key)
		if(is_mine) {
			window.open(`/${type}/${id}`, '_blank');
		} else {
			window.open(`/guest-view/${type+'s'}/${id}?key=${key}&email=${user[0].user.email}`, '_blank');
		}
		return
	}
	return (
		<>
			<div style={{ display: 'flex', flexDirection: is_mine ? 'row-reverse' : 'row', alignSelf: 'center', alignItems: is_mine ? 'flex-end' : 'flex-start', flexFlow: 'column', paddingBottom: '10px' }}>
				{timeline && (
					<button type="button" onClick={() => handlePushToFeature(timeline.id, 'timeline', timeline.collaboration?.key)} className={styles.btnCard}>
						<div className={styles.outlineCardFlex}>
							<div className={styles.outlineCardImage}>
								<img style={{ height: '56px', objectFit: 'contain', objectPosition: 'center', borderRadius: '5px' }} src={timeline.image_url} alt="Card" />
							</div>
							<div className={styles.outlineCardCont}>
								<div>{timeline.name}</div>
								<div>{timeline.description}</div>
							</div>
						</div>
						<div className={styles.detailListing}>
							<ul>
								<li><Image src={threeBars} preview={false} /></li>
								<li>{timeline.character_count ?? 0} characters</li>
								<li>{timeline.block_count ?? 0} blocks</li>
								<li>Updated: April 12,2021</li>
							</ul>
						</div>
					</button>
				)}
				{outline && (
					<button type="button" onClick={() => handlePushToFeature(outline.id, 'outline', outline.collaboration?.key)} className={styles.btnCard}>
						<div className={styles.outlineCardFlex}>
							<div className={styles.outlineCardImage}>
								<img src={outline.image_url} alt="Card" />
							</div>
							<div className={styles.outlineCardCont}>
								<div>{outline.outline_name}</div>
								<div>{outline.outline_description}</div>
							</div>
						</div>
						<div className={styles.detailListing}>
							<ul>
								<li><Image src={threeBars} preview={false} /></li>
								<li>{outline.chapters.length ?? 0} Chapters</li>
								<li>23 Lines</li>
								<li>Updated: April 12,2021</li>
							</ul>
						</div>
					</button>
				)}
				{brainstorm && (
					<button type="button" onClick={() => handlePushToFeature(brainstorm.id, 'brainstorm', brainstorm.collaboration?.key)} className={styles.btnCard}>
						<div className={styles.outlineCardFlex}>
							<div className={styles.outlineCardImage}>
								<img src={brainstorm.image_url} alt="Card" />
							</div>
							<div className={styles.outlineCardCont}>
								<div>{brainstorm.brainstorm_name}</div>
								<div>{brainstorm.description}</div>
							</div>
						</div>
						<div className={styles.detailListing}>
							<ul>
								<li><Image src={threeBars} preview={false} /></li>
								<li>4 Sections</li>
								<li>23 Lines</li>
								<li>Updated: April 12,2021</li>
							</ul>
						</div>
					</button>
				)}
				{plot_planner && (
					<button type="button" onClick={() => handlePushToFeature(plot_planner.id, 'plotline', plot_planner.collaboration?.key)} className={styles.btnCard}>
						<div className={styles.outlineCardFlex}>
							<div className={styles.outlineCardImage}>
								<img src={plot_planner.image_url} alt="Card" />
							</div>
							<div className={styles.outlineCardCont}>
								<div>{plot_planner.plot_planner_planner_name}</div>
								<div>{plot_planner.description}</div>
							</div>
						</div>
						<div className={styles.detailListing}>
							<ul>
								<li><Image src={threeBars} preview={false} /></li>
								<li>4 Sections</li>
								<li>23 Lines</li>
								<li>Updated: April 12,2021</li>
							</ul>
						</div>
					</button>
				)}
				{book && (
					<button type="button" className={styles.btnCard} onClick={()=> handlePushToFeature(book.id, 'book-detial', book.collaboration?.key)} style={{ maxWidth: '150px' }}>
						<img src={book.image_url} alt="Card" />
						<div>{book.book_name}</div>
					</button>
				)}
				{series && (
					<button type="button" className={styles.btnCard} style={{ maxWidth: '150px' }}>
						<img src={series.image_url} alt="Card" />
						<div>{series.series_name}</div>
					</button>
				)}
				{!message && <div onClick={() => {
					handleMenuIcon(id);
					console.log(id)
				}}>
					<Image src={menuIcon} preview={false} />
				</div>}
			</div>
		</>
	);
};

export default FeatureCard;
