import "./index.scss";
import { CaretDownOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteCharacter, getCharacters, updateCharacter } from "../../redux/Slice/CharacterSlice";
import CharacterModal from "../../components/timeline/timeline-events/AddCharacterModal";
import EditCharacterModal from "../../components/timeline/timeline-events/edit/EditCharacterModal";
import { Popover, message } from "antd";
import { AddEventBlockModal, EditEventBlockModal } from "../../components/timeline/timeline-events/EventBlock/AddEventBlockModal";
import { getTimeline } from "../../redux/Slice/TimelineSlice";
import { updateEvBlock } from "../../redux/Slice/EventBlockSlice";
import debounce from 'lodash.debounce';

const Content = ({ c_id, path_id, close }) => {
	const [editModal, setEditModal] = useState(false);
	const dispatch = useDispatch();

	const showEditModal = () => setEditModal(true);
	const editModalOk = () => setEditModal(false);
	const EditModalCancel = () => setEditModal(false);

	const deleteChar = (cid) => {
		dispatch(deleteCharacter(cid));
		setTimeout(() => dispatch(getCharacters(path_id)), 1000);
	};

	const EditCharacter = (data) => {
		try {
			const payload = { id: c_id, data: data };
			dispatch(updateCharacter(payload));
			setTimeout(() => dispatch(getCharacters(path_id)), 1000);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div>
			<div className="edit">
				<button onClick={() => { showEditModal(); close(); }}>
					Edit
					<span className="ml-2">
						<i className="fa fa-check"></i>
					</span>
				</button>
			</div>
			<div className="delete-btn">
				<button onClick={() => { deleteChar(c_id); close(); }}>
					Delete
					<span className="ml-2">
						<i className="fa fa-times"></i>
					</span>
				</button>
			</div>
			<EditCharacterModal
				isModalOpen={editModal}
				handleCancel={EditModalCancel}
				handleOk={editModalOk}
				onSubmit={EditCharacter}
				char_id={c_id}
			/>
		</div>
	);
};

const TimelineEvent = ({ selectedEvent, hasEditor, showEditorModal, mod, geustData }) => {
	const { id } = useParams();
	const [modalOpen, setIsModalOpen] = useState(false);
	const [eventModalOpen, setIsEventModalOpen] = useState(false);
	const [updateBlock, setUpdateBlock] = useState(false);
	const [openIndex, setOpenIndex] = useState(null);
	const [popupVisible, setPopupVisible] = useState(false);
	const [widths, setWidths] = useState({});
	const [stretching, setStretching] = useState(false);
	const [delta, setDelta] = useState(1);
	const [blockId, setBlockId] = useState(null);
	const [clicked, setClicked] = useState(false);
	const divRef = useRef(null);
	const dispatch = useDispatch();
	const { characters } = useSelector((state) => state.character);
	const { loading, timeline } = useSelector((state) => state.timeline);
	const [currentChar, setCurrentChar] = useState({});
	console.log(timeline, 'timeline data')

	const showCharacterModal = () => setIsModalOpen(true);
	const characterModalOk = () => setIsModalOpen(false);
	const characterModalCancel = () => setIsModalOpen(false);

	const showEventModal = () => setIsEventModalOpen(true);
	const eventModalOk = () => setIsEventModalOpen(false);
	const eventModalCancel = () => setIsEventModalOpen(false);

	const showUpdateBlock = () => setUpdateBlock(true);
	const blockModalOk = () => setUpdateBlock(false);
	const blockModalCancel = () => setUpdateBlock(false);

	const char = timeline[0]?.data?.timeline_characters?.map((c) => c);

	const handleMouseDown = (event, blockId) => {
		setStretching(true);
		setDelta(event.clientX - (widths[blockId] || 300));
		setBlockId(blockId);
	};

	const handleMouseMove = (event) => {
		if (clicked && stretching) {
			setWidths((prevWidths) => ({
				...prevWidths,
				[blockId]: event.clientX - delta,
			}));
		}
	};

	const handleMouseOut = useCallback(debounce(() => {
		setStretching(false);
		setClicked(false);
		const blockData = { position_x: delta, size: widths[blockId] || 300 };
		const currectBlockData = currentChar.blocks.filter(i => i.id === blockId)?.map(i => ({ position_x: i.position_x, size: i.size }))[0];
		try {
			const payload = { id: blockId, data: blockData };
			if (payload.id && JSON.stringify(blockData) !== JSON.stringify(currectBlockData)) {
				console.log('trigered')
				dispatch(updateEvBlock(payload));
			}
			setTimeout(() => dispatch(getTimeline({id})), 1000);
		} catch (e) {
			message.error("Can't update block size");
		}
	}, 300), [blockId, currentChar.blocks, delta, dispatch, id, widths]);

	const close = () => setPopupVisible(false);
	const open = (index) => {
		setPopupVisible(true);
		setOpenIndex(index);
	};
	const handleOpenChange = (newOpen) => setPopupVisible(newOpen);

	useEffect(() => {
		let wait = false;
		if (!loading && !wait) {
			dispatch(getCharacters({id, mod, geustData}));
		}
		return () => { wait = true; };
	}, [dispatch, id, loading]);

	console.log(selectedEvent, timeline, characters)
	return (
		<>
			<div className="eventDiv">
				<div className="characterBlock" style={{ maxHeight: "200px" }}>
					<div style={{ position: "relative", bottom: "20px" }}>
						{mod !== 'guest' && <button className="newEventbtn" onClick={hasEditor ? showEventModal : showEditorModal}>
							<PlusOutlined className="mr-1 plusIcon" />
							<span>Add Event Block</span>
						</button>}
					</div>
					{characters?.length > 0 && characters?.map((c) => (
						<div key={c.id}>
							<div>
								{hasEditor ? <Popover
									id={c.id}
									placement="rightBottom"
									content={<Content c_id={c.id} path_id={id} close={close} />}
									title="Actions"
									trigger="click"
									style={{ cursor: "pointer" }}
									open={c.id === openIndex && popupVisible}
									openIndex={openIndex}
									popupVisible={popupVisible}
									onOpenChange={handleOpenChange}
								>
									<button className="charName mr-2" onClick={() => open(c.id)}>
										<CaretDownOutlined className="caretIcon" />
										<span>{c.character_name}</span>
									</button>
								</Popover> : <button className="charName mr-2" onClick={showEditorModal}>
										       <CaretDownOutlined className="caretIcon" />
										     <span>{c.character_name}</span>
									</button>}
								<div className="block mt-2 mb-2">
									{char?.map((ch, index) => (
										char[index]?.blocks?.length > 0 &&
										char[index]?.blocks?.map((block, index) => (
											block?.character_id === c?.id && (
												<div style={{ height: "90px" }} key={index}>
													<div
														style={{
															position: "absolute",
															backgroundColor: block?.color_code,
															width: `${widths[block?.id] || 300}px`,
															cursor: "se-resize",
														}}	
														onMouseDown={hasEditor ? (e) => handleMouseDown(e, block?.id) : null}
														onMouseMove={hasEditor ? handleMouseMove : null}
														onMouseOut={hasEditor ? () => {setCurrentChar(char[index]); handleMouseOut(); setBlockId(block?.id)} : null}
														ref={divRef}
														onClick={hasEditor ? () => {
															setBlockId(block?.id);
															setClicked(true);
															console.log(index)
														} : showEditorModal}
														className="event-block"
													>
														<div className="event-detail">
															<p>{block?.event_name}</p>
															<p>{block?.event_description}</p>
														</div>
														<div className="event-outline">
															{block?.outline}
														</div>
													</div>
												</div>
											)
										))
									))}
								</div>
							</div>
						</div>
					))}
					<div className="newCharBtn">
					{mod !== 'guest' &&<button onClick={hasEditor ? showCharacterModal : showEditorModal} className="mt-3 newChar">
							<PlusOutlined className="mr-1 plusIcon" />
							New Character
						</button>}
					</div>
				</div>
			</div>

			<CharacterModal
				isModalOpen={modalOpen}
				handleCancel={characterModalCancel}
				handleOk={characterModalOk}
				isUpdate={false}
			/>
			<AddEventBlockModal
				handleOk={eventModalOk}
				handleCancel={eventModalCancel}
				isModalOpen={eventModalOpen}
			/>
			<EditEventBlockModal
				handleOk={blockModalOk}
				handleCancel={blockModalCancel}
				isModalOpen={updateBlock}
			/>
		</>
	);
};

export default TimelineEvent;
