import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
// import * as firebase from 'firebase/app';
import store from "./redux/store/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

let persistore = persistStore(store);


  
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<PersistGate persistor={persistore}>
			<App />
		</PersistGate>
	</Provider>
);
