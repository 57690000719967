import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSharedSpecific } from "../../redux/Slice/SharedWork";
import { axiosInstance } from "../../services/apiServices/config";
const useSharedDetails = () => {
    const dispatch = useDispatch();
    const { sharedWorks, outlines, plotlines, brainStorms, timelines, error, loading } = useSelector((state) => state.sharedWork);
    const [sharedBookActivity, setSharedBokActivity] = useState({data: []});
    const [activityLoading, setActivityLoading] = useState(false);
    const [selectedSection, setSelectedSection] = useState("All");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isEditor, setIsEditor] = useState(null);
    const [collaboratorsData, setCollaboratorsData] = useState({
        brainstorms: { users: [], count: 0 },
        outlines: { users: [], count: 0 },
        timelines: { users: [], count: 0 },
        plot_planners: { users: [], count: 0 }
    });
    let bookId = window.location.pathname.split('/').pop();

    useEffect(()=> {
        const fetchSharedSpecific = async () => {
            if (selectedSection !== "All" && bookId) {
                try {
                    await dispatch(getSharedSpecific({ type: selectedSection, collaborator_id: bookId, page: currentPage })).unwrap();
                } catch (err) {
                    console.error(err.message);
                }
            }
        };
        fetchSharedSpecific();
    }, [selectedSection])
    useEffect(() => {
        const fetchActivity = async () => {
            setActivityLoading(true);
            try {
                const response = await axiosInstance.get(`/logs/books/${bookId}?page=${currentPage}`);
                setSharedBokActivity(response.data);
                setTotalPages(response.meta.last_page);
            } catch (err) {
                console.error(err.message);
            } finally {
                setActivityLoading(false);
            }
        };
        const fetchCollaboratorsData = async () => {
            try {
                const response = await axiosInstance.get(`/collaborators/shared/specific/users?book_id=${bookId}`);
                setCollaboratorsData(response.data);
            } catch (err) {
                console.error(err.message);
            }
        };
        const checkIsEditor = () => {
            const editorStatus = sharedWorks.data.filter(work => work.id == bookId)[0].editor;
            setIsEditor(editorStatus);
        };
        fetchActivity();
        fetchCollaboratorsData();
        checkIsEditor();
    }, [bookId]);
    useEffect(()=> {
        if(isEditor === false){
            setSelectedSection("outlines")
        }
    }, [isEditor])
    console.log(selectedSection)
    console.log(sharedBookActivity)
    const handleSectionChange = (key) => {
        setSelectedSection(key);
        setCurrentPage(1);
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    return {
        sharedWorks,
        outlines,
        plotlines,
        brainStorms,
        timelines,
        error,
        loading,
        selectedSection,
        currentPage,
        bookId,
        handleSectionChange,
        handlePageChange,
        sharedBookActivity,
        activityLoading,
        totalPages,
        collaboratorsData,
        isEditor
    };
};
export { useSharedDetails };