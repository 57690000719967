import { useState } from "react";
import { useDispatch } from "react-redux";
import FullPageSpinner from "../components/loader/FullPageSpinner";
import OutlineCard from "../components/outlines/OutlineCard";
import PlotlineCard from "../components/plotline/PlotlineCard";
import BrainstormCard from "../components/brainstorm/BrainstormCard";
import TimelineCard from "../components/timeline/TimelineCard";
import { Tabs, Typography, Card, Row, Col, Avatar, Pagination, Alert } from "antd";
import { ACTIVITY_LOGS } from "../constant/constant";
import { ActivityLog } from "../components/cards/ActivityLog";
import { useSharedDetails } from "../hooks/shared/useSharedDetails";
import { DashboardActivityLog } from "../components/cards/DashboardActivityLog";
import { UserOutlined } from "@ant-design/icons";
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const ITEMS_PER_PAGE = 5;
const Section = ({ title, items, CardComponent, currentPage, onPageChange, shareId }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);

    
    return (
        <div className="section">
            <Title level={3} className="section-title">{title}</Title>
            {error && <Alert message="Error" description={error} type="error" showIcon />}
            {items?.length > 0 ? items.map((item, index) => (
                <Row key={item?.id || index} gutter={[16, 16]} className="mb-2">
                    <Col xs={24}>
                        <Card
                            hoverable
                            className="item-card"
                            style={{
                                backgroundColor: item.color_code,
                                color: item.foreground_color,
                            }}
                        >
                            <CardComponent {...{ [title.toLowerCase().slice(0, -1)]: item }} plotline={item} isShared={true} shareId={shareId} />
                        </Card>
                    </Col>
                </Row>
            )) : (
                <h5>No {title.toLowerCase()} found</h5>
            )}
            <Pagination
                style={{ float: "right" }}
                current={currentPage}
                pageSize={ITEMS_PER_PAGE}
                total={items?.length}
                onChange={onPageChange}
                className="pagination"
            />
        </div>
    );
};
const CountDisplay = ({ title, count, collaborators, type }) => (
    <Card className="count-display" bordered={false} style={{ textAlign: 'left', padding: '12px', marginBottom: '16px' }}>
        <Title level={4} className="count-title" style={{ marginBottom: '4px' }}>{title}</Title>
        <Text className="count-text" style={{ display: 'block', marginBottom: '4px' }}>{type} Count: {count}</Text>
        <Text className="count-text" style={{ display: 'block', marginBottom: '8px' }}>Users: {collaborators.length}</Text>
        <div className="collaborators" style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
            {collaborators.slice(0, 5).map((collaborator, index) => (
                <Avatar
                    key={index}
                    src={collaborator.profile_image_url || null}
                    alt={collaborator.name}
                    icon={!collaborator.profile_image_url ? <UserOutlined /> : null}
                    className="collaborator-avatar"
                    size="small"
                />
            ))}
            {collaborators.length > 5 && <Avatar className="collaborator-avatar" size="small">++</Avatar>}
        </div>
    </Card>
);
const SharedDetails = () => {
    const {
        sharedWorks,
        outlines,
        plotlines,
        brainStorms,
        timelines,
        error,
        loading,
        selectedSection,
        currentPage,
        bookId,
        handleSectionChange,
        handlePageChange,
        sharedBookActivity,
        activityLoading,
        totalPages,
        collaboratorsData,
        isEditor
    } = useSharedDetails();
    const renderContent = () => {
        if (selectedSection === "All" && isEditor) {
            return (
                <Row gutter={[16, 16]}>
                    <Col span={6}><CountDisplay title="Outlines" type="Outline" count={collaboratorsData.outlines.count} collaborators={collaboratorsData.outlines.users} /></Col>
                    <Col span={6}><CountDisplay title="Plotlines" type="Plotline" count={collaboratorsData.plot_planners.count} collaborators={collaboratorsData.plot_planners.users} /></Col>
                    <Col span={6}><CountDisplay title="Brainstorms" type="Brainstorm" count={collaboratorsData.brainstorms.count} collaborators={collaboratorsData.brainstorms.users} /></Col>
                    <Col span={6}><CountDisplay title="Timelines" type="Timeline" count={collaboratorsData.timelines.count} collaborators={collaboratorsData.timelines.users} /></Col>
                </Row>
            );
        } else {
            switch (selectedSection) {
                case "outlines":
                    return <Section title="Outlines" items={outlines[bookId]?.data ?? []} CardComponent={OutlineCard} currentPage={currentPage} onPageChange={handlePageChange} shareId={bookId} />;
                case "plot_planners":
                    return <Section title="Plotlines" items={plotlines[bookId]?.data ?? []} CardComponent={PlotlineCard} currentPage={currentPage} onPageChange={handlePageChange} shareId={bookId} />;
                case "brainstorms":
                    return <Section title="Brainstorms" items={brainStorms[bookId]?.data ?? []} CardComponent={BrainstormCard} currentPage={currentPage} onPageChange={handlePageChange} shareId={bookId} />;
                case "timelines":
                    return <Section title="Timelines" items={timelines[bookId]?.data ?? []} CardComponent={TimelineCard} currentPage={currentPage} onPageChange={handlePageChange} shareId={bookId} />;
                default:
                    return null;
            }
        }
    };
    return (
        <div className="main-container">
            <Tabs activeKey={selectedSection} onChange={handleSectionChange} className="tabs-container">
                {isEditor && <TabPane tab="All" key="All" disabled={!isEditor} />}
                <TabPane tab="Outlines" key="outlines" />
                <TabPane tab="Plotlines" key="plot_planners" />
                <TabPane tab="Brainstorms" key="brainstorms" />
                <TabPane tab="Timelines" key="timelines" />
            </Tabs>
            {loading ? (
                <FullPageSpinner />
            ) : (
                <>
                    {renderContent()}
                    {selectedSection === "All" && isEditor && (
                        <div className="activity-log-section pt-3">
                            <Title level={3} className="section-title">Activity Log</Title>
                            {sharedBookActivity.data.length > 0 ? (
                                <>
                                    <div className="activity-log-list">
                                        {sharedBookActivity.data.map((item, index) => (
                                            <DashboardActivityLog
                                                logsData={item}
                                                key={index}
                                            />
                                        ))}
                                    </div>
                                    <Pagination
                                        current={currentPage}
                                        pageSize={10}
                                        total={totalPages * 10}
                                        onChange={handlePageChange}
                                        showSizeChanger={false}
                                        disabled={activityLoading}
                                        className="activity-pagination"
                                        style={{float: 'right', paddingTop: '10px'}}
                                    />
                                </>
                            ) : !activityLoading && (
                                <div className="no-activity-message">
                                    <p>No activity logs available.</p>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
export default SharedDetails;