import { Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
	getChapters,
	postChapters,
	setLoading,
} from "../../redux/Slice/ChapterSlice";
import { getChapterCards } from "../../redux/Slice/ChapterCardsSlice";
import { getOutline } from "../../redux/Slice/OutlineSlice";
import { hasEditorAccess } from "../../redux/Slice/SharedWork";

const useOutlineDetail = (props) => {
	const { mod } = props;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [popupVisible, setPopupVisible] = useState(false);
	const [openIndex, setOpenIndex] = useState(false);
	const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();
	const { outlines } = useSelector((state) => state.sharedWork);
	const { id } = useParams();
	const sharedId = new URLSearchParams(window.location.search).get('sharedId');
	const email = new URLSearchParams(window.location.search).get('email')
	const key = new URLSearchParams(window.location.search).get('key')
	const { outline } = useSelector((state) => state.outline);
	const { colors } = useSelector((state) => state.colors);
	const { chapters } = useSelector((state) => state.chapters);
	const { chapterCards } = useSelector((state) => state.chaptercard);

	const title = outline[0]?.data?.outline_title;
	const name = outline[0]?.data?.outline_name;
	const desc = outline[0]?.data?.description;

	console.log(loading, 'loading')
	let hasEditor = hasEditorAccess(outlines, id, sharedId);
	let geustData = {
		email,
		key
	}
	const [form] = Form.useForm();

	console.log(chapters, 'chapteers')
	const showModal = () => setIsModalOpen(true);
	const handleOk = () => {
		setIsModalOpen(false);
		SubmitChapters();
	};
	const handleCancel = () => setIsModalOpen(false);

	const SubmitChapters = (e) => {
		let data = { ...e, outline_id: parseInt(id) };
		dispatch(postChapters({ data }));
		setTimeout(() => {
			dispatch(getChapters({id}));
			setIsModalOpen(false);
		}, 1000);
		form.resetFields();
	};

	const showEditorModal = () => {
		setIsEditorModalVisible(true);
	};

	const handleEditorModalOk = () => {
		setIsEditorModalVisible(false);
	};

	const handleEditorModalCancel = () => {
		setIsEditorModalVisible(false);
	};

	useEffect(() => {
		setLoading(true);
		Promise.all([
			dispatch(getChapters({id, mod, geustData})),
			dispatch(getChapterCards()),
			dispatch(getOutline({id, mod, geustData}))
		]).then(() => {
			setLoading(false);
		}).catch(() => {
			setLoading(false);
		});
			
	}, [dispatch, id]);

	const close = () => setPopupVisible(false);
	const open = (index) => {
		setPopupVisible(true);
		setOpenIndex(index);
	};

	return {
		hasEditor,
		id,
		isModalOpen,
		popupVisible,
		openIndex,
		title,
		name,
		desc,
		chapters,
		loading,
		chapterCards,
		colors,
		form,
		showModal,
		handleOk,
		handleCancel,
		SubmitChapters,
		close,
		open,
		isEditorModalVisible,
		showEditorModal,
		handleEditorModalOk,
		handleEditorModalCancel,
	};
};

export { useOutlineDetail };