import { useEffect, useRef, useState, useCallback } from "react";
import { axiosInstance } from "../../services/apiServices/config";
import { useNavigate } from "react-router-dom";

const useChatMessage = (props) => {

    // Props
    const {
        chat,
        setUserReacted,
        setMessageMenu,
        handleDelete,
        fetchMessages,
        setRepliedMessage,
        setEditMessages,
        setScrollType,
        reactedMessage,
        setImagesOnModal,
        showImageModal
    } = props;

    // References for menu and click timer
    const menuRef = useRef(null);
    const clickTimerRef = useRef(null);

    // State management
    const [isHover, setIsHover] = useState(false);
    const [isFileHover, setIsFileHover] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [longPress, setLongPress] = useState(false);
    const [reactions, setReactions] = useState([false, false, false, false]);
    const [updatedMessages, setUpdatedMessages] = useState([]);
    const [currentReaction, setCurrentReaction] = useState(null);
    const [reactionSubmitting, setReactionSubmitting] = useState(false);

    // Extract image URLs from message attachments
    const messageAttachments = chat.attachments ? Object.values(chat.attachments) : [];
    const imageUrls = messageAttachments.map((attachment) => attachment.original_url);

    // Toggle reaction state and post reaction data
    const toggleReaction = useCallback(async (chatId, type) => {
        if (reactionSubmitting) return;
        setReactionSubmitting(true);
        try {
            const response = await axiosInstance.post(`chats/reaction`, { chat_id: chatId, reaction: type });
            const { data } = response.data;

            setUserReacted(prevState => ({
                ...prevState,
                count: prevState.count + 1,
                message: data
            }));

            setUpdatedMessages(prevMessages =>
                prevMessages.map(msg => msg.id === chatId ? data : msg)
            );

            // Update current reaction based on the response
            const hasReaction = ['hearts', 'thumbs_up', 'thumbs_down', 'question_marks'].some(key => data[key] > 0);
            setCurrentReaction({
                reactionType: hasReaction ? type : null,
                chatId: chatId
            });
        } catch (error) {
            console.error("Failed to post reaction", error);
        } finally {
            setReactionSubmitting(false);
        }
    }, [reactionSubmitting, setUserReacted]);

    // Handle reaction based on user interaction
    const handleReaction = useCallback(async (index) => {
        const newReactions = reactions.map((reaction, idx) => idx === index ? !reaction : reaction);
        setReactions(newReactions);
        setScrollType("paginate");
        await toggleReaction(chat.id, index + 1);
    }, [reactions, setScrollType, toggleReaction, chat.id]);

    // Handle long press actions
    const handlePressActions = useCallback((pressDuration) => {
        clickTimerRef.current = setTimeout(() => {
            setLongPress(true);
            setMenuVisible(true);
        }, pressDuration);
    }, []);

    // Handle mouse down and up actions
    const handleMouseActions = useCallback((actionType) => {
        if (actionType === 'down') {
            handlePressActions(500);
        } else if (actionType === 'up') {
            clearTimeout(clickTimerRef.current);
            if (!longPress && menuVisible) {
                setMenuVisible(false);
            }
            setLongPress(false);
        }
    }, [handlePressActions, longPress, menuVisible]);

    // Handle menu icon actions
    const handleMenuIcon = useCallback((id) => {
        setMessageMenu(prevState => ({
            visible: prevState.id !== id,
            index: prevState.index === id ? null : id
        }));
    }, [setMessageMenu]);

    // Handle different actions like copy, delete, reply, and edit
    const handleAction = useCallback(async (actionType, data) => {
        try {
            if (actionType === 'copy') {
                await navigator.clipboard.writeText(data);
            } else if (actionType === 'delete') {
                await axiosInstance.delete(`chats/${data}`);
                handleDelete(data);
                fetchMessages(data);
            } else if (actionType === 'reply') {
                setRepliedMessage({
                    parentId: data,
                });
            } else if (actionType === 'edit') {
                setEditMessages(data);
            }
            setMessageMenu({ visible: false, index: null });
        } catch (error) {
            console.error(`Failed to ${actionType} message`, error);
        }
    }, [handleDelete, fetchMessages, setRepliedMessage, setEditMessages, setMessageMenu]);

    const handlePushToFeature = useCallback((id, route) => {
        window.open(`/${route}/${id}`, '_blank');
    }, []);

    useEffect(() => {
        if (showImageModal.isShow && chat.id === showImageModal.id && messageAttachments.length > 0) {
            setImagesOnModal(messageAttachments);
        }
    }, [showImageModal, chat.id, messageAttachments, setImagesOnModal]);

    // Effect to handle click outside to close the menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    // Effect to update the current reaction based on chat updates
    useEffect(() => {
        const reactionType = chat.hearts ? 1 : chat.thumbs_up ? 2 : chat.thumbs_down ? 3 : chat.question_marks ? 4 : null;
        setCurrentReaction({
            reactionType: reactionType,
            chatId: chat.id
        });
    }, [chat]);

    // Effect to update reaction based on reacted messages
    useEffect(() => {
        const reaction = reactedMessage.find(message => message.chat.id === chat.id);
        if (reaction) {
            const reactionType = reaction.chat.hearts > 0 ? 1 : reaction.chat.thumbs_up > 0 ? 2 : reaction.chat.thumbs_down > 0 ? 3 : reaction.chat.question_marks > 0 ? 4 : null;
            setCurrentReaction({
                reactionType: reactionType,
                chatId: chat.id
            });
        }
    }, [reactedMessage, chat.id]);

    return {
        chatMessageHandlers: {
            handleMenuIcon,
            setIsHover,
            setIsFileHover,
            isFileHover,
            handleMouseActions,
            toggleReaction,
            handleAction,
            handleReaction,
            handlePushToFeature
        },
        chatMessageRefs: {
            clickTimerRef,
            menuRef
        },
        chatMessageState: {
            messageAttachments,
            currentReaction,
            menuVisible
        }
    };
};

export { useChatMessage };