import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { apiServices } from "../../services/apiServices/Api";
// import { deleteChapter } from "./ChapterSlice";

//get chapter Cards
export const getChapterCards = createAsyncThunk(
	"chaptercard/getChapterCards",
	({id}) => {
		const chaptercards = apiServices
			.get(`/chapters-cards?chapter_id=${id}`)
			.then((response) => response)
			.catch((err) => err);
		return chaptercards;
	}
);

//post chapters
export const postChapterCards = createAsyncThunk(
	"chaptercard/postChapterCard",
	(card) => {
		const chaptercards = apiServices
			.post(`/chapters-cards`, card.data)
			.then((response) => response)
			.catch((err) => console.log(err));

		return chaptercards;
	}
);

//delete chapter card
export const deleteChapterCard = createAsyncThunk(
	"chapters/deleteChapterCard",
	(id) => {
		const chapters = apiServices
			.delete(`/chapters-cards/${id}`)
			.then((response) => response)
			.catch((err) => console.log(err));

		// console.log("deleted card payload", chapters);
		return chapters;
	}
);

//update chapter card
export const updateChapterCard = createAsyncThunk(
	"chaptercard/updateChapterCard",
	({ id, data }) => {
		const chapterCard = apiServices
			.update(`/chapters-cards/${id}`, data)
			.then((response) => {
				// console.log("update chapter card", response);
				return response;
			})
			.catch((err) => err);

		return chapterCard;
	}
);

//
export const postTodos = createAsyncThunk("chaptercard/postTodos", (todo) => {
	const chapterCard = apiServices
		.post(`/card-tasks`, todo.data)
		.then((response) => {
			// console.log("todo card", response);
			return response;
		})
		.catch((err) => err);

	return chapterCard;
});
const initialState = {
	chapterCards: [],
	loading: false,
	todos: [],
	error: false,
};

const ChapterSlice = createSlice({
	name: "chaptercard",
	initialState,

	reducers: {
		chapterCardSeliceReset : (state) => {
			Object.assign(state, initialState);
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getChapterCards.pending, (state) => {
			state.loading = true;
		})
		.addCase(getChapterCards.fulfilled, (state, action) => {
			state.loading = false;
			state.chapterCards = [action.payload];
		})
		.addCase(getChapterCards.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
    })

		.addCase(postChapterCards.pending, (state) => {
			state.loading = true;
		})
		.addCase(postChapterCards.fulfilled, (state, action) => {
			state.loading = false;
			state.chapterCards = [action.payload];
			message.success("Chapter Card created successfully");
		})
    .addCase(postChapterCards.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
			message.error("Chapter Card not created");
		})

		//edit chapter card

		.addCase(updateChapterCard.pending, (state, action) => {
			state.loading = true;
		})
		.addCase(updateChapterCard.fulfilled, (state, action) => {
			state.chapterCards = [action.payload];
			state.loading = false;
			message.success("Chapter card updated successfully");
		})
		.addCase(updateChapterCard.rejected, (state, action) => {
			state.chapterCards = [];
			state.error = action.payload;
			message.error("Chapter card not updated");
		})

		//post todos

		.addCase(postTodos.pending, (state, action) => {
			state.loading = true;
		})
		.addCase(postTodos.fulfilled, (state, action) => {
			state.todos = [action.payload];
			state.loading = false;
			message.success("Todo has been created");
		})
		.addCase(postTodos.rejected, (state, action) => {
			state.chapterCards = [];
			state.error = action.payload;
			message.error("Todo not created");
		})

		//delete chapter card
		.addCase(deleteChapterCard.pending, (state, action) => {
			state.loading = true;
		})
		.addCase(deleteChapterCard.fulfilled, (state, action) => {
			state.chapterCards = action.payload;
			state.loading = false;
			message.success("Chapter Card deleted succesfully");
		})
		.addCase(deleteChapterCard.rejected, (state, action) => {
			state.chapterCards = [];
			state.error = action.payload;
			message.error("Chapter Card not deleted");
		})
	},
});

const ChapterCardReducer = ChapterSlice.reducer;
export const { chapterCardSeliceReset } = ChapterSlice.actions;

export default ChapterCardReducer;
