import React from "react";

const ScaleBar = ({ recordingBars, showProgress, barPosition }) => {
	return (
		<div className="scale-bar">
			{showProgress && (
				<div className="track-bar" style={{ left: barPosition + "%" }}></div>
			)}
			{recordingBars.length > 0 && (
				<ul>
					{recordingBars.map((_, index) => (
						<li
							className={`bar ${index % 5 === 0 ? "big" : "small"}`}
							key={index}
						>
							{index % 5 === 0 && index}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default ScaleBar;
