import React, { useCallback, useEffect, useState } from "react";
import { Drawer, Input, Button, Select, Switch, Form } from "antd";
import "./outline-side-modal/outlinemodal.css";
import newBook from "../assets/images/newbook.png";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";

import { getColorData } from "../redux/Slice/ColorSlice";
import UserGallery from "./gallery/UserGallery";

export const UpdateBookDrawer = ({ open, close, onSubmit, id }) => {
	const [selectedFile, setSelectedFile] = useState({});
	const [showGallery, setShowGallery] = useState(false);
	const [selectedOption, setSelectedOption] = useState("");

	const { colors } = useSelector((state) => state.colors);
	const { seriesData } = useSelector((state) => state.series);
	const { book_data } = useSelector((state) => state.books);

	const [bookList] = useState({
		books: [],
	});

	const updateData =
		book_data[0]?.length > 0 && book_data[0]?.filter((book) => book?.id === id);

	const bookName = updateData[0]?.book_name;

	const description = updateData[0]?.book_description;
	const color = updateData[0]?.color_id;
	const image_id = updateData[0]?.image_id;
	const isFinished = updateData[0]?.is_finished;
	const seriesIDs = seriesData[0]?.filter((s) => s?.series_name === updateData[0]?.series_name);

	const [image, setImage] = useState();

	const dispatch = useDispatch();

	const colors_data = useCallback(() => {
		dispatch(getColorData());
	}, [dispatch]);

	const [form] = Form.useForm();

	useEffect(() => {
		colors_data();
		setImage(updateData[0]?.image_url ?? newBook);
		setSelectedFile({
			id: updateData[0]?.image_id,
			image_url: updateData[0]?.image_url,
		});
		form.setFieldsValue({
			image_id: image,
			book_name: bookName,
			book_description: description,
			color_id: color,
			// series_id: seriesIDs,
			is_finished: isFinished,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = (e) => {
		let data = { ...e };
		if (selectedFile?.image_url || image) {
			data.image_id = selectedFile?.id ?? image_id;
		}
		// if (selectedFile?.image_url == null) {
		//   data.image_id = null;
		// }

		if(data.series_id === updateData[0]?.series_name ) {
			data.series_id = seriesIDs[0]?.id
		}

		data.is_finished = e.is_finished === true ? 1 : 0;
		data.book = bookList.books;

		onSubmit(data);
	};
	const { Option } = Select;

	return (
		<>
			<Drawer
				title="Update Book"
				placement="right"
				onClose={close}
				open={open}
				className="p-1 drawer data-drawer"
				zIndex={1}
				autoFocus={false}
				mask={false}
				style={{ position: "fixed", width: "auto" }}
			>
				<Form
					form={form}
					labelCol={{
						span: 4,
					}}
					wrapperCol={{
						span: 24,
					}}
					layout="horizontal"
					initialValues={{
						remember: true,
					}}
					onFinish={handleSubmit}
					autoComplete="off"
					className="gx-signin-form gx-form-row0"
					style={{
						maxWidth: 1000,
					}}
				>
					<label>Artwork</label>
					{showGallery && (
						<UserGallery
							setSelectedFile={setSelectedFile}
							setShowGallery={setShowGallery}
						/>
					)}
					<div className="artwork mt-3">
						<button
							className="btn-artwork"
							type="button"
							onClick={() => setShowGallery(!showGallery)}
						>
							<img src={selectedFile?.image_url ?? newBook} alt="Book Icon" />
						</button>
						{selectedFile && (
							<div className="artworkContainer mt-2">
								<button
									onClick={(e) => {
										e.preventDefault();

										setSelectedFile(null);
									}}
									type="button"
									className="btn-remove"
								>
									Remove
								</button>
							</div>
						)}
					</div>
					<Form.Item
						label="Title"
						name="book_name"
						labelCol={{ span: 24 }}
						rules={[
							{
								required: true,
								message: "Name is required",
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label="Description"
						name="book_description"
						labelCol={{ span: 24 }}
						rules={[
							{
								required: true,
								message: "Description is required",
							},
						]}
					>
						<TextArea style={{ padding: "6px" }}></TextArea>
					</Form.Item>
					<Form.Item
						label="Color"
						name={"color_id"}
						labelCol={{ span: 24 }}
						rules={[
							{
								required: true,
								message: "Color is required",
							},
						]}
					>
						<Select options={colors} placeholder="Select Color" />
					</Form.Item>

					<Form.Item
						label="Series"
						name="series_id"
						labelCol={{ span: 24 }}
						rules={[
							{
								required: false,
								message: "Please select Series",
							},
						]}
						initialValue={seriesData[0].length > 0 ? updateData[0]?.series_name : null}
					>
						<Select
							value={selectedOption}
							placeholder="Series Name"
							onChange={(e) => setSelectedOption(e?.target)}
						>
							{seriesData[0]?.map((s) => (
								<Option value={s?.id}>{s?.series_name}</Option>
							))}
						</Select>
					</Form.Item>

					<div className="mt-1 ">
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<label htmlFor="finish" className="mr-5">
								Is the Book finished
							</label>
							<span className="ml-5">
								<Form.Item name={"is_finished"}>
									<Switch defaultChecked={isFinished === 1 ? true : false} />
								</Form.Item>
							</span>
						</div>
					</div>
					<div className="buttons-container d-flex mt-2">
						<button
							type="submit"
							className="mr-2"
							style={{ cursor: "pointer" }}
						>
							Update
						</button>
						<Button onClick={() => close()} type="ghost">
							Cancel
						</Button>
					</div>
				</Form>
			</Drawer>
		</>
	);
};
