import { DatePicker, Drawer, Form, Input, Select, TimePicker } from "antd";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postCalenderList } from "../../redux/Slice/CalendarSlice";
import dayjs from "dayjs";
import { disabledDate, footer } from "./timepickerFooter";

const CalendarEventDrawer = ({
	open,
	close,
	onSubmit,
	isUpdate,
	form,
	updatedEvent,
	startTime,
	endTime,
	eventDate,
	loading,
}) => {
	const dispatch = useDispatch();
	const { tags } = useSelector((state) => state.colors);
	const [initialState, setInitialState] = useState({});

	console.log(updatedEvent)
	useEffect(() => {
		if (updatedEvent) {
			setInitialState({
				start_time: isUpdate ? dayjs(startTime, "HH:mm") : null,
				end_time: isUpdate ? dayjs(endTime, "HH:mm") : null,
			});

			setTimeout(() => {
				const fieldsValues = {
					title: updatedEvent?.title,
					tag_id: tags.find((tag) => tag.label === updatedEvent?.tag_id)?.id,
					start_time: initialState?.start_time,
					end_time: initialState?.end_time,
					event_date: isUpdate ? dayjs(eventDate, "DD-MM-YYYY") : null,
					tag_id: tags.find(t => t.id === updatedEvent.tag_id)
				};

				if (updatedEvent?.type === "Event") {
					fieldsValues.location = updatedEvent?.location;
					fieldsValues.description = updatedEvent?.description;
				}

				form.setFieldsValue(fieldsValues);
			}, 1000);
		}
	}, [updatedEvent, open])

	const [data, setData] = useState({
	});

	useEffect(() => {
		if (updatedEvent) {
			setData({
				color: updatedEvent?.color,
				color_id: tags.find((tag) => tag.label === updatedEvent?.tag_id)?.id,
				color_name: updatedEvent?.color_name,
				description: updatedEvent?.description,
				end: updatedEvent?.end,
				end_time: dayjs(updatedEvent?.end_time, "HH:mm"),
				event_date: updatedEvent?.event_date,
				foreground_color: updatedEvent?.foreground_color,
				id: updatedEvent?.id,
				location: updatedEvent?.location,
				start: updatedEvent?.start,
				start_time: dayjs(updatedEvent?.start_time, "HH:mm"),
				title: updatedEvent?.title,
				type: updatedEvent?.type
			});
		}


	}, [updatedEvent]);


	const handleSubmit = (e) => {
		let submitData = { ...data, ...e };
		submitData.start_time = `${dayjs(submitData.start_time).format("HH:mm")}:00`;
		submitData.end_time = `${dayjs(submitData.end_time).format("HH:mm")}:00`;
		submitData.event_date = dayjs(submitData.event_date).format("DD-MM-YYYY");
		submitData.card_id = updatedEvent?.card_id;
		submitData.tag_id = tags.find((tag) => tag.label === submitData.tag_id)?.id;

	    !loading &&	onSubmit(submitData);
		setTimeout(() => {
			form.resetFields()
			dispatch(postCalenderList());
		}, 2000);
	};

	const handleClose = () => {
		form.resetFields()
		close();
	}
	

	const { colors } = useSelector((state) => state.colors);
	return (
		<Drawer
			title={isUpdate ? "Edit Event" : "Add Event"}
			placement="right"
			onClose={handleClose}
			open={open}
			className="p-1 drawer data-drawer"
			zIndex={1}
			autoFocus={false}
			mask={false}
			style={{ position: "fixed", width: "auto" }}
		>
			{/* <p>Event Type: {updatedEvent?.type}</p> */}
			<Form
				onFinish={handleSubmit}
				form={form}

				labelCol={{
					span: 4,
				}}
				wrapperCol={{
					span: 24,
				}}
				layout="horizontal"
				autoComplete="off"
				className="gx-signin-form gx-form-row0"
				style={{
					maxWidth: 1000,
				}}
			>
				<Form.Item
					label="Title"
					labelCol={{ span: 24 }}
					name="title"
					rules={[
						{
							required: true,
							message: "Please Enter Event Title!",
						},
					]}
				>
					<Input placeholder="Enter Event Name" />
				</Form.Item>

				<Form.Item
					label={`${updatedEvent?.type === "Todo" ? "Event Time" : "Start Time"}`}
					name="start_time"
					labelCol={{ span: 24 }}
					rules={[
						{
							required: true,
							message: "Please Enter Start Time!",
						},
					]}
				>
					<TimePicker 
					renderExtraFooter={footer} use12Hours={false} format={"HH:mm"} />
				</Form.Item>
				{updatedEvent?.type !== "Todo" && <Form.Item
					label="End Time"
					labelCol={{ span: 24 }}
					name="end_time"
					rules={[
						{
							required: true,
							message: "Please Enter End Time!",
						},
					]}
				>
					<TimePicker renderExtraFooter={footer} use12Hours={false} format={"HH:mm"}  />
				</Form.Item>}

				<Form.Item
					label="Event Date"
					labelCol={{ span: 24 }}
					name="event_date"
					rules={[
						{
							required: true,
							message: "Please Enter Event Date!",
						},
					]}
				>
					<DatePicker disabledDate={disabledDate} format={"DD-MM-YYYY"}/>
				</Form.Item>

				{updatedEvent?.type !== "Todo" && <Form.Item
					label="Event Location"
					labelCol={{ span: 24 }}
					name="location"
					rules={[
						{
							required: true,
							message: "Please Enter Location!",
						},
					]}
				>
					<Input placeholder="Enter Event Location" />
				</Form.Item>}

				{updatedEvent?.type !== "Todo" && <Form.Item
					label="Tags"
					name="tag_id"
					labelCol={{ span: 24 }}
					className="mb-3"
					rules={[
						{
							required: true,
							message: "Please Select a Tag!",
						},
					]}
				>
					<Select options={tags} placeholder="Select Tag" />
				</Form.Item>}
				{updatedEvent?.type !== "Todo" && <Form.Item
					label="Description"
					name="description"
					labelCol={{ span: 24 }}
					className="mb-3"
					rules={[
						{
							required: true,
							message: "Please enter description",
						},
					]}
				>
					<Input.TextArea placeholder="Enter Event Description" />
				</Form.Item>}

				<div className="buttons-container mt-3 d-flex space-between">
					<button type="submit" className="mr-2" style={{ cursor: "pointer" }}>
						Save
					</button>
					<button type="ghost" onClick={handleClose}>
						Cancel
					</button>
				</div>
			</Form>
		</Drawer>
	);
};

export default CalendarEventDrawer;