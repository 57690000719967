import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { apiServices } from "../../services/apiServices/Api";

//get users
export const getUsers = createAsyncThunk("user/getUsers", () => {
	const user = apiServices.get(`/collaborators?search=&page=1`).then((response) => {
		return response;
	});
	return user;
});

//search users
export const searchUsers = createAsyncThunk("user/searchUsers", (name) => {
	const user = apiServices
		.get(`/users?search=${name}`)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			message.error("No user found");
		});
	// console.log("search user", user);
	return user;
});

//edit user fields

export const editUsers = createAsyncThunk(
	"user/editUsers",
	({ field_name, field_value }) => {
		// console.log(field_name, field_value);
		const payload = { field_name, field_value };
		const user = apiServices
			.post(`/edit-user-fields`, payload)
			.then((response) => {
				if (response.success) {
					message.success(response.msg);
				} else {
					message.error(response.msg);
				}
				// console.log("RESPONSE", response);
				return response;
			})
			.catch((err) => {
				message.error("Updated Failed");
			});
		return user;
	}
);

const initialState = {
	users: [],
	loading: false,
	error: false,
	searchData: [],
};

const userSlice = createSlice({
	name: "user",
	initialState,

	reducers: {
		userSeliceReset : (state) => {
			Object.assign(state, initialState);
		}
	},
	extraReducers: (builder) => {

		builder.addCase(getUsers.pending, (state) => {
			state.loading = true;
		})
		.addCase(getUsers.fulfilled, (state, action) => {
			state.loading = false;
			state.users = [action.payload];
		})
		.addCase(getUsers.rejected, (state, action) => {
			state.loading = true;
			state.error = [action.payload];
			state.users = [];
		})

		//SEARCH USERS
		.addCase(searchUsers.pending, (state, action) => {
			state.loading = true;
		})
		.addCase(searchUsers.fulfilled, (state, action) => {
			state.loading = false;
			state.searchData = [action.payload];
		})
		.addCase(searchUsers.rejected, (state) => {
			state.loading = true;
		})

		//edit users
		.addCase(editUsers.pending, (state) => {
			state.loading = true;
		})
		.addCase(editUsers.fulfilled, (state, action) => {
			state.loading = false;
			state.users = [action.payload];

			// console.log("edit payload", action.payload);
		})
		.addCase(editUsers.rejected, (state) => {
			state.loading = true;
		})
	},
});

const UserReducer = userSlice.reducer;
export const { userSeliceReset } = userSlice.actions;
export default UserReducer;
