import { message } from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setBrainstormValues } from "../redux/Slice/BrainStormSlice";
import { setOutlinesBySeliece } from "../redux/Slice/OutlineSlice";
import { setPlotPlainerSelice } from "../redux/Slice/PlotlineSlice";
import { setTimelinDataSelice } from "../redux/Slice/TimelineSlice";

// console.log(token, "====================");
const getOutlines = (bookId, page, setOutlines, setTotalRecords, dispatch) => {
	return new Promise((resolve, reject) => {
		const token = localStorage.getItem("token");
		axios(
			`${process.env.REACT_APP_API_URL}/outlines-list${
				bookId ? "/" + bookId : ""
			}?page=${page || 1}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			}
		)
			.then((response) => {
				setOutlines(response.data.data);
				!bookId && dispatch(setOutlinesBySeliece(response.data.data));
				setTotalRecords(response.data.meta.total);
				resolve(response.data);
			})
			.catch((error) => {
				if (error.response.status === 403) {
					message.error(error.response.data.message);
				} else {
					message.error("Could not fetch Outlines");
				}
				reject(error);
			});
	});
};

const getBooks = async (bookId, setSelectedBook, setBooks) => {
	const token = localStorage.getItem("token");
	axios(`${process.env.REACT_APP_API_URL}/books-list`, {
		headers: {
			Authorization: `Bearer ${token}`,
			Accept: "application/json",
		},
	})
		.then((response) => {
			const bookOptions = response.data.data.map((book) => {
				if (parseInt(bookId) === book.id) {
					setSelectedBook(() => ({
						value: book.id,
						label: book.book_name,
					}));
				}

				return {
					value: book.id,
					label: book.book_name,
				};
			});
			setBooks(bookOptions);
		})
		.catch((error) => {
			if (error.response.status === 403) {
				message.error(error.response.data.message);
			} else {
				message.error("Could not fetch Books");
			}
		});
};


const getBrainstorms = async (bookId, page, setBrainstorms, setTotalRecords, dispatch) => {
	const token = localStorage.getItem("token");

	console.log(dispatch)
	return new Promise((resolve, reject) => {
	   	axios(
			`${process.env.REACT_APP_API_URL}/brainstorms-list${
				bookId ? "/" + bookId : ""
			}?page=${page || 1}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			}
		).then((response) => {
				console.log(response.data.data.length)
				setBrainstorms(response.data.data);
				setTotalRecords(response.data.meta.last_page);
				console.log(response, 'response')
				!bookId && dispatch(setBrainstormValues(response.data.data));
				resolve(response.data);
			})
			.catch((error) => {
				if (error.response.status === 403) {
					message.error(error.response.data.message);
				} else {
					message.error("Could not fetch Brainstorms");
				}
				reject(error);
			});
	});
};

const getPlotlines = async (bookId, page, setPlotlines, setTotalRecords, dispatch) => {
	const token = localStorage.getItem("token");

	return new Promise((resolve, reject) => {
		axios(
			`${process.env.REACT_APP_API_URL}/plot-planners-list${
				bookId ? "/" + bookId : ""
			}?page=${page || 1}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			}
		)
			.then((response) => {
				console.log(response.data.data);
				setPlotlines(response.data.data);
				!bookId && dispatch(setPlotPlainerSelice(response.data.data));
				setTotalRecords(response.data.meta.total);
				resolve(response.data);
			})
			.catch((error) => {
				if (error.response.status === 403) {
					message.error(error.response.data.message);
				} else {
					message.error("Could not fetch Plotlines");
				}
				reject(error);
			});
	});
};

const getTimelines = (bookId, page, setTimelines, setTotalRecords, dispatch) => {
	const token = localStorage.getItem("token");

	return new Promise((resolve, reject) => {
		axios(
			`${process.env.REACT_APP_API_URL}/timelines-list${
				bookId ? "/" + bookId : ""
			}?page=${page || 1}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			}
		)
			.then((response) => {
				setTimelines(response.data.data);
				setTotalRecords(response.data.meta.total);
				!bookId && dispatch(setTimelinDataSelice(response.data.data));
				resolve(response.data);
			})
			.catch((error) => {
				if (error.response.status === 403) {
					message.error(error.response.data.message);
				} else {
					message.error("Could not fetch Timelines");
				}
				reject(error);
			});
	});
};

export { getBooks, getBrainstorms, getOutlines, getPlotlines, getTimelines };
