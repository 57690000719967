import { useCallback } from 'react';
import { useDispatch } from "react-redux";
import { AuthSeliceReset } from "../redux/Slice/AuthSlice";
import { bookSeliceReset } from "../redux/Slice/BookSlice";
import { brainstormSeliceReset } from "../redux/Slice/BrainStormSlice";
import { calenderSeliceReset } from "../redux/Slice/CalendarSlice";
import { chapterCardSeliceReset } from "../redux/Slice/ChapterCardsSlice";
import { characterSeliceReset } from "../redux/Slice/CharacterSlice";
import { chatSeliceReset } from "../redux/Slice/ChatSlice";
import { colorSliceReset } from "../redux/Slice/ColorSlice";
import { dashboardSeliceReset } from "../redux/Slice/dashboard";
import { eventBlockSeliceReset } from "../redux/Slice/EventBlockSlice";
import { gallerySeliceReset } from "../redux/Slice/GallerySlice";
import { outlineSeliceReset } from "../redux/Slice/OutlineSlice";
import { plotlineSeliceReset } from "../redux/Slice/PlotlineSlice";
import { seriesSliceReset } from "../redux/Slice/SeriesSlice";
import { timelineSeliceReset } from "../redux/Slice/TimelineSlice";
import { userSeliceReset } from "../redux/Slice/UserSlice";
import { setSharedWorks } from '../redux/Slice/SharedWork';

export const useResetAllState = () => {
    const dispatch = useDispatch();

    const resetAll = useCallback(() => {
        // dispatch(AuthSeliceReset());
        dispatch(bookSeliceReset());
        dispatch(brainstormSeliceReset());
        dispatch(calenderSeliceReset());
        dispatch(chapterCardSeliceReset());
        dispatch(characterSeliceReset());
        dispatch(chatSeliceReset());
        dispatch(colorSliceReset());
        dispatch(dashboardSeliceReset());
        dispatch(eventBlockSeliceReset());
        dispatch(gallerySeliceReset());
        dispatch(outlineSeliceReset());
        dispatch(plotlineSeliceReset());
        dispatch(seriesSliceReset());
        dispatch(timelineSeliceReset());
        dispatch(userSeliceReset());
        dispatch(setSharedWorks([]))
    }, [dispatch]);

    return { resetAll };
}
