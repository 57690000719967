import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessageFetching } from "../../../../../redux/Slice/ChatSlice";
import { Button, Spin } from "antd";

export function Paginate({ setScrollType , handleScroll }) {
    const dispatch = useDispatch();
    const [scrollCount, setScrollCount] = useState(0);
    const {loading} = useSelector((state)=> state.chat)

    const handleClick = () => {
        dispatch(setMessageFetching(true))
        setScrollCount(prevCount => prevCount + 1);
        setScrollType("paginate");
        setTimeout(() => {
            handleScroll();
        }, 1000);
    };
    console.log(loading, 'fetching', handleScroll)

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "10px" }}>
            <Button 
                disabled={loading}
                onClick={handleClick} 
                style={{ 
                    padding: "5px 10px", 
                    fontSize: "14px", 
                    backgroundColor: "#007BFF", 
                    color: "#FFF", 
                    border: "none", 
                    borderRadius: "20px", 
                    cursor: "pointer",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                }}
            >
                {loading ? <Spin /> : "Load More"}
            </Button>
        </div>
    );
}
