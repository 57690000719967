import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const HeaderDataContext = createContext({
	heading: "",
	setHeading: () => {},
	selectedContact: null,
	setSelectedContact: () => {},
	setContactId: () => {},
	contact_id: null,
});

export default function HeaderContext({ children }) {
	const {user} = useSelector((state)=> state.auth)
	const [heading, setHeading] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [contact_id, setContactId] = useState(null);

	useEffect(()=> {
		const hasPackage = user[0].user?.package
		if(hasPackage){
			setHeading("Dashboard")
		} else {
			setHeading("Plains")
		}
	}, [user])
	return (
		<HeaderDataContext.Provider
			value={{
				heading,
				setHeading,
				selectedContact,
				setSelectedContact,
				contact_id,
			}}
		>
			{children}
		</HeaderDataContext.Provider>
	);
}
