import TimelineCard from "../components/cards/TimelineCard";
import BrainstormCard from "../components/cards/BrainstormCard";
import OutlineCard from "../components/cards/OutlineCard";
import PlotCard from "../components/cards/PlotCard";
import BookCard from "../components/cards/BookCard";
import SeriesCard from "../components/cards/SeriesCard";

const populateFeatures = (feature, data, setChatFeature, chat, setChat) => {
	const removeCommands = () => {
		// Define regex patterns for commands you want to remove
		const commandPatterns = [/\/timeline/g, /\/plot/, /\/book/, /\/series/, /\/brainstorm/, /\/outline/g];
		// Remove commands from the message using regex and replace
		return setChat(commandPatterns.reduce((msg, pattern) => msg.replace(pattern, ''), chat));
	};

	if (data.length === 0) {
		return [];
	}

	switch (feature) {
		case "timeline":
			return populateTimeline(data, setChatFeature, removeCommands);
		case "brainstorm":
			return populateBrainstorm(data, setChatFeature, removeCommands);
		case "outline":
			return populateOutline(data, setChatFeature, removeCommands);
		case "plot":
			return populatePlot(data, setChatFeature, removeCommands);
		case "book":
			return populateBook(data, setChatFeature, removeCommands);
		case "series":
			return populateSeries(data, setChatFeature, removeCommands);
		default:
			break;
	}
};

const populateTimeline = (data, setChatFeature, removeCommands) => {
	const features = data.map((item) => (
		<TimelineCard key={item.id} item={item} setChatFeature={setChatFeature} removeCommands={removeCommands} />
	));
	return features;
};

const populateBrainstorm = (data, setChatFeature, removeCommands) => {
	const features = data.map((item) => (
		<BrainstormCard key={item.id} item={item} setChatFeature={setChatFeature} removeCommands={removeCommands} />
	));
	return features;
};

const populateOutline = (data, setChatFeature, removeCommands) => {
	const features = data.map((item) => (
		<OutlineCard key={item.id} item={item} setChatFeature={setChatFeature} removeCommands={removeCommands} />
	));
	return features;
};
const populatePlot = (data, setChatFeature, removeCommands) => {
	const features = data.map((item) => (
		<PlotCard key={item.id} item={item} setChatFeature={setChatFeature} removeCommands={removeCommands} />
	));
	return features;
};
const populateBook = (data, setChatFeature, removeCommands) => {
	const features = data.map((item) => (
		<BookCard key={item.id} item={item} setChatFeature={setChatFeature} removeCommands={removeCommands} />
	));
	return features;
};
const populateSeries = (data, setChatFeature, removeCommands) => {
	const features = data.map((item) => (
		<SeriesCard key={item.id} item={item} setChatFeature={setChatFeature} removeCommands={removeCommands} />
	));
	return features;
};

export { populateFeatures };
