import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBookData } from "../../redux/Slice/BookSlice";
import { HeaderDataContext } from "../../context/HeaderContext";
import { getSharedWorks } from "../../redux/Slice/SharedWork";
import { axiosInstance } from "../../services/apiServices/config";

const useShared = () => {
	const header = useContext(HeaderDataContext);
	header.setHeading("Shared Work");

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { book_data } = useSelector((state) => state.books);
	const { sharedWorks, loading } = useSelector((state) => state.sharedWork);

	const { user } = useSelector((state) => state.auth)

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [activityLogs, setActivityLogs] = useState([])
	const [loadingActivityLogs, setLoadingActivityLogs] = useState(false);

	const itemsPerPage = 10;

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};


	const fetchActivity = async(userId)=> {
		setLoadingActivityLogs(true);
		const response = await axiosInstance.get(`/logs/shared/${userId}`)
		await setActivityLogs(response.data.data)
		setLoadingActivityLogs(false);
	}


	useEffect(() => {
		dispatch(getBookData());
		dispatch(getSharedWorks(currentPage));
	}, [dispatch, currentPage]);

	useEffect(() => {
		if (sharedWorks.meta) {
			setTotalPages(sharedWorks.meta.last_page);
		}
	}, [sharedWorks]);


	useEffect(()=> {
		if(user && user.length > 0){
			let userId = user[0]?.user?.id;
			if(userId){
				fetchActivity(userId);
			}
		}
	},[])


	const handleBookClick = (bookId) => {
        navigate(`/shared-details/${bookId}`);
    };

	return { 
		dispatch,
		book_data,
        sharedWorks,
        handleBookClick,
        currentPage,
        itemsPerPage,
        handlePageChange,
        totalPages,
        loading,
		activityLogs,
        loadingActivityLogs

     };
};

export { useShared };