import { UserOutlined } from "@ant-design/icons";
import { Avatar, Select, Form } from "antd";

const CollaborativeShareField = ({ label, users, selectedUsers, setSelectedUsers, handleCollaboratorRole, closeCollaboratorRole, isRequired = true }) => {
	const isValidEmail = (email) => {
		if (typeof email !== 'string') return false;
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email);
	};

	const isValidUser = (value) => {
		const userNames = users[0]?.data.map(user => user.name);
		return userNames.includes(value) || isValidEmail(value);
	};
	return (
		<>
			<Form.Item
				label={label}
				name="shared_with"
				rules={[
					{
						required: isRequired,
						message: "Please select users to share with",
					},
				]}
			>
				<Select
					mode="tags"
					onChange={(value) => {
						const lastValue = value[value.length - 1];
						if (typeof lastValue === 'string' && isValidUser(lastValue)) {
							setSelectedUsers(prevSelectedUsers => {
								const newUser = { id: Math.random(), email: lastValue, role: 'viewer' };
								if (!prevSelectedUsers.some(user => user.email === newUser.email)) {
									return [...prevSelectedUsers, newUser];
								}
								return prevSelectedUsers;
							});
                            handleCollaboratorRole(lastValue);
						} else if (typeof lastValue === 'number') {
							const newUsers = users[0]?.data.filter(user => value.includes(user.id));
							setSelectedUsers(prevSelectedUsers => {
								const uniqueNewUsers = newUsers.filter(newUser => !prevSelectedUsers.some(user => user.id === newUser.id));
								return [...prevSelectedUsers, ...uniqueNewUsers];
							});
                            handleCollaboratorRole(...newUsers.map(user => user.email));
						} else {
							// Remove invalid value
							value.pop();
						}
					}}
					onDeselect={(value) => {
						setSelectedUsers(prevSelectedUsers => prevSelectedUsers.filter(user => user.id !== value && user.email !== value));
					}}
					options={[
						...users[0]?.data.map((user) => ({
							id: user.id,
							label: user.name ?? user.email,
							value: user.id,
						})),
						...selectedUsers.filter(user => !users[0]?.data.some(u => u.email === user.email)).map((user) => ({
							id: user.id,
							label: user.email,
							value: user.email,
						})),
					]}
					tokenSeparators={[","]}
				/>
			</Form.Item>
			<div className="d-flex flex-wrap gap-2 pb-1">
				{selectedUsers.map((selectedUser) => {
					const user = users[0]?.data.find((u) => u.id === selectedUser.id);
					return (
						<Avatar key={selectedUser.id} size="default" src={user?.avatar} icon={!user?.avatar && <UserOutlined />} />
					);
				})}
			</div>
		</>
	);
};

export { CollaborativeShareField };
