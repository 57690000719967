import { Form, Input, Select, Button, Drawer, DatePicker, Upload } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import newBook from "../../assets/images/newbook.png";
import { getChapters } from "../../redux/Slice/ChapterSlice";
import UserGallery from "../gallery/UserGallery";
import { axiosInstance } from "../../services/apiServices/config";
import dayjs from "dayjs";
import { UploadOutlined } from "@ant-design/icons";
import { getTimelineList } from "../../redux/Slice/TimelineSlice";
import { getPlotlineList } from "../../redux/Slice/PlotlineSlice";
import { getBrainstorms } from "../../helpers/GetList";
import { getUsers } from "../../redux/Slice/UserSlice";

const OutlineSideModal = ({ open, close, chapterCardId, onsubmit, chapter_id }) => {

    const { colors } = useSelector((state) => state.colors);
    const { chapters } = useSelector((state) => state.chapters);
    const { users } = useSelector((state) => state.user);
    const { book_data } = useSelector((state) => state.books);
    const { bstorm } = useSelector((state) => state.brainstorm);
    const { timelineData } = useSelector((state) => state.timeline);
    const { plotlines } = useSelector((state) => state.plotline);
    const [name, setName] = useState("");
    const [color, setColor] = useState("");
    const [description, setDescription] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [taskToRemove, setTaskToRemove] = useState([]);
    const [showGallery, setShowGallery] = useState(false);
    const [customErrors, setCustomErrors] = useState({});
    const [attachments, setAttachments] = useState([]);
    const [attachmentsToRemove, setAttachmentsToRemove] = useState([]);
    const [attachmentsToAdd, setAttachmentsToAdd] = useState([]);
    const [loading, setLoading] = useState(false);
    const [collaboratorsToUpdate, setCollaboratorsToUpdate] = useState([]);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            dispatch(getChapters({ id }));
            form.resetFields();
        }
    }, [id, dispatch, open, form]);

    useEffect(() => {
        if (chapters?.length > 0) {
            const updateData = chapters[0]?.data?.chapters?.length > 0 && chapters[0]?.data?.chapters?.find((c) => c.id === chapter_id)?.cards.find((i) => i.id === chapterCardId);

            console.log(updateData)
            if (updateData.id) {
                setName(updateData.card_title);
                setColor(colors.filter(c => c.color === updateData.color)[0]?.id ?? updateData.id);
                setDescription(updateData.card_description);
                setSelectedFile(updateData?.image ? {
                    id: updateData.image.id,
                    image_name: updateData.image.image,
                    image_url: `https://charliapp-v2.clickysoft.net/storage/gallery/${updateData.image.user_id}/` + updateData.image.image
                } : null);
                updateData.tasks.length > 0 && setTasks(updateData.tasks.map(item => {
                    return {
                        id: item.id,
                        task_id: item.task_id,
                        title: item.title,
                        due_date: new Date(item.due_date),
                        task_type: item.task_type,
                        type_id: item.type_id,
                        collabration_id: item?.collabration_id,
                        collaboration: item.collaboration,
                        status: item.status,
                        role: item.collaboration[0].role
                    }
                }) || []);
                attachments.length === 0 &&  setAttachments(updateData.attachments?.map(i => ({
                    name: i.attachment,
                    uid: i.id,
                    ...i
                })) || []);
            }
        }

    }, [chapterCardId, chapters, close, open]);
    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                dispatch(getUsers()),
                dispatch(getTimelineList()),
                dispatch(getPlotlineList()),
                getBrainstorms(null, 1, dispatch)
            ]);
        };
        fetchData();
    }, [dispatch]);
    useEffect(() => {
        if (chapters?.length > 0) {
            form.setFieldsValue({
                card_title: name,
                color_id: color,
                card_description: description,
                tasks: tasks.map((task, index) => ({
                    [`tasks[${index}].title`]: task.title,
                    [`tasks[${index}].due_date`]: task.due_date,
                    [`tasks[${index}].task_type`]: task.task_type,
                    [`tasks[${index}].collabration_id`]: task.collabration_id,
                    [`tasks[${index}].type_id`]: task.type_id,
                    [`tasks[${index}].status`]: task.status,
                    [`tasks[${index}].role`]: task.collaboration[0] && task.collaboration[0]?.role,
                })),
            });
        }
    }, [open, name, color, description, tasks, attachments, form, chapters, close]);

    const updateUniqueCollaborators = useCallback(() => {
        if (collaboratorsToUpdate.length > 0) {
            const uniqueCollaborators = collaboratorsToUpdate.reduce((acc, current) => {
                const x = acc.find(item => item.collabration_id === current.collabration_id);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc.filter(item => item.collabration_id !== current.collabration_id).concat([current]);
                }
            }, []);
            setCollaboratorsToUpdate(uniqueCollaborators);
        }
    }, [collaboratorsToUpdate]);

    console.log(form.getFieldsValue())
    const handleSubmit = async (values) => {
        setLoading(true);
        let data = {
            id: chapterCardId,
            card_title: values.card_title,
            card_description: values.card_description,
            color_id: values.color_id,
            image_id: selectedFile?.id ?? null,
            tasks: tasks
        };

        try {
            if(data) {

            const deletePromises = taskToRemove.map(item => axiosInstance.get(`task-delete/${item}`));
            const removeAttachmentPromises = attachmentsToRemove.map(id => axiosInstance.delete(`/attachments/${id}`));
            const attachmentPromises = attachmentsToAdd.map(item => {
                const attachedForm = new FormData();
                attachedForm.append('file_path', item.originFileObj);
                attachedForm.append('chapter_card_id', chapterCardId);
                return axiosInstance.post('/attachments', attachedForm);
            }) || [];
            
            await Promise.all(deletePromises).then(()=> setTaskToRemove([]));
            await Promise.all(removeAttachmentPromises).then(() => setAttachmentsToRemove([]));
            await Promise.all(attachmentPromises).then(() => setAttachmentsToAdd([]));

            const collaboratorData = {
                id: collaboratorsToUpdate.map(i => i.id)[0],
                email: collaboratorsToUpdate.map(i => users[0]?.data?.find(user => user.id === i.collabration_id)?.email)[0],
                collaboratable_id: collaboratorsToUpdate.map(i => i.type_id)[0],
                collaboratable_type: collaboratorsToUpdate.map(i => i.task_type)[0],
                role: collaboratorsToUpdate.map(i => i.role ?? "editor" )[0]
            };

            const fetchCollabUpdate = async(i)=> {
                await axiosInstance.put(`/collaborators/${i.id}`, collaboratorData).then(()=> setCollaboratorsToUpdate([]))
            };
            collaboratorsToUpdate.length > 0 && Promise.all(collaboratorsToUpdate.map((i) => fetchCollabUpdate(i)));

            const response = await onsubmit(data);
            const cardId = response.payload?.data?.id;

           

            close();
            }
        } catch (error) {
            console.error("Error in handleSubmit:", error);
        } finally {
            setLoading(false);
        }
    };

    console.log(collaboratorsToUpdate, 'collab');

    const addTask = useCallback(() => {
        setTasks([...tasks, { id: null, title: "", due_date: new Date(), task_type: null, collabration_id: null, status: "in_progress", role: "editor" }]);
    }, [tasks]);


    const removeTask = useCallback((index, id) => {
        const newTasks = tasks.filter((_, i) => i !== index);
        if (!taskToRemove.includes(id)) {
            setTaskToRemove([...taskToRemove, id]);
        }
        setTasks(newTasks);
        form.setFieldsValue({
            [`tasks[${index}].title`]: undefined,
            [`tasks[${index}].due_date`]: undefined,    
            [`tasks[${index}].collaborator`]: undefined,
            [`tasks[${index}].status`]: undefined,
            [`tasks[${index}].task_type`]: undefined,
            [`tasks[${index}].timeline_period`]: undefined,
            [`tasks[${index}].role`]: undefined,
        });
    }, [tasks, form, taskToRemove]);

    console.log(attachmentsToRemove)

    const filterUser = useCallback((id) => {
        let user = users[0].data.find(user => user.id === id);
        return user?.id;
    }, [users]);

    const filterBsStorm = useCallback((type_id) => {
        const item = bstorm.find((b) => b.id === type_id);
        return item?.id;
    }, [bstorm]);

    const filterBook = useCallback((type_id) => {
        const item = book_data[0]?.find((b) => b.id === type_id);
        return item?.id;
    }, [book_data])

    const filterTimeline = useCallback((type_id) => {
        const item = timelineData.find((t) => t.id === type_id);
        return item?.id
    }, [timelineData]);

    const filterPlotline = useCallback((type_id) => {
        const item = plotlines.find((p) => p.id === type_id);
        return item?.id
    }, [plotlines]);

    console.log(form.getFieldsValue())
    const renderAdditionalOptions = useCallback((taskType, type_id, index) => {
        console.log(taskType)
        const options = {
            book: {
                label: "Book",
                name: `tasks[${index}].book_genre`,
                placeholder: "Select Book Genre",
                options: book_data[0]?.map(book => ({ label: book.book_name, value: book.id })) || [],
            },
            brainstorm: {
                label: "Brainstorm",
                name: `tasks[${index}].brainstorm_topic`,
                placeholder: "Select Brainstorm Topic",
                options: bstorm?.map(b => ({ label: b.brainstorm_name, value: b.id })) || [],
            },
            timeline: {
                label: "Timeline",
                name: `tasks[${index}].timeline_period`,
                placeholder: "Select Timeline Period",
                options: timelineData?.map(t => ({ label: t.name, value: t.id })) || [],
            },
            plot_planner: {
                label: "Plotline",
                name: `tasks[${index}].plotline_summary`,
                placeholder: "Select Plotline Summary",
                options: plotlines?.map(p => ({ label: p.plot_planner_title, value: p.id })) || [],
            },
        };

        console.log(taskType[taskType.length - 1] === 's' ? taskType.substring(0, taskType.length - 1) : taskType )
        const option = options[taskType[taskType.length - 1] === 's' ? taskType.substring(0, taskType.length - 1) : taskType];
        if (!option) return null;

        const selectedValue = option.options.find(opt => opt.value === type_id);

        return (
            <Form.Item
                label={option.label}
                name={option.name}
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: `${option.label} is required` }]}
                initialValue={selectedValue ? selectedValue.value : undefined}
            >
                {option.component || (
                    <Select
                        options={option.options}
                        placeholder={option.placeholder}
                        value={selectedValue ? selectedValue.value : undefined}
                        onChange={(value) => {
                            const newTasks = [...tasks];
                            newTasks[index].type_id = value;
                            setTasks(newTasks);
                            setCollaboratorsToUpdate([...collaboratorsToUpdate, newTasks[index]]);
                            updateUniqueCollaborators()
                        }}
                    />
                )}
            </Form.Item>
        );
    }, [book_data, bstorm, timelineData, plotlines, tasks]);

    const renderTaskForm = useCallback((task, index) => {
        const commonFields = (
            <>
                <Form.Item
                    label="Task Title"
                    name={`tasks[${index}].title`}
                    labelCol={{ span: 24 }}
                    initialValue={task.title}
                    rules={[{ required: true, message: "Title is required" }]}
                >
                    <Input value={task.title} onChange={(e) => {
                        const newTasks = [...tasks];
                        newTasks[index].title = e.target.value;
                        setTasks(newTasks);
                    }} />
                </Form.Item>
                <Form.Item
                    label="Due Date"
                    name={`tasks[${index}].due_date`}
                    labelCol={{ span: 24 }}
                    initialValue={dayjs(tasks[index].due_date)}
                    rules={[{ required: true, message: "Due date is required" }]}
                >
                    <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        style={{ width: "100%" }}
                        placeholder="Select Due Date and Time"
                        value={dayjs(tasks[index].due_date)}
                        defaultValue={dayjs(tasks[index].due_date)}
                        onChange={(value) => {
                            const newTasks = [...tasks];
                            newTasks[index].due_date = value;
                            setTasks(newTasks);
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Collaborator"
                    name={`tasks[${index}].collaborator`}
                    labelCol={{ span: 24 }}
                    initialValue={task.collaboration?.map(user => ({ label: user.name ?? user.email, value: user.id }))}
                    rules={[{ required: true, message: "Collaborator is required" }]}
                >
                    <Select
                        options={users[0].data.map(user => ({ label: user.name ?? user.email, value: user.id })) || []}
                        placeholder="Select Collaborator"
                        value={task.collabration_id}
                        onChange={(value) => {
                            const newTasks = [...tasks];
                            newTasks[index].collabration_id = filterUser(value);
                            setTasks(newTasks);
                            setCollaboratorsToUpdate([...collaboratorsToUpdate, newTasks[index]]);
                            updateUniqueCollaborators()
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Status"
                    name={`tasks[${index}].status`}
                    labelCol={{ span: 24 }}
                    rules={[{ required: true, message: "Status is required" }]}
                    initialValue={`${task.status?.replace('_', ' ') ?? "In pRogress"}`}
                >
                    <Select
                        options={[
                            { label: "In Progress", value: "in_progress" },
                            { label: "Completed", value: "completed" },
                        ]}
                        placeholder="Select Status"
                        value={task.status}
                        onChange={(value) => {
                            const newTasks = [...tasks];
                            newTasks[index].status = value;
                            setTasks(newTasks);
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Role"
                    name={`tasks[${index}].role`}
                    labelCol={{ span: 24 }}
                    rules={[{ required: true, message: "Role is required" }]}
                    initialValue={task.role}
                >
                    <Select
                        options={[
                            { label: "Editor", value: "editor" },
                            { label: "Viewer", value: "viewer" },
                        ]}
                        placeholder="Select Role"
                        value={task.role}
                        onChange={(value) => {
                            const newTasks = [...tasks];
                            newTasks[index].role = value;
                            setTasks(newTasks);
                            setCollaboratorsToUpdate([...collaboratorsToUpdate, newTasks[index]]);
                            updateUniqueCollaborators()
                        }}
                    />
                </Form.Item>
                <Button type="danger" onClick={() => removeTask(index, task.id)}>
                    Remove Task
                </Button>
                <hr />
            </>
        );


        return (
            <div key={index}>
                {commonFields}
                <Form.Item
                    label="Task Type"
                    name={`tasks[${index}].task_type`}
                    labelCol={{ span: 24 }}
                    rules={[{ required: true, message: "Task type is required" }]}
                    initialValue={task.task_type}
                >
                    <Select
                        options={[
                            { label: "Book", value: "books" },
                            { label: "Brainstorm", value: "brainstorms" },
                            { label: "Timeline", value: "timelines" },
                            { label: "Plotline", value: "plot_planners" },
                        ]}
                        placeholder="Select Task Type"
                        value={task.task_type}
                        onChange={(value) => {
                            const newTasks = [...tasks];
                            newTasks[index].task_type = value;
                            newTasks[index].type_id = null;
                            setTasks(newTasks);
                            setCollaboratorsToUpdate([...collaboratorsToUpdate, newTasks[index]]);
                            updateUniqueCollaborators()
                        }}
                    />
                </Form.Item>
                {renderAdditionalOptions(task.task_type, task.type_id, index)}
            </div>
        );
    }, [book_data, bstorm, filterUser, plotlines, removeTask, renderAdditionalOptions, tasks, timelineData, users, collaboratorsToUpdate]);

    return (
        <Drawer
            className="chapterModal"
            title="Update Card"
            open={open}
            onClose={close}
            mask={false}
            width={400}
            placement="right"
            bodyStyle={{ padding: "1.5rem" }}
        >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={handleSubmit}
                    autoComplete="off"
                    className="gx-signin-form gx-form-row0"
                    style={{ maxWidth: 1000 }}
                >
                    <Form.Item label="Card Image">
                        {showGallery && (
                            <UserGallery
                                setSelectedFile={setSelectedFile}
                                setShowGallery={setShowGallery}
                            />
                        )}
                        <div className="artwork mt-3">
                            <button
                                className="btn-artwork"
                                type="button"
                                onClick={() => setShowGallery(!showGallery)}
                            >
                                <img src={selectedFile?.id ? selectedFile.image_url : newBook} alt="Book Icon" />
                            </button>
                            {selectedFile && (
                                <div className="artworkContainer mt-2">
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedFile(null);
                                        }}
                                        type="button"
                                        className="btn-remove"
                                    >
                                        Remove
                                    </button>
                                </div>
                            )}
                        </div>
                        {customErrors.image_url && (
                            <div className="error">{customErrors.image_url}</div>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Title"
                        name="card_title"
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: "Card Title is required",
                            },
                        ]}
                    >
                        <Input value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="card_description"
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: "Card description is required",
                            },
                        ]}
                    >
                        <Input.TextArea value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                        label="Color"
                        name="color_id"
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: "Color is required",
                            },
                        ]}
                    >
                        <Select options={colors} placeholder="Select Color" value={colors.filter(c => c.id === color)} onChange={(value) => setColor(value)} />
                    </Form.Item>
                    {tasks.map((task, index) => renderTaskForm(task, index))}
                    <Button type="dashed" onClick={addTask} style={{ width: "100%", marginBottom: "1rem" }}>
                        Add Task
                    </Button>
                    <Form.Item
                        label="Attachments"
                        name="attachments"
                        labelCol={{ span: 24 }}
                        rules={[{ required: false }]}
                    >
                        <Upload
                            fileList={attachments}
                            onChange={({ fileList }) => {
                                setAttachments(fileList);
                                setAttachmentsToAdd(fileList.filter(file => !file.uid || !attachments.some(att => att.uid === file.uid)));
                            }}
                            beforeUpload={() => false}
                            onRemove={(file) => {
                                setAttachments((prev) => prev.filter((item) => item.uid !== file.uid));
                                if (!attachmentsToRemove.includes(file.uid)) {
                                    console.log(file)
                                    setAttachmentsToRemove((prev) => [...prev, file.uid]);
                                }
                            }}
                        >
                            <Button icon={<UploadOutlined />}>Upload Attachments</Button>
                        </Upload>
                    </Form.Item>
                    <Button htmlType="submit" type="primary" className="ml-2" disabled={loading}>
                        Update
                    </Button>
                </Form>
        </Drawer>
    );
};

export default OutlineSideModal;