import React, { useCallback } from "react";
import { Col, Image, Row, Modal, Input } from "antd";
import ChatContact from "./ChatContact";
import menuIcon from "../../../../../assets/images/menu-icon.png";
import searchIcon from "../../../../../assets/images/search-icon.png";
import ChatMessage from "./ChatMessage";
import attachIcon from "../../../../../assets/images/attach-icon.png";
import closeIcon from "../../../../../assets/icons/Close.svg";
import typingIcon from "../../../../../assets/images/typing-icon.gif";
import CommandMenu from "../CommandMenu";
import PreviewCard from "../cards/PreviewCard";
import { Paginate } from "../cards/paginate";
import useChatArea from "../../../../../hooks/chats/useChatArea";

const ChatArea = ({
	chatId,
	styles,
	contact,
	messages,
	searchedMessages,
	socketConnected,
	setSocketConnected,
	sendMessage,
	handleTyping,
	typing,
	messagesRef,
	gotoPreviousPage,
	attachedFiles,
	setAttachedFiles,
	fetchMessages,
	setMessages,
	OpenRightModal,
	userReacted,
	setUserReacted,
	scrollType,
	setScrollType,
	reactedMessage,
	handleDelete,
	handleEdit,
	editMessage,
	temp,
	setTemp,
	searchMessages,
	setSearchedMessages
}) => {

	const chatAreaProps = {
		gotoPreviousPage,
		editMessage,
		handleTyping,
		handleEdit,
		sendMessage,
		messages,
		chatId,
		temp,
		setTemp,
		setScrollType,
		setMessages,
		attachedFiles,
		setAttachedFiles,
		setSearchedMessages,
		setSocketConnected
	};

	const { chatControls, chatData, context } = useChatArea(chatAreaProps);
	const {
		messageEl,
		handleScroll,
		editMessages,
		setEditMessages,
		repliedMessage,
		setRepliedMessage,
		messageMenu,
		setMessageMenu,
		handleAttachments,
		showAttachments,
		handleCommand,
		handleMessageChange,
		handleSend,
		chat,
		setSearchText,
		hadleImagesModal,
		showImageModal,
		searchActive,
		toggleSearch
	} = chatControls;
	const {
		editedMessages,
		deletedId,
		repliedImage,
		setRepliedImage,
		chatFeature,
		setChatFeature,
		features,
		setFeatures,
		imagesOnModal,
		setImagesOnModal
	} = chatData;
	const { chatContext } = context;

	const renderChatMessages = useCallback(() => {
		return messages.map((message) => (
			<ChatMessage
				key={message.id}
				chat={message}
				setImagesOnModal={setImagesOnModal}
				showImageModal={showImageModal}
				hadleImagesModal={hadleImagesModal}
				editMessages={editMessages}
				editedMessages={editedMessages}
				deletedId={deletedId}
				setEditMessages={setEditMessages}
				reactedMessage={reactedMessage}
				userReacted={userReacted}
				handleDelete={handleDelete}
				setUserReacted={setUserReacted}
				setScrollType={setScrollType}
				styles={styles}
				fetchMessages={fetchMessages}
				setMessages={setMessages}
				setRepliedMessage={setRepliedMessage}
				messageMenu={messageMenu}
				setMessageMenu={setMessageMenu}
			/>
		));
	}, [
		messages,
		setImagesOnModal,
		showImageModal,
		hadleImagesModal,
		editMessages,
		editedMessages,
		deletedId,
		setEditMessages,
		reactedMessage,
		userReacted,
		handleDelete,
		setUserReacted,
		setScrollType,
		styles,
		fetchMessages,
		setMessages,
		setRepliedMessage,
		messageMenu,
		setMessageMenu
	]);

	const renderAttachedFiles = useCallback(() => {
		return attachedFiles.map((file, index) => (
			<div key={index} className={styles.attachedThumbnail}>
				<Image src={URL.createObjectURL(file)} preview={true} />
			</div>
		));
	}, [attachedFiles, styles]);

	const renderRepliedMessage = useCallback(() => {
		return messages
			.filter((message) => message.id === repliedMessage.parentId)
			.map((message) => (
				<div className={styles.chatReply} key={message.id}>
					<div className={styles.chatReplyBox}>
						<span
							onClick={() => {
								setRepliedMessage({});
								setRepliedImage([]);
							}}
							className={styles.leftBarStyle}
						>
							<Image src={closeIcon} preview={false} />
						</span>
						<h6 className={styles.boxHeadingText}>
							{message.from_name === contact.name ? message.from_name : "You"}{" "}
							<span className={styles.crossIconMark}></span>
						</h6>
						<div style={{ marginBottom: "10px" }}>
							{repliedImage && repliedImage.length > 0 && (
								<Image
									src={repliedImage[0].original_url}
									width={60}
									height={60}
									preview={false}
									style={{ marginTop: "10px", borderRadius: "5px" }}
								/>
							)}
						</div>
						<p className={styles.chatReplyText}>{message.message}</p>
					</div>
				</div>
			));
	}, [messages, repliedMessage, contact.name, setRepliedMessage, setRepliedImage, repliedImage, styles]);

	return (
		<>
			<div className={styles.chatArea}>
				<div className={styles.chatHeader}>
					<div className={styles.chatUserInfo}>
						<ChatContact
							avatar={contact.avatar}
							online={contact.online}
							name={contact.name}
							styles={styles}
						/>
					</div>
					<div className={styles.chatActions}>
						{searchActive ? (
							<div style={{ display: "flex", alignItems: "center" }}>
								<Input
									placeholder="Search"
									onChange={searchMessages}
									style={{ width: "100%" }}
								/>
								<div style={{ cursor: "pointer", width: "20px", height: "20px" }}>
									<Image
										src={closeIcon}
										width={20}
										height={20}
										onClick={toggleSearch}
										preview={false}
									/>
								</div>
							</div>
						) : (
							<Image src={searchIcon} onClick={toggleSearch} preview={false} />
						)}
						<Image
							src={menuIcon}
							preview={false}
							style={{ cursor: "pointer" }}
							onClick={OpenRightModal}
						/>
					</div>
				</div>
				<div ref={messageEl} className={styles.chatBody} id="chatMessagesList">
					{messages.length > 0 && (
						<Paginate setScrollType={setScrollType} handleScroll={handleScroll} />
					)}
					{renderChatMessages()}
					<div ref={messagesRef} />
				</div>
				{Object.keys(chatContext.selectedFeature).length > 0 && (
					<Row>
						<Col className={styles.featureContainer}>
							{Object.keys(chatContext.selectedFeature).map((key) => (
								<PreviewCard
									key={key}
									dataIndex={key}
									item={chatContext.selectedFeature[key]}
								/>
							))}
						</Col>
					</Row>
				)}
				{attachedFiles.length > 0 && (
					<Row>
						<Col className={styles.featureContainer}>
							<div className={styles.attachedFiles}>{renderAttachedFiles()}</div>
						</Col>
					</Row>
				)}
				{typing && (
					<Row>
						<Col>
							<Image
								src={typingIcon}
								preview={false}
								style={{ maxWidth: "50px", marginLeft: "15px" }}
							/>
						</Col>
					</Row>
				)}
				<div className={styles.chatFooter}>
					<Row>
						<Col span={21}>
							{repliedMessage?.parentId && renderRepliedMessage()}
							<div className={styles.sendMessage}>
								<input
									type="file"
									onChange={handleAttachments}
									id="attachments"
									style={{ display: "none" }}
									multiple
								/>
								<button
									type="button"
									onClick={showAttachments}
									className={styles.btnAnchor}
								>
									<Image src={attachIcon} preview={false} />
								</button>
								<CommandMenu handleCommand={handleCommand} styles={styles} />
								<input
									className={styles.chatInput}
									type="text"
									placeholder="Type a message..."
									onChange={handleMessageChange}
									onKeyPress={(e) => e.key === "Enter" && handleSend()}
									value={chat}
									disabled={!socketConnected}
								/>
							</div>
						</Col>
						<Col span={3} className={styles.columnSendMsg}>
							<button
								className={styles.chatSendBtn}
								onClick={handleSend}
								disabled={!socketConnected}
							>
								{editMessages ? "Edit" : "Send"}
							</button>
						</Col>
					</Row>
				</div>
			</div>
			{chatFeature && (
				<Modal
					open={true}
					title={`SEARCH ${chatFeature.toUpperCase()}`}
					onCancel={() => {
						setChatFeature("");
						setFeatures([]);
					}}
					centered
				>
					<Input
						placeholder={`Search ${chatFeature}...`}
						onChange={(e) => setSearchText(e.target.value)}
					/>
					{features.map((item) => item)}
				</Modal>
			)}
			{showImageModal.isShow && (
				<Modal
					open={true}
					onCancel={() => {
						hadleImagesModal(null);
						setImagesOnModal([]);
					}}
					footer={null}
					centered
				>
					<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
						{imagesOnModal.map((item) => (
							<Image
								style={{ borderRadius: "5px" }}
								preview={true}
								src={item.original_url}
								key={item.original_url}
							/>
						))}
					</div>
				</Modal>
			)}
		</>
	);
};

export default ChatArea;