import React, { useRef, useMemo, useCallback } from "react";
import styles from "./Chat.module.css";
import { Col, Row } from "antd";
import ContactArea from "./components/contacts/ContactArea";
import ChatArea from "./components/chats/ChatArea";
import useIndex from "../../../hooks/chats/useIndex";
import { ChatLeftModal, ChatRightModal } from "./components/contacts/Modals";
import PackageSubscriptionHook from "../../../components/PackageSubscriptionHook";
import styled from "styled-components";

const Chat = () => {
  const chatHook = useIndex();
  const subscriptionRef = useRef();

  const {
    isLeftModal,
    isRightModal,
    handleIsLeftModal,
    handleIsRightModal,
  } = chatHook.modals;

  const {
    user,
    onlineUsers,
    socketConnected,
    setSocketConnected
  } = chatHook.userState;

  const {
    messages,
    setMessages,
    searchedMessages,
    fetchMessages,
    sendMessage,
    messagesRef,
    attachedFiles,
    setAttachedFiles,
    gotoPreviousPage,
    handleDelete,
    handleEdit,
    editMessage, 
    temp,
    setTemp,
  } = chatHook.messageState;

  const {
    userReacted,
    setUserReacted,
    reactedMessage,
    scrollType,
    setScrollType,
  } = chatHook.reactionState;

  const {
    handleTyping,
    isTyping,
    header,
    deleteContact,
    searchMessages,
    setSearchedMessages
  } = chatHook.utilities;

  const chatAreaProps = useMemo(() => ({
    chatId: header.selectedContact?.contact_id,
    styles,
    contact: header.selectedContact,
    messages: searchedMessages.length > 0 ? searchedMessages : messages,
    socketConnected,
    setSocketConnected,
    sendMessage,
    handleTyping,
    typing: isTyping,
    messagesRef,
    gotoPreviousPage,
    attachedFiles,
    setAttachedFiles,
    fetchMessages,
    setMessages,
    OpenRightModal: handleIsRightModal,
    userReacted,
    setUserReacted,
    scrollType,
    setScrollType,
    reactedMessage,
    handleDelete,
    handleEdit,
    temp,
    setTemp,
    editMessage,
    searchMessages,
    setSearchedMessages
  }), [
    header.selectedContact,
    styles,
    searchedMessages,
    messages,
    socketConnected,
    setSocketConnected,
    sendMessage,
    handleTyping,
    isTyping,
    messagesRef,
    gotoPreviousPage,
    attachedFiles,
    setAttachedFiles,
    fetchMessages,
    setMessages,
    handleIsRightModal,
    userReacted,
    setUserReacted,
    scrollType,
    setScrollType,
    reactedMessage,
    handleDelete,
    handleEdit,
    temp,
    setTemp,
    editMessage,
    searchMessages,
    setSearchedMessages
  ]);

  return (
    <div className={styles.chatContainer}>
      <PackageSubscriptionHook
        ref={subscriptionRef}
        checkPackageSubscription={chatHook.utilities.checkSubscription}
        title="Chat Feature"
        feature="chat-feature"
        packageName="premium"
      />
      <Row className={styles.chatContainerRow}>
        <div
          className={styles.chatModalWrapper}
          style={{
            opacity: isLeftModal || isRightModal ? "1" : "0",
            pointerEvents: isLeftModal || isRightModal ? "all" : "none",
          }}
        >
          <ChatRightModal
            {...chatHook.modals}
            header={header}
            deleteContact={deleteContact}
            user={user}
          />
        </div>
        <Col span={8} className={styles.contacts}>
          <ContactArea handleIsLeftModal={handleIsLeftModal} styles={styles} onlineUsers={onlineUsers}  />
        </Col>
        {header.selectedContact && (
          <Col span={16} className={styles.chat}>
            <ChatArea {...chatAreaProps} />
          </Col>
        )}
      </Row>
    </div>
  );
};

const SubscriptionCheck = () => {
  const SubscribeButton = styled.a`
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #1890ff;
    border-radius: 5px;
    text-decoration: none;
  `;

  return (
    <div style={{
      width: "100%", 
      height: "100vh", 
      display: "flex", 
      flexDirection: "column", 
      justifyContent: "center", 
      alignItems: "center", 
      gap: "10px"
    }}>
      <p className="subscription-message">
        You don't have access to this feature. Subscribe to unlock all features and enjoy a seamless chatting experience. 🚫 
      </p>
      <SubscribeButton href="/subscribe">
        Subscribe Now
      </SubscribeButton>
    </div>
  );
};

export default Chat;
