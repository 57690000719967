import React, { useState } from "react";
import { Avatar, Popover, Tag } from "antd";
import WebIcon from "../assets/icons/web-icon.svg";
import "./cards/index.scss";
import { getTimeDuration } from "../services/utilServices";
import { Link } from "react-router-dom";
import newBook from "../assets/images/newbook.png";
import { MenuButton } from "./button/MenuButton";
import { CommentOutlined, ShareAltOutlined } from "@ant-design/icons";
import { CommentsDrawer } from "./comments";

const ListCard = ({
	icon,
	listType,
	name,
	description,
	updated,
	content,
	id,
	color_code,
	foreground_color,
	pathname,
	book_id,
	color_id,
	audio_file,
	brainstorm_name,
	image_id,
	open,
	openIndex,
	popupVisible,
	type,
	style,
	handleOpenChange,
	series_name,
	isShared = false,
	user = {},
	comments = [],
	newComment,
	setNewComment,
	handleAddComment,
	mod = 'auther',
	hasEditor = true,
	image_url,
	showCommentDrawer
}) => {

	const data = {
		brainstorm_name,
		description,
		id,
		color_code,
		book_id,
		color_id,
		audio_file,
		image_id,
	};

	const renderSharedInfo = () => (
		<>
			<ShareAltOutlined className="mr-1" style={{ color: foreground_color }} />
			<span className="thin-text-13 mr-2" style={{ color: foreground_color }}>
				Shared by:
			</span>
			<Avatar src={image_url ?? WebIcon} className="mr-1" alt="Web Icon" style={{ width: '24px', height: '24px' }} />
			<span className="bold-text-13" style={{ color: foreground_color }}>
				{user.name}
			</span>
		</>
	);

	const renderBookInfo = () => (
		<Link to={`/book-detial/${id}`}  style={pathname === "" ? { pointerEvents: "none", cursor: "default" } : {}}>
			<div className="display-flex mt-2">
				<img
					src={icon?.length > 0 ? icon : newBook}
					className="mr-3 self-start"
					alt="Book or Series Icon"
					width="70"
					height="70"
				/>
				<div>
					<h2 className="sub-title-16 font-w-800 mb-0-3" style={{ color: foreground_color }}>
						{name}
					</h2>
					<h4 className="thin-text-13" style={{ lineHeight: 1.5, color: foreground_color }}>
						{description}
					</h4>
					<p>{audio_file}</p>
					<div className="display-flex mt-2">
						<p className="thin-text-13 mr-2" style={{ color: foreground_color }}>
							Updated: {getTimeDuration(updated)}
						</p>
					</div>
				</div>
			</div>
		</Link>
	);

	const renderSeriesInfo = () => (
		<Link to={{ pathname: `/${pathname}/${id}`, state: { data: data } }} style={pathname === "" ? { pointerEvents: "none", cursor: "default" } : {}}>
			{renderBookInfo()}
		</Link>
	);

	const renderTypeSpecificLinks = () => (
		<div className="display-flex mt-2">
			{type !== "plotline" && (
				<p className="thin-text-13 underline mr-2" style={{ color: foreground_color }}>
					<Link to={`/brain-storm/list/${id}`} style={{ color: foreground_color }}>
						Plotline
					</Link>
				</p>
			)}
			{type !== "brainstorm" && (
				<p className="thin-text-13 underline mr-2" style={{ color: foreground_color }}>
					<Link to={`/brain-storm/list/${id}`} style={{ color: foreground_color }}>
						Brainstorm
					</Link>
				</p>
			)}
			{type !== "outline" && (
				<p className="thin-text-13 underline mr-2">
					<Link to={`/out-line/list/${id}`} style={{ color: foreground_color }}>
						Outline
					</Link>
				</p>
			)}
			{type !== "timeline" && (
				<p className="thin-text-13 underline mr-2" style={{ color: foreground_color }}>
					<Link to={`/time-line/list/${id}`} style={{ color: foreground_color }}>
						Timeline
					</Link>
				</p>
			)}
		</div>
	);

	const renderTypeSpecificInfo = () => {
		if (type === "series") {
			return renderSeriesInfo();
		} else if (type === "book") {
			return (
				<>
					{renderBookInfo()}
					{renderTypeSpecificLinks()}
				</>
			);
		} else if (["plotline", "brainstorm", "outline", "timeline"].includes(type)) {
			return (
				<div className="display-flex mt-2">
					<img
						src={icon?.length > 0 ? icon : newBook}
						className="mr-3 self-start"
						alt="Book or Series Icon"
						width="70"
						height="70"
					/>
					<div>
						<h2 className="sub-title-16 font-w-800 mb-0-3" style={{ color: foreground_color }}>
							{name} : {type.charAt(0).toUpperCase() + type.slice(1)} #1
						</h2>
						<h4 className="thin-text-13 mt-2" style={{ lineHeight: 1.5, color: foreground_color }}>
							{series_name}
						</h4>
						<div className="display-flex mt-2">
							<p className="thin-text-13 mr-2" style={{ color: foreground_color }}>
								Updated: {getTimeDuration(updated)}
							</p>
							{renderTypeSpecificLinks()}
						</div>
					</div>
				</div>
			);
		}
	};

	return (
		<div className="book-card-container" id={id} style={style}>
			<div className="display-flex space-between align-center">
				<div className="display-flex align-center">
					{isShared && renderSharedInfo()}
				</div>
				<div className="display-flex align-center">
					<CommentOutlined
						style={{ color: foreground_color }}
						className="mr-2"
						onClick={(e) => {
							e.stopPropagation();
							showCommentDrawer();
						}}
					/>
					<Tag className="bold-white-text-11" style={{cursor: "pointer"}} onClick={(e) => {
						e.stopPropagation();
						showCommentDrawer();
					}}>{comments.length ?? 0} Comment</Tag>
					{listType !== 'activity' && (
						<Popover
							placement="right"
							content={content}
							open={id === openIndex && popupVisible}
							title="Actions"
							trigger="click"
							onOpenChange={handleOpenChange}
						>
							<MenuButton onClick={open} id={id} color={foreground_color} />
						</Popover>
					)}
				</div>
			</div>
			{renderTypeSpecificInfo()}
		</div>
	);
};

export default ListCard;

