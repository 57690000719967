import styles from "../../Chat.module.css";
import closeIcon from "../../../../../assets/images/close-icon.png";
import fml from "../../../../../assets/images/fml.png";
import lcm1 from "../../../../../assets/images/lcm1.png";
import lcm2 from "../../../../../assets/images/lcm2.png";
import lcm3 from "../../../../../assets/images/lcm3.png";
import lcm4 from "../../../../../assets/images/lcm4.png";
import rct1 from "../../../../../assets/images/rct1.png";
import rct2 from "../../../../../assets/images/rct2.png";
import rct3 from "../../../../../assets/images/rct3.png";
import rcb1 from "../../../../../assets/images/rcb1.png";
import rcb2 from "../../../../../assets/images/rcb2.png";
import rcb3 from "../../../../../assets/images/rcb3.png";
import rcb4 from "../../../../../assets/images/rcb4.png";
import { Image, } from "antd";
import { useState } from "react";


const ChatLeftModal = ({ isLeftModal, setIsLeftModal, twoStepVerification, user, handleTwoStepVerificationToggle, notification, handleNotificationToggle, handleLogout }) => {

    return (
        <div
            className={styles.chatModalLeft}
            style={{ left: isLeftModal ? "0" : "-100%" }}
        >
            <div className={styles.leftModalCloseIcon}>
                <Image
                    src={closeIcon}
                    preview={false}
                    onClick={() => {
                        setIsLeftModal(false);
                    }}
                />
            </div>
            <div className={styles.leftModalHead}>
                <div className={styles.leftModalHeadAvatar}>
                    <Image src={user[0]?.user.profile_image} preview={false} />
                    <span></span>
                </div>
                <h5>{user[0]?.user.name}</h5>
                <h6>Admin</h6>
            </div>
            <div className={styles.leftModalAbout}>
                {/* <h6>About</h6> */}
                {/* <div>
            <p>
              While most people enjoy casino gambling, sports betting,
              lottery and bingo playing for the fun and excitement it
              provides, others may experience gambling
            </p>
          </div> */}
            </div>
            <div className={styles.leftModalStatus}>
                <h6>Status</h6>
                <div>
                    <label>
                        <input type="radio" name="status" value="active" />
                        <span></span>Active
                    </label>
                    <label>
                        <input type="radio" name="status" value="disturb" />
                        <span></span>Do Not Disturb
                    </label>
                    <label>
                        <input type="radio" name="status" value="away" />
                        <span></span>Away
                    </label>
                    <label>
                        <input type="radio" name="status" value="offline" />
                        <span></span>Offline
                    </label>
                </div>
            </div>
            <div className={styles.leftModalSettings}>
                <h6>Settings</h6>
                <div className={styles.leftModalSettingsDiv}>
                    <div className={styles.leftModalSettingsDivLeft}>
                        <Image src={lcm1} preview={false} /> Two-step Verification
                    </div>
                    <div className={styles.switchContainer}>
                        <label className={styles.switch}>
                            <input
                                type="checkbox"
                                checked={twoStepVerification}
                                onChange={handleTwoStepVerificationToggle}
                            />
                            <span className={`${styles.slider} ${styles.round}`}></span>
                        </label>
                    </div>
                </div>
                <div className={styles.leftModalSettingsDiv}>
                    <div className={styles.leftModalSettingsDivLeft}>
                        <Image src={lcm2} preview={false} /> Notification
                    </div>
                    <div className={styles.switchContainer}>
                        <label className={styles.switch}>
                            <input
                                type="checkbox"
                                checked={notification}
                                onChange={handleNotificationToggle}
                            />
                            <span className={`${styles.slider} ${styles.round}`}></span>
                        </label>
                    </div>
                </div>
                <div className={styles.leftModalSettingsDiv}>
                    <div className={styles.leftModalSettingsDivLeft}>
                        <a href="javascript:void(0)"><Image src={lcm3} preview={false} /> Invite Friends</a>
                    </div>
                </div>
                <div className={styles.leftModalSettingsDiv}>
                    <div className={styles.leftModalSettingsDivLeft}>
                        <a href="javascript:void(0)"><Image src={lcm4} preview={false} /> Delete Account</a>
                    </div>
                </div>
            </div>
            <div className={styles.leftModalBtn}>
                <a onClick={handleLogout}>Logout</a>
            </div>
        </div>
    )
};


const ChatRightModal = ({ isRightModal, setIsRightModal, header, deleteContact, user }) => {

    const [deleteing, setDeleteing] = useState(false);
    return (
        <div
            className={styles.chatModalRight}
            style={{ right: isRightModal ? "0" : "-100%" }}
        >
            <div className={styles.leftModalCloseIcon}>
                <Image
                    src={closeIcon}
                    preview={false}
                    onClick={() => {
                        setIsRightModal(false);
                    }}
                />
            </div>
            <div className={styles.leftModalHead}>
                <div className={styles.leftModalHeadAvatar}>
                    <Image src={header?.selectedContact?.avatar ?? fml} preview={false} />
                    <span></span>
                </div>
                <h5>{header?.selectedContact?.name ?? ""}</h5>
                {/* <h6>Writer 👩🏻‍</h6> */}
            </div>
            {/* <div className={styles.leftModalAbout}>
              <h6>About</h6>
              <p>
                While most people enjoy casino gambling, sports betting,
                lottery and bingo playing for the fun and excitement it
                provides, others may experience gambling
              </p>
            </div> */}
            <div className={`${styles.leftModalSettings} ${styles.rightModalSettings}`}>
                <h6>Settings</h6>
                <div className={styles.leftModalSettingsDiv}>
                    <div className={styles.leftModalSettingsDivLeft}>
                        <a href={`mailto:${user[0]?.user.email}`} target="_blank" rel="noopener noreferrer"><Image src={rct1} preview={false} /> {user[0]?.user.email}</a>
                    </div>
                </div>
                {/* <div className={styles.leftModalSettingsDiv}>
                    <div className={styles.leftModalSettingsDivLeft}>
                        <a href="tel:+1(123) 456 - 7890"><Image src={rct2} preview={false} /> +1(123) 456 - 7890</a>
                    </div>
                </div> */}
                {/* <div className={styles.leftModalSettingsDiv}>
                    <div className={styles.leftModalSettingsDivLeft}>
                        <Image src={rct3} preview={false} /> Mon - Fri 10AM - 8PM
                    </div>
                </div> */}
            </div>
            <div className={`${styles.leftModalSettings} ${styles.rightModalSettings}`}>
                <h6>Options</h6>
                {/* <div className={styles.leftModalSettingsDiv}>
                    <div className={styles.leftModalSettingsDivLeft}>
                        <a href="javascript:void(0)"><Image src={rcb1} preview={false} /> Add Tag</a>
                    </div>
                </div>
                <div className={styles.leftModalSettingsDiv}>
                    <div className={styles.leftModalSettingsDivLeft}>
                        <a href="javascript:void(0)"><Image src={rcb2} preview={false} /> Important Contact</a>
                    </div>
                </div>
                <div className={styles.leftModalSettingsDiv}>
                    <div className={styles.leftModalSettingsDivLeft}>
                        <a href="javascript:void(0)"><Image src={rcb3} preview={false} /> Shared Media</a>
                    </div>
                </div> */}
                <div className={styles.leftModalSettingsDiv}>
                    <div className={styles.leftModalSettingsDivLeft}>
                        <a onClick={() => {
                            if (!deleteing) {
                                setDeleteing(true);
                                deleteContact().then(() => {
                                    setIsRightModal(false);
                                    setDeleteing(false);
                                }).catch(() => {
                                    setDeleteing(false);
                                });
                            }
                        }}><Image src={rcb4} preview={false} /> Delete Contact</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ChatLeftModal, ChatRightModal };