import React, { useContext } from "react";
import styles from "./Card.module.css";
import { ChatDataContext } from "../../../../../context/ChatContext";

const PreviewCard = ({ dataIndex, item }) => {
	const chatContext = useContext(ChatDataContext);

	let card = {};
	switch (dataIndex) {
		case "timeline":
			card = {
				image_url: item.image_url,
				title: item.name,
			};
			break;
		case "outline":
			card = {
				image_url: item.image_url,
				title: item.outline_name,
			};
			break;
		case "brainstorm":
			card = {
				image_url: item.image_url,
				title: item.brainstorm_name,
			};
			break;
		case "plot_planner":
			card = {
				image_url: item.image_url,
				title: item.plot_planner_title,
			};
			break;
		case "book":
			card = {
				image_url: item.image_url,
				title: item.book_name,
			};
			break;
		case "series":
			card = {
				image_url: item.image_url,
				title: item.series_name,
			};
			break;
	}
	return Object.keys(card).length ? (
		<div className={styles.btnCard}>
			<img src={card.image_url} alt="Card" />
			<div>{card.title}</div>
			<button
				type="button"
				className={styles.btnRemove}
				onClick={() => {
					chatContext.setSelectedFeature((prevData) => {
						delete prevData[dataIndex];
						return { ...prevData };
					});
				}}
			>
				X
			</button>
		</div>
	) : (
		<></>
	);
};

export default PreviewCard;
