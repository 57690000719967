import React, { useEffect, useState } from "react";
import { hmsToSecondsOnly } from "../../helpers";
import ScaleBar from "./ScaleBar";
import {
	BackwardOutlined,
	CaretRightFilled,
	ForwardOutlined,
	PauseOutlined,
} from "@ant-design/icons";

const RoundPlayer = ({ rounds, roundIndex, selectRound, deleteRound, hasEditor, mod }) => {
	const [audio, setAudio] = useState(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [showProgress, setShowProgress] = useState(false);
	const [barPosition, setBarPosition] = useState(0);
	const recordingBars = [
		...Array(hmsToSecondsOnly(rounds[roundIndex].playtime)),
	];

	useEffect(() => {
		resetPlayer();
		setAudio(new Audio(rounds[roundIndex].audio_file_url));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roundIndex]);

	useEffect(() => {
		if (!audio) return;

		audio.addEventListener("timeupdate", () => {
			setBarPosition((audio.currentTime * 100) / audio.duration);
		});
		audio.addEventListener("ended", () => {
			resetPlayer();
		});
	}, [audio]);

	const resetPlayer = () => {
		setIsPlaying(false);
		setShowProgress(false);
		setBarPosition(0);
	};

	const togglePlay = () => {
		const prevValue = isPlaying;
		setIsPlaying(!prevValue);
		if (!prevValue) {
			audio.play();
			setShowProgress(true);
		} else {
			audio.pause();
			setShowProgress(false);
		}
	};

	return (
		<div className="round-player">
			<div className="player">
				<div className="backward">
					<button
						type="button"
						className="btn-track backward"
						onClick={() => {
							selectRound(roundIndex - 1);
						}}
						disabled={roundIndex === 0}
					>
						<BackwardOutlined
							style={{
								color: roundIndex === 0 ? "#cdcdcd" : "#ffffff",
							}}
						/>
					</button>
				</div>
				<ScaleBar
					recordingBars={recordingBars}
					showProgress={showProgress}
					barPosition={barPosition}
				/>
				<div className="forward">
					<button
						type="button"
						className="btn-track forward"
						onClick={() => {
							selectRound(roundIndex + 1);
						}}
						disabled={roundIndex === rounds.length - 1}
					>
						<ForwardOutlined
							style={{
								color: roundIndex === rounds.length - 1 ? "#cdcdcd" : "#ffffff",
							}}
						/>
					</button>
				</div>
			</div>
			<div className="track-info mt-3 mb-2">
				<div className="info white">
					Round {roundIndex + 1}/{rounds.length}
				</div>
				<div className="play">
					<button type="button" className="btn-play" onClick={togglePlay}>
						{isPlaying ? (
							<PauseOutlined style={{ color: "#2DB6F5" }} />
						) : (
							<CaretRightFilled style={{ color: "#2DB6F5" }} />
						)}
					</button>
				</div>
				{hasEditor && mod !== 'guest' &&  <div className="delete">
					<button
						type="button"
						className="btn-delete"
						onClick={() => deleteRound(rounds[roundIndex].id)}
					>
						Delete
					</button>
				</div>}
			</div>
		</div>
	);
};

export default RoundPlayer;
