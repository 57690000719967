import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { apiServices } from "../../services/apiServices/Api";
import { message } from "antd";

//post calendar list
export const postCalenderList = createAsyncThunk(
	"calendar/postCalenderList",
	({date_from, date_to}) => {

		const data = {
			dateFrom: date_from,
			dateTo: date_to,
		};
		const calendarData = apiServices
			.post(`/calendars-list`, data)
			.then((response) => {
				return response;
			});

		// console.log("posted calendar data", calendarData);

		return calendarData;
	}
);

//post calendar event
export const postCalendarEvent = createAsyncThunk(
	"calendar/postCalendarEvent",
	(cal) => {
		// console.log("cal", cal);
		const calendarData = apiServices
			.post(`/calendars`, cal.data)
			.then((response) => response)
			.catch((err) => console.log(err));
		return calendarData;
	}
);

//get calendar event
export const getCalendarEvent = createAsyncThunk(
	"calendar/getCalendarEvent",
	(id) => {
		const calendarData = apiServices
			.get(`/calendars/${id}`)
			.then((response) => {
				// console.log(response);
				return response;
			})
			.catch((err) => err);
		return calendarData;
	}
);
//delete a calendar event

export const deleteCalendarEvent = createAsyncThunk(
	"calendar/deleteCalendarEvent",
	(id) => {
		const calData = apiServices
			.delete(`/calendars/${id}`)
			.then((response) => response.data)
			.catch((err) => console.log(err));

		return calData;
	}
);

//update calendar event
export const updateCalendarEvent = createAsyncThunk(
	"calendar/updateCalendarEvent",
	({ id, data }) => {
		const calData = apiServices
			.update(`/calendars/${id}`, data)
			.then((response) => response)
			.catch((err) => err);
		// console.log("calendar events", calData);

		return calData;
	}
);
const initialState = {
	calendar_list: [],
	loading: false,
	error: false,
	event: [],
};

const CalendarSlice = createSlice({
	name: "calendar",
	initialState,
	reducers: {
		calenderSeliceReset : (state) => {
			Object.assign(state, initialState);
		},
	},
	extraReducers: (builder) => {
		//post calendar list
		builder.addCase(postCalenderList.pending, (state) => {
			state.loading = true;
		})
		.addCase(postCalenderList.fulfilled, (state, action) => {
			state.loading = false;
			state.calendar_list = [...action.payload];
		})

		.addCase(postCalenderList.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})
		//post calendar event

		.addCase(postCalendarEvent.pending, (state) => {
			state.loading = true;
		})
		.addCase(postCalendarEvent.fulfilled, (state, action) => {
			state.loading = false;
			state.event = [action.payload];
		})
		.addCase(postCalendarEvent.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})

		//get calendar event
		.addCase(getCalendarEvent.pending, (state) => {
			state.loading = true;
		})
		.addCase(getCalendarEvent.fulfilled, (state, action) => {
			state.loading = false;
			state.event = [action.payload];
		})
		.addCase(getCalendarEvent.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})

		//delete calendar event
		.addCase(deleteCalendarEvent.pending, (state) => {
			state.loading = true;
		})
		.addCase(deleteCalendarEvent.fulfilled, (state, action) => {
			state.loading = false;

			state.event = action.payload;
		})
		.addCase(deleteCalendarEvent.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})

		//update calendar event
		.addCase(updateCalendarEvent.pending, (state) => {
			state.loading = true;
		})
		.addCase(updateCalendarEvent.fulfilled, (state, action) => {
			state.loading = false;
			state.event = [action.payload];
			message.success("Event Updated Successfully");
			// console.log("updated calendar Event", action.payload);
		})
		.addCase(updateCalendarEvent.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})
	},
});

const CalendarReducer = CalendarSlice.reducer;
export const { setEdit, calenderSeliceReset } = CalendarSlice.actions;

export default CalendarReducer;
