import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiServices } from "../../services/apiServices/Api";
import { message } from "antd";
import { axiosInstance } from "../../services/apiServices/config";

//get chatss
export const getContacts = createAsyncThunk("chat/getContacts", () => {
	const chats = apiServices
		.get(`/contacts`)
		.then((response) => {
			return response;
		})
		.catch((err) => console.log("Error while fetching contacts"));
	return chats;
});


//add chat
export const chatReaction = createAsyncThunk(
	"chat/chatReaction",
	(chatmsgs) => {
		const chat = apiServices.post("/chats", chatmsgs).then((response) => {
			// console.log("response", response);
			return response;
		});
		return chat;
	}
);

export const messageSeen = async(chatId) => {
	const response = await axiosInstance.post(`/chats/mark-read/${chatId}`);
	return response;
   }

//delete chatMsg
export const deleteMsg = createAsyncThunk("chat/deleteChat", (id) => {
	const chat = apiServices.delete(`/chats/${id}`).then((response) => {
		// console.log("delete msg response", response);
		return response;
	});
	return chat;
});

//delete chatMsg
export const reactionEmoji = createAsyncThunk(
	"chat/reactionEmoji",
	(reaction) => {
		const chat = apiServices
			.post(`/chats/reaction`, reaction.data)
			.then((response) => {
				// console.log("chat reaction", response);
				return response;
			});
		return chat;
	}
);

// New addition based on file_context_0
export const getFeature = createAsyncThunk("chat/getFeature", async () => {
  try {
    const feature = await apiServices.get("/get-features");
    return feature.data;
  } catch (err) {
    console.error("Error fetching features", err);
    throw err;
  }
});

const initialState = {
	chats: [],
	loading: false,
	error: false,
	chatMsgs: [],
	chat_reaction: "",
	features: [], 
};

const ChatsSlice = createSlice({
	name: "chat",
	initialState,

	reducers: {
		chatSeliceReset : (state) => {
			Object.assign(state, initialState);
		},
		setMessages: (state, action) => {
			state.chatMsgs = action.payload;
		},
		setMessageFetching: (state, action) => {
			state.loading = action.payload
		}
	},
	extraReducers: (builder) => {

		builder.addCase(getContacts.pending, (state) => {
			state.loading = true;
		})
		.addCase(getContacts.fulfilled, (state, action) => {
			state.loading = false;
			state.chats = [action.payload];
		})
		.addCase(getContacts.rejected, (state, action) => {
			state.loading = true;
			state.error = [action.payload];
			state.chats = [];
		})
		.addCase(chatReaction.pending, (state) => {
			state.loading = true;
		})
		.addCase(chatReaction.fulfilled, (state, action) => {
			state.loading = false;
			state.chatMsgs = [action.payload];
		})
		.addCase(chatReaction.rejected, (state, action) => {
			state.loading = true;
			state.chatMsgs = [];
		})
		.addCase(deleteMsg.pending, (state) => {
			state.loading = true;
		})
		.addCase(deleteMsg.fulfilled, (state, action) => {
			state.loading = false;
			state.chatMsgs = action.payload;
		})
		.addCase(deleteMsg.rejected, (state, action) => {
			state.loading = true;
			message.error("not deleted");
		})

		//chat reaction
		.addCase(reactionEmoji.pending, (state) => {
			state.loading = true;
		})
		.addCase(reactionEmoji.fulfilled, (state, action) => {
			state.loading = false;
			state.chat_reaction = action.payload;
		})
		.addCase(reactionEmoji.rejected, (state, action) => {
			state.loading = true;
		})
		// Handling getFeature
		.addCase(getFeature.pending, (state) => {
			state.loading = true;
		})
		.addCase(getFeature.fulfilled, (state, action) => {
			state.loading = false;
			state.features = action.payload;
		})
		.addCase(getFeature.rejected, (state, action) => {
			state.loading = true;
			state.error = action.error.message;
		})
	},
});

const ChatsReducer = ChatsSlice.reducer;
export const { chatSeliceReset, setMessages, setMessageFetching } = ChatsSlice.actions;
export default ChatsReducer;
