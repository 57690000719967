import React, { useEffect, useState } from "react";
import { Popconfirm, Popover, Tag, Avatar, Tooltip, Modal, Button, Input, Form, Drawer } from "antd";
import WebIcon from "../../assets/icons/web-icon.svg";
import "../cards/index.scss";
import { getTimeDuration } from "../../services/utilServices";
import newBook from "../../assets/images/newbook.png";
import { MenuButton } from "../button/MenuButton";
import { CommentOutlined, ShareAltOutlined, PlusSquareOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CommentsDrawer } from "../comments";
import { axiosInstance } from "../../services/apiServices/config";

const BrainstormCard = ({
	brainstorm,
	style,
	editBrainstorm,
	deleteBrainstorm,
	showAddNewDrawer,
	isShared,
	shareId,
	commentsCount,
	showCommentDrawer,
	setShowAction,
	showAction
}) => {
	const { colors } = useSelector((state) => state.colors);

	useEffect(() => {
		if (showAddNewDrawer) setShowAction(false);
	}, [showAddNewDrawer]);

	return (
		<div className="book-card-container" id={brainstorm.id} style={style}>
			<div className="display-flex space-between">
				<div>
					<ShareAltOutlined
						className="mr-1 align-center"
						style={{ color: brainstorm.foreground_color }}
					/>
					<span
						className="thin-text-13 mr-2"
						style={{ color: brainstorm.foreground_color }}
					>
						Shared by:
					</span>
					<img src={WebIcon} className="mr-1" alt="Web Icon" />
					<span
						className="bold-text-13"
						style={{ color: brainstorm.foreground_color }}
					>
						Shana Tenerths
					</span>
				</div>
				<div>
					<CommentOutlined
						style={{ color: brainstorm.foreground_color }}
						className="mr-2"
						onClick={showCommentDrawer}
					/>
					<Tag className="bold-white-text-11" style={{cursor: "pointer"}} onClick={showCommentDrawer}>{commentsCount} Comment</Tag>
					<Popover
						popupVisible={false}
						placement="right"
						content={
							<>
								<div className="edit">
									<button
										onClick={() => {
											editBrainstorm(brainstorm.id);
											setShowAction(false);
										}}
										type="button"
									>
										Edit <i className="fa fa-check ml-2"></i>
									</button>
								</div>
								<div className="delete-btn">
									<Popconfirm
										title="Delete Brainstorm"
										description="Are you sure to delete this brainstorm?"
										onConfirm={() => deleteBrainstorm(brainstorm.id)}
									>
										<button type="button">
											Delete <i className="fa fa-times ml-2"></i>
										</button>
									</Popconfirm>
								</div>
							</>
						}
						open={showAction}
						title="Actions"
						trigger="click"
						style={{ cursor: "pointer" }}
					>
						<MenuButton
							onClick={() => setShowAction(!showAction)}
							id={brainstorm.id}
							color={brainstorm.foreground_color}
						/>
					</Popover>
				</div>
			</div>
			<Link to={`/brainstorm/${brainstorm.id}${isShared ? `?sharedId=${shareId}` : ''}`}>
				<div className="display-flex mt-2">
					<img
						src={brainstorm.image_url || newBook}
						className="mr-3 self-start"
						alt="Book or Series Icon"
						width="70"
						height="70"
					/>
					<div>
						<h2
							className="sub-title-16 font-w-800 mb-0-3"
							style={{ color: brainstorm.foreground_color }}
						>
							{brainstorm.brainstorm_name}
						</h2>
						<h4
							className="thin-text-13"
							style={{ lineHeight: 1.5, color: brainstorm.foreground_color }}
						>
							{brainstorm.description}
						</h4>
						<div className="display-flex mt-2">
							<p
								className="thin-text-13 mr-2"
								style={{ color: brainstorm.foreground_color }}
							>
								Updated: {getTimeDuration(brainstorm.updated)}
							</p>
						</div>
					</div>
				</div>
			</Link>
		</div>
	);
};


export default BrainstormCard;

