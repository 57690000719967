import { useState, useEffect } from "react";
import { getBooks, getBrainstorms } from "../../helpers/GetList";
import axios from "axios";
import { message } from "antd";
import { mapFeaturesToButtons } from "../../components/PackageSubscriptionHook";
import { useDispatch, useSelector } from "react-redux";


const useBrainstorms = (id, token) => {
	const dispatch = useDispatch()
	const { bstorm } = useSelector((state)=> state.brainstorm)
	const [books, setBooks] = useState([]);
	const [brainstorms, setBrainstorms] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedBrainstorm, setSelectedBrainstorm] = useState(null);
	const [selectedBook, setSelectedBook] = useState({
		value: "",
		label: "-- Any Book --",
	});
	const [showAddNewDrawer, setShowAddNewDrawer] = useState(false);
	const [showEditDrawer, setShowEditDrawer] = useState(false);
	const [subscriptionModal, setSubscriptionModal] = useState(false);
	const [controlledButtons, setControlledButtons] = useState({
		createBrainstorm: false,
		feature: ""
	});
	const [clickedButton, setClickedButton] = useState([]);
	const [loading, setLoading] = useState(false);

	console.log(loading,'loading')
	useEffect(() => {
		setLoading(true);
		getBooks(id, setSelectedBook, setBooks)
		getBrainstorms(id, 1, setBrainstorms, setTotalRecords, dispatch)
		.then(()=> setLoading(false))
	}, [id]);

	const getBrainstormsByBook = (book) => {
		setLoading(true);
		setSelectedBook(book);
		getBrainstorms(book.value, 1, setBrainstorms, setTotalRecords, dispatch)
		setCurrentPage(1);
	};

	const handlePageClick = (page) => {
		setLoading(true);
		setCurrentPage(page);
		getBrainstorms(selectedBook?.value, page, setBrainstorms, setTotalRecords, dispatch)
	};

	const editBrainstorm = (id) => {
		if (controlledButtons.createBrainstorm) {
			setLoading(true);
			axios(`${process.env.REACT_APP_API_URL}/brainstorms/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			})
				.then((response) => {
					setSelectedBrainstorm(response.data.data);
					setShowEditDrawer(true);
				})
				.catch(() => message.error("Could not fetch Brainstorm"))
		} else {
			setClickedButton([{ feature: "brainstorming-feature" }]);
			openSubscriptionModal();
		}
	};

	const deleteBrainstorm = (id) => {
		if (controlledButtons.createBrainstorm) {
			setLoading(true);
			axios
				.delete(`${process.env.REACT_APP_API_URL}/brainstorms/${id}`, {
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: "application/json",
					},
				})
				.then(() => {
					getBrainstorms(selectedBook?.value, 1, setBrainstorms, setTotalRecords, dispatch);
					setCurrentPage(1);
				})
				.catch(() => message.error("Could not delete Brainstorm."))
		} else {
			setClickedButton([{ feature: "brainstorming-feature" }]);
			openSubscriptionModal();
		}
	};

	const checkSubscription = (action) => {
		const featureMap = {
			"brainstorming-feature": "createBrainstorm"
		};
		const updatedButtons = mapFeaturesToButtons(featureMap, action);
		setControlledButtons(updatedButtons);
	};

	const openSubscriptionModal = () => {
		setSubscriptionModal(true);
	};

	return {
		books,
		brainstorms,
		bstorm,
		totalRecords,
		currentPage,
		selectedBrainstorm,
		selectedBook,
		showAddNewDrawer,
		showEditDrawer,
		subscriptionModal,
		controlledButtons,
		clickedButton,
		loading,
		setShowAddNewDrawer,
		setShowEditDrawer,
		setSubscriptionModal,
		setClickedButton,
		getBrainstormsByBook,
		handlePageClick,
		editBrainstorm,
		deleteBrainstorm,
		checkSubscription,
		openSubscriptionModal
	};
};

export { useBrainstorms };
