const hmsToSecondsOnly = (str) => {
	if (!str) return 0;
	let p = str.split(":"),
		s = 0,
		m = 1;

	while (p.length > 0) {
		s += m * parseInt(p.pop(), 10);
		m *= 60;
	}

	return s;
};

const blobToBase64 = (blob) => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	return new Promise((resolve) => {
		reader.onloadend = () => {
			resolve(reader.result);
		};
	});
};

const dataURLtoFile = (dataURL, filename) => {
	let arr = dataURL.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[arr.length - 1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
};

const urlToFile = async (url, filename, mimeType) => {
	const result = await fetch(url, { mode: "no-cors" });
	const buffer = result.arrayBuffer();
	return new File([buffer], filename, { type: mimeType });
};

export { hmsToSecondsOnly, blobToBase64, dataURLtoFile, urlToFile };
