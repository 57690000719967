import React, { useContext, memo, useCallback } from "react";
import { HeaderDataContext } from "../../../../../context/HeaderContext";
import ChatContact from "../chats/ChatContact";
import { useSelector } from "react-redux";

const ContactItem = memo(({ contact, isOnline, styles }) => {
	const header = useContext(HeaderDataContext);
	const chats = useSelector((state) => state.chat.chats);

	const handleContactClick = useCallback(() => {
		if (header.selectedContact?.id !== contact.id) {
			header.setSelectedContact({
				id: contact.id,
				contact_id: contact.contact_id,
				name: contact.contact.name,
				type: contact.contact_type,
				message: contact.latest_chat?.message,
				time: contact.latest_chat?.message_time.readable,
				unreadCount: contact.total_unread,
				online: false,
				avatar: contact.contact.icon,
				members: contact.contact.members,
				email: contact.contact.email
			});
		}
	}, [header, contact]);

	return (
		<button
			className={`${styles.contactItem} ${contact.id === header.selectedContact?.id
					? styles.contactItemSelected
					: ""
				}`}
			onClick={handleContactClick}
		>
			<ChatContact
				name={contact.contact.name}
				avatar={contact.contact.icon}
				online={isOnline}
				message={contact.latest_chat?.message}
				styles={styles}
			/>
			<div className={styles.chatInfo}>
				<div className={styles.chatTime}>{contact?.latest_chat?.message_time?.readable}</div>
				{contact.total_unread > 0 && (
					<div className={styles.chatCount}>{contact.total_unread}</div>
				)}
			</div>
		</button>
	);
});

export default ContactItem;
