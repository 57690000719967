import React from "react";
import PropTypes from 'prop-types';
import ChatContact from "./ChatContact";
import FeatureCard from "../cards/FeatureCard";
import iconHeart from "../../../../../assets/images/reactions/heart.png";
import iconThumbsUp from "../../../../../assets/images/reactions/thumbs-up.png";
import iconThumbsDown from "../../../../../assets/images/reactions/thumbs-down.png";
import iconQuestionMark from "../../../../../assets/images/reactions/question-mark.png";
import menuIcon from "../../../../../assets/images/menu-icon.png";
import binIcon from "../../../../../assets/images/bin-icon.png";
import pencilIcon from "../../../../../assets/images/pencil-icon.png";
import copyIcon from "../../../../../assets/images/copy-icon.png";
import replyIcon from "../../../../../assets/images/reply-icon.png";
import modalCss from '../../../../../components/outline-side-modal/outlinemodal.css';
import { Image, Modal } from "antd";
import { useChatMessage } from "../../../../../hooks/chats/useChatMessage";

const ChatMessage = (props) => {
  const {
    chat,
    fetchMessages,
    setMessages,
    setEditMessages,
    editMesaages,
    editedMessages,
    deletedId,
    reactedMessage,
    handleDelete,
    setScrollType,
    userReacted,
    setUserReacted,
    styles,
    setRepliedMessage,
    setMessageMenu,
    messageMenu,
    messages,
    hadleImagesModal,
    setImagesOnModal,
    showImageModal
  } = props;


  const chatMessageProps = {
    chat,
    setUserReacted,
    setMessageMenu,
    handleDelete,
    fetchMessages,
    setRepliedMessage,
    setEditMessages,
    reactedMessage,
    setScrollType,
    setImagesOnModal,
    showImageModal
  };

  const {
    chatMessageHandlers,
    chatMessageRefs,
    chatMessageState
  } = useChatMessage(chatMessageProps);

  const {
    handleMenuIcon,
    setIsHover,
    handleMouseActions,
    toggleReaction,
    handleAction,
    handleReaction,
    isFileHover,
    setIsFileHover,
    handlePushToFeature
  } = chatMessageHandlers;

  const {
    clickTimerRef,
    menuRef
  } = chatMessageRefs;

  const {
    messageAttachments,
    currentReaction,
    menuVisible
  } = chatMessageState;

  const renderMessageAttachments = () => {
    if (messageAttachments.length === 1) {
      return <Image src={messageAttachments[0]?.original_url} preview={true} width={400} height={300} style={{ borderRadius: "15px 15px 0px 15px" }} />;
    }
    if (messageAttachments.length === 2) {
      return (
        <div className={styles.grid_col} style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', width: '400px' }}>
          <Image src={messageAttachments[0]?.original_url} preview={true} style={{ borderRadius: "15px 0px 0px 15px", height: '300px', objectFit: 'cover' }} />
          <Image src={messageAttachments[1]?.original_url} preview={true} style={{ borderRadius: "0px 15px 0px 0px", height: '300px', objectFit: 'cover' }} />
        </div>
      );
    }
    if (messageAttachments.length === 3) {
      return (
        <div className={styles.grid_col} style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', width: '400px' }}>
          <Image src={messageAttachments[0]?.original_url} preview={true} style={{ borderRadius: "15px 0px 0px 15px", height: '300px', objectFit: 'cover' }} />
          <div className={styles.grd_img} style={{ height: '300px' }}>
            <Image src={messageAttachments[1]?.original_url} preview={true} style={{ borderRadius: "0px 15px 0px 0px", height: '148.5px', objectFit: 'cover' }} />
            <Image src={messageAttachments[2]?.original_url} preview={true} style={{ borderRadius: "0px 0px 0px 0px", height: '148.5px', objectFit: 'cover' }} />
          </div>
        </div>
      );
    }
    if (messageAttachments.length > 3) {
      return (
        <div className={styles.grid_col} style={{ display: 'grid', gridGap: '3px', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', width: '400px' }}>
          <Image src={messageAttachments[0]?.original_url} preview={true} style={{ borderRadius: "15px 0px 0px 15px", height: '300px', objectFit: 'cover' }} />
          <div className={styles.grid_img} style={{ height: '300px', display: 'grid', gridRowGap: '3px' }}>
            <Image src={messageAttachments[1]?.original_url} preview={true} style={{ borderRadius: "0px 15px 0px 0px", height: '148.5px', objectFit: 'cover' }} />
            <div onMouseOver={() => setIsFileHover(true)} onMouseLeave={() => setIsFileHover(false)} onClick={() => hadleImagesModal(chat.id)} className={styles.more_img}>
              <Image src={messageAttachments[2]?.original_url} preview={false} style={{ borderRadius: "0px 0px 0px 0px", height: '148.5px', objectFit: 'cover' }} />
              <div style={{ display: isFileHover ? 'flex' : 'none' }} className={styles.more_img}>{messageAttachments.length - 3} +</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderReactionButtons = () => (
    <div ref={menuRef} className={styles.reactionButtons} style={{ display: menuVisible && !chat.is_mine ? "flex" : "none", right: "-80px" }}>
      {[iconHeart, iconThumbsUp, iconThumbsDown, iconQuestionMark].map((icon, index) => (
        <button key={index} className={styles.reactionButtonsAll} onClick={() => handleReaction(index)}>
          <img src={icon} alt="Reaction" width={20} />
        </button>
      ))}
    </div>
  );

  const renderMessageMenu = () => (
    <ul className={styles.dropdownListings} style={{ left: chat.is_mine ? "-120px" : "auto", right: !chat.is_mine ? "-120px" : "auto" }}>
      <li onClick={() => handleAction('reply', chat.id)}><Image src={replyIcon} preview={false} /> Reply</li>
      {chat.message !== null && chat.is_mine && <li onClick={() => handleAction('edit', chat.id)}><Image src={pencilIcon} preview={false} /> Edit Message</li>}
      {chat.message !== null && <li onClick={() => handleAction('copy', chat.message)}><Image src={copyIcon} preview={false} /> Copy Message</li>}
      <li onClick={() => handleAction('delete', chat.id)}><Image src={binIcon} preview={false} /> Delete Message</li>
    </ul>
  );

  console.log(chat)
  return (
    <>
      {deletedId.length > 0 && deletedId.includes(chat.id) ? null : (
        <div className={`${styles.chatMessage} ${chat.is_mine ? styles.chatMessageMy : ""}`} data-read={chat.is_read} id={`message${chat.id}`}>
          <ChatContact avatar={chat.avatar} styles={styles} />
          <div className={styles.messageParent}>
            <div style={{ display: 'flex', flexDirection: chat.is_mine ? 'row-reverse' : 'row' }}>
              {messageAttachments.length > 0 && renderMessageAttachments()}
              <FeatureCard {...chat} handleMenuIcon={handleMenuIcon} handlePushToFeature={handlePushToFeature} />
            </div>
            <div className={styles.messagesParent} style={{ margin: chat.is_mine ? "0 0 0 auto" : "0", justifyContent: chat.is_mine ? "flex-end" : "flex-start" }}>
              {chat.message && (
                <div className={styles.message} onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onMouseDown={() => handleMouseActions('down')} onMouseUp={() => handleMouseActions('up')} onDoubleClick={() => clearTimeout(clickTimerRef.current)} style={{ padding: chat.is_mine ? "10px 25px 10px 20px" : "10px 45px 10px 15px", maxWidth: chat.message?.length > 100 ? "64%" : "100%", order: chat.is_mine ? "2" : "1", textAlign: chat.is_mine ? "left" : "right" }}>
                  {chat.parent_id && (
                    <div className={styles.chatReply} style={{ textAlign: !chat.is_mine ? 'right' : 'left' }}>
                      <div className={styles.chatReplyBox}>
                        <h6 className={styles.boxHeadingText}>
                          {chat.from_name === chat.from_name || chat.is_mine ? 'You' : chat.from_name} <span className={styles.crossIconMark}></span>
                        </h6>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'start' }}>
                          {chat.attachments && chat.attachments.length > 0 && <Image src={chat.attachments[0].original_url} width={60} height={60} preview={false} style={{ marginTop: '10px', borderRadius: '5px' }} />}
                        </div>
                        <p className={styles.chatReplyText}>{chat.message}</p>
                      </div>
                    </div>
                  )}
                  {editedMessages.filter((editedMessage) => editedMessage.id === chat.id).message || chat.message}
                  {currentReaction?.reactionType && currentReaction.chatId === chat.id && (
                    <div className={styles.reactionEmoji}>
                      <img onClick={(e) => {
                        e.stopPropagation();
                        if (clickTimerRef.current) {
                          clearTimeout(clickTimerRef.current);
                          clickTimerRef.current = null;
                          toggleReaction(chat.id, currentReaction?.reactionType);
                        } else {
                          clickTimerRef.current = setTimeout(() => {
                            clearTimeout(clickTimerRef.current);
                            clickTimerRef.current = null;
                          }, 300);
                        }
                      }} src={currentReaction.reactionType === 1 ? iconHeart : currentReaction.reactionType === 2 ? iconThumbsUp : currentReaction.reactionType === 3 ? iconThumbsDown : currentReaction.reactionType === 4 ? iconQuestionMark : null} alt="Reaction" width={20} />
                    </div>
                  )}
                  {renderReactionButtons()}
                </div>
              )}
              {messageMenu.visible && messageMenu.index === chat.id && renderMessageMenu()}
              {chat.message && <div onClick={() => handleMenuIcon(chat.id)} style={{ order: chat.is_mine ? "1" : "2" }}><Image src={menuIcon} preview={false} /></div>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ChatMessage.propTypes = {
  chat: PropTypes.object.isRequired,
  fetchMessages: PropTypes.func.isRequired,
  setMessages: PropTypes.func.isRequired,
  setScrollType: PropTypes.func.isRequired,
  userReacted: PropTypes.object.isRequired,
  setUserReacted: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
  setRepliedMessage: PropTypes.func.isRequired,
  setMessageMenu: PropTypes.func.isRequired,
  messageMenu: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired
};

export default ChatMessage;

