import React from "react";
import { FaPause, FaMicrophone } from "react-icons/fa";
import { AudioRecorder } from "react-audio-voice-recorder";
import { LiveAudioVisualizer } from "react-audio-visualize";
import ScaleBar from "./ScaleBar";

const Recorder = ({ recorder, toggleRecording, error }) => {
	const recordingBars = [0, ...Array(recorder.recordingTime)];

	return (
		<>
			<div className="recorder">
				<ScaleBar recordingBars={recordingBars} />
				{recorder.mediaRecorder && (
					<div className="visualizer-container">
						<LiveAudioVisualizer
							mediaRecorder={recorder.mediaRecorder}
							width={200}
							height={30}
							barColor="#ffffff"
						/>
					</div>
				)}
				<button
					type="button"
					className={`play-pause${recorder.isRecording ? " glow" : ""}`}
					onClick={toggleRecording}
				>
					<span>
						{recorder.isRecording ? (
							<FaPause color="#2DB6F5" />
						) : (
							<FaMicrophone color="#388D44" />
						)}
					</span>
				</button>
			</div>
			<div style={{ display: "none" }}>
				<AudioRecorder
					audioTrackConstraints={{
						noiseSuppression: true,
						echoCancellation: true,
					}}
					// downloadOnSavePress={true}
					downloadFileExtension="mp3"
					recorderControls={recorder}
					style={{ display: "none" }}
				/>
			</div>
			{error.audio_file && (
				<p className="ant-form-item-explain-error mb-2">{error.audio_file}</p>
			)}
		</>
	);
};

export default Recorder;
