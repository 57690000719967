import React, { useState } from "react";
import Select from "react-select";
import CustomButton from "../button/Button";
import CreateBookIcon from "../../assets/icons/create-book.svg";
import PlotlineCard from "./PlotlineCard";
import { Pagination } from "antd";
import AddPlotlineDrawer from "./AddPlotlineDrawer";
import EditPlotlineDrawer from "./EditPlotlineDrawer";
import PackageSubscriptionHook from "../PackageSubscriptionHook";
import { SubscriptionModal } from "../subscriptionodal";
import { usePlotlines } from "../../hooks/plotlines/usePlotlines";
import { CommentsDrawer } from "../comments";
import { axiosInstance } from "../../services/apiServices/config";
import { setPlotPlainerSelice } from "../../redux/Slice/PlotlineSlice";
import FullPageSpinner from "../loader/FullPageSpinner";

const Plotlines = () => {
	const {
		ref,
		dispatch,
		header,
		id,
		loading,
		totalRecords,
		currentPage,
		plotlines,
		plotlinesData,
		selectBook,
		selectedBook,
		showAddNewDrawer,
		showEditDrawer,
		selectedPlotline,
		books,
		subscriptionModal,
		controlledButtons,
		clickedButton,
		setClickedButton,
		setShowAddNewDrawer,
		setShowEditDrawer,
		setSubscriptionModal,
		getPlotlinesByBook,
		handlePageClick,
		editPlotline,
		deletePlotline,
		checkSubscription,
		openSubscriptionModal,
	} = usePlotlines();

	const plotlinesToDisplay = selectedBook.value === '' ? plotlinesData : plotlines;

	const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState({isOpen: false, id: null});
	const [commentsState, setCommentsState] = useState({});
	const [newCommentState, setNewCommentState] = useState("");

	const showCommentDrawer = (comments, id) => {
		setCommentsState(prevState => ({
			...prevState,
			[id]: comments.map(i => ({author: 'you', comment: i.comment}))
		}));
		setIsCommentDrawerOpen({id, isOpen: true});
	};

	const handleCommentDrawerClose = () => {
		setIsCommentDrawerOpen({isOpen: false, id: null});
	};

	const handleAddComment = async () => {
		if (newCommentState.trim()) {
			const postComments = await axiosInstance.post('/comments', {
				comment_type: "plot_planner",
				comment: newCommentState,
				plot_planner_id: isCommentDrawerOpen.id
			});
			if (postComments.data) {
				const item = plotlines.find(i => i.id == isCommentDrawerOpen.id);
				if (item) {
					const updatedComments = [...item.comments, { id: item.comments.length + 1, author: 'You', comment: newCommentState }];
					const updatedItem = { ...item, comments: updatedComments };
					const updatedplotlines = plotlines.map(i => i.id === isCommentDrawerOpen.id ? updatedItem : i);
					dispatch(setPlotPlainerSelice(updatedplotlines));
				}
				setCommentsState(prevState => ({
					...prevState,
					[isCommentDrawerOpen.id]: [...prevState[isCommentDrawerOpen.id], { id: prevState[isCommentDrawerOpen.id].length + 1, author: 'You', comment: newCommentState }]
				}));
				setNewCommentState("");
			}
		}
	};

	return (
		<div className="main-container">
			<PackageSubscriptionHook
				feature={["plot-planning-feature"]}
				ref={ref}
				title="Plotline"
				checkPackageSubscription={checkSubscription}
			/>
			<div className="list-actions">
				<div className="addnew">
					<CustomButton
						type="primary"
						buttonTitle={"Add New Plotline"}
						onClick={() => controlledButtons.createPlotline ? setShowAddNewDrawer(true) : (setClickedButton([{feature: "plot-planning-feature"}]), openSubscriptionModal())}
						icon={<img src={CreateBookIcon} alt="Button Icon" />}
					/>
				</div>
				{books.length > 0 && (
					<div className="filter">
						<label>Select book: </label>
						<Select
							options={[selectBook, ...books]}
							onChange={getPlotlinesByBook}
							isSearchable={true}
							className="select-book"
							value={selectedBook}
						/>
					</div>
				)}
			</div>
			<div className="card-container mt-2">
				<div className="p-3">
					{loading &  plotlinesToDisplay.length === 0 ? <FullPageSpinner/>
					: plotlinesToDisplay.length === 0 ? (
						<h4 style={{ textAlign: "center" }}>
							No Plotline to display, Click on New Plotline Button to add new.
						</h4>
					) : (
						 plotlinesToDisplay.map((plotline) => (
							<PlotlineCard
								key={plotline.id}
								style={{
									backgroundColor: plotline.color_code,
									color: plotline.foreground_color,
									cursor: "pointer",
								}}
								plotline={plotline}
								editPlotline={editPlotline}
								deletePlotline={deletePlotline}
								showCommentDrawer={()=> showCommentDrawer(plotline.comments, plotline.id)}
							/>
						))
					)}
				</div>
				<Pagination
					rootClassName="list-pagination"
					total={totalRecords}
					current={currentPage}
					onChange={handlePageClick}
				/>
			</div>
			<AddPlotlineDrawer
				open={showAddNewDrawer}
				close={() => setShowAddNewDrawer(false)}
				books={books}
				getPlotlinesByBook={getPlotlinesByBook}
			/>
			<EditPlotlineDrawer
				plotline={selectedPlotline}
				open={showEditDrawer}
				close={() => setShowEditDrawer(false)}
				books={books}
				getPlotlinesByBook={getPlotlinesByBook}
			/>
			<SubscriptionModal
				open={subscriptionModal}
				ok={openSubscriptionModal}
				features={clickedButton}
				cancel={() => setSubscriptionModal(false)}
			/>
			<CommentsDrawer
				mod="auther"
				hasEditor={true}
				isCommentDrawerOpen={isCommentDrawerOpen.isOpen}
				handleCommentDrawerClose={handleCommentDrawerClose}
				comments={commentsState[isCommentDrawerOpen.id] || []}
				newComment={newCommentState}
				setNewComment={setNewCommentState}
				handleAddComment={handleAddComment}
			/>
		</div>
	);
};

export default Plotlines;