import { Col, Row } from "antd";
import PackageSubscriptionHook from "../../components/PackageSubscriptionHook";
import "./index.css";
import { CreateNewBookDrawer } from "../../components/CreateNewBookDrawer";
import Vector from "../../assets/images/Group 178.png";
import { useBooksAndSeries } from "../../hooks/books/useBookAndSeries";


const BooksAndSeries = () => {
	const {
		contextHolder,
		ref,
		checkSubscription,
		select,
		setSelect,
		options,
		booksContentSection,
		activityLogSection,
		createBook,
		cardStatus,
		onAddData,
		closeCreateBookDrawer
	} = useBooksAndSeries();

	return (
		<>
			{contextHolder}
			<PackageSubscriptionHook
				feature={["full-planning-features-for-a-new-book", "series-feature"]}
				packageName="calendar-feature"
				ref={ref}
				title="Multiple Features"
				checkPackageSubscription={checkSubscription}
			/>
			<div className="main-container">
				<div className="display-flex">
					<div className="screen-title dark-black mr-3">
						Welcome back
					</div>
					<div className="display-flex">
						<select
							onChange={(e) => setSelect(e.target.value)}
							value={select}
							defaultValue="All Books"
							placeholder="Select Book/Series"
							style={{
								border: "none",
								fontSize: "18px",
								fontWeight: "bolder",
								width: "150px",
								backgroundColor: "transparent",
							}}
						>
							{options.map((option, index) => (
								<option value={option.value} key={index}>
									{option.text}
								</option>
							))}
						</select>
					</div>
				</div>
				<Row style={{ padding: "10px" }}>
					<div
						style={{
							position: "absolute",
							marginTop: "19px",
							marginLeft: "80%",
						}}
					>
						<img src={Vector} alt="bitmap" />
					</div>
				</Row>
				<Row className="card-container">
					<Col xs={24} sm={24} md={17} lg={17} xl={17} xxl={17}>
						<div className="left-section">{booksContentSection()}</div>
					</Col>
					<Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
						<div className="activity-section">{activityLogSection()}</div>
					</Col>
				</Row>
			</div>
			<CreateNewBookDrawer
				status={cardStatus}
				onSubmit={onAddData}
				open={createBook}
				close={closeCreateBookDrawer}
			/>
		</>
	);
};

export default BooksAndSeries;
