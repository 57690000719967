import { Avatar, Button, Drawer, Form, Input } from "antd";

const   CommentsDrawer = ({ isCommentDrawerOpen, handleCommentDrawerClose, comments, newComment, setNewComment, handleAddComment, mod, hasEditor }) => (
    <Drawer
        title="Comments"
        placement="right"
        onClose={handleCommentDrawerClose}
        open={isCommentDrawerOpen}
        width={400}
        bodyStyle={{ padding: 0, display: "flex", flexDirection: "column" }}
    >
        <div className="comment-section" style={{ padding: "20px", backgroundColor: "#f9f9f9", flex: 1, overflowY: "auto" }}>
            {comments?.map((comment) => (
                <div className="comment" key={comment.id} style={{ display: "flex", alignItems: "center", marginBottom: "15px", borderBottom: "1px solid #e0e0e0", paddingBottom: "10px" }}>
                    {/* <Avatar src={`https://example.com/avatar${comment.id}.png`} alt={comment.author} style={{ marginRight: "10px" }} /> */}
                    <div className="comment-content" style={{ backgroundColor: "#ffffff", padding: "15px", borderRadius: "10px 20px", width: "100%", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)" }}>
                        <p className="comment-author" style={{ fontWeight: "bold", marginBottom: "5px", color: "#333" }}>{comment.comment_type === 'chapter_card' ? 'You' : comment.author}</p>
                        <p className="comment-text" style={{ margin: 0, color: "#555" }}>{comment.comment}</p>
                    </div>
                </div>
            ))}
        </div>
        <div style={{ padding: "20px", backgroundColor: "#f9f9f9" }}>
            {hasEditor && mod !== 'guest' && <Form onFinish={handleAddComment}>
                <Form.Item style={{ marginBottom: "10px" }}>
                    <Input.TextArea
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder="Add a comment"
                        rows={4}
                        style={{ borderRadius: "10px", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)" }}
                    />
                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                    <Button type="primary" htmlType="submit" style={{ borderRadius: "20px", backgroundColor: "#4CAF50", border: "none", padding: "10px 20px", fontSize: "16px", fontWeight: "bold", color: "#fff", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
                        Add Comment
                    </Button>
                </Form.Item>
            </Form>}
        </div>
    </Drawer>
);

export { CommentsDrawer };