import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import { HeaderDataContext } from "../../context/HeaderContext";
import { ChatDataContext } from "../../context/ChatContext";

import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { getContacts, setMessageFetching } from "../../redux/Slice/ChatSlice";
import { LogoutFunc } from "../../redux/Slice/AuthSlice";
import { useResetAllState } from "../../helpers/resetAllState";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { axiosInstance } from "../../services/apiServices/config";
import { mapFeaturesToButtons } from "../../components/PackageSubscriptionHook";

const useIndex = () => {
    const ENDPOINT = 'https://charlii-socket.clickysoft.app';

    const ref = useRef(null);

    // Navigation and state management hooks
    const router = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { resetAll } = useResetAllState();

    // Local state definitions
    const [loading, setLoading] = useState(false);
    const [chatShouldShow, setChatShouldShow] = useState(true);
    const [messages, setMessages] = useState([]);
    const [searchedMessages, setSearchedMessages] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [userReacted, setUserReacted] = useState({
        count: 0,
        message: null
    });
    const [socket, setSocket] = useState(null);
    const [socketConnected, setSocketConnected] = useState(false);
    const [chatRoomId, setChatRoomId] = useState(null);
    const [typing, setTyping] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [attachedFiles, setAttachedFiles] = useState([]);
    const [isLeftModal, setIsLeftModal] = useState(false);
    const [isRightModal, setIsRightModal] = useState(false);
    const header = useContext(HeaderDataContext);
    const [scrollType, setScrollType] = useState('down');
    const chatContext = useContext(ChatDataContext);
    const [reactedMessage, setReactedMessage] = useState([]);
    const [emitType, setEmitType] = useState('message');
    const [temp, setTemp] = useState([]);
    const [sendingMessage, setSendingMessage] = useState(false);
    const [previousContactId, setPreviousContactId] = useState(null)

    // Feature toggles
    const [twoStepVerification, setTwoStepVerification] = useState(false);
    const [notification, setNotification] = useState(false);

    // Refs for persisting values across renders
    const chatRoomIdRef = useRef(chatRoomId);
    const messagesRef = useRef(null);
    const pageRef = useRef(1);
    const totalPagesRef = useRef(0);

    // Handlers for feature toggles
    const handleTwoStepVerificationToggle = useCallback(() => {
        setTwoStepVerification((prevState) => !prevState);
    }, []);

    const handleNotificationToggle = useCallback(() => {
        setNotification((prevState) => !prevState);
    }, []);

    // Subscription
    const checkSubscription = useCallback((action) => {
        const featureMap = {
            "chat-feature": "chatArea"
        };
        const updatedButtons = mapFeaturesToButtons(featureMap, action);
        setChatShouldShow(updatedButtons);
    }, []);

    // Modal display handlers
    const handleIsRightModal = useCallback(() => {
        setIsRightModal(true);
        setIsLeftModal(false);
    }, []);

    const handleIsLeftModal = useCallback(() => {
        setIsLeftModal(true);
        setIsRightModal(false);
    }, []);

    // Message fetching and manipulation
    const fetchMessages = useCallback(async (id) => {
        let index = messages.findIndex((message) => message.id === id);
        if (index !== -1) {
            setMessages((prevMessages) => {
                const newMessages = [...prevMessages];
                newMessages.splice(index, 1);
                return newMessages;
            });
        }
    }, [messages]);

    // Scroll to the bottom of the chat on new message
    const scrollToBottom = useCallback(() => {
        if (messages.length > 0 && messagesRef.current && pageRef.current === 1) {
            messagesRef.current.scrollIntoView();
        }
    }, [messages]);

    // Listener for typing indicator
    const typingListener = useCallback((room) => {
        if (room === chatRoomIdRef.current) {
            setIsTyping(true);
        }
    }, []);

    // Handler for reactions received via socket
    const handleReactBySocket = useCallback((data) => {
        const reactionType = data.hearts ? 1 : data.thumbs_up ? 2 : data.thumbs_down ? 3 : data.question_marks ? 4 : null;
        return reactionType;
    }, []);

    // Listener for new messages via socket
    const newMessageListener = useCallback((message) => {
        if (
            header.selectedContact &&
            message.user === user[0].user.id &&
            message.sender === header.selectedContact.contact_id
        ) {
            setEmitType(message.type);
            if (message.type === "message") {
                setScrollType('down');
                scrollToBottom();
                messagesRef.current.scrollIntoView();
                if (message.chat.replies.length > 0) {
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        { ...message.chat.replies[0], is_mine: false, avatar: message.avatar },
                    ]);
                } else {
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        { ...message.chat, is_mine: false, avatar: message.avatar },
                    ]);
                }
            } else if (message.type === "reaction") {
                setScrollType('paginate');
                setReactedMessage((prevReactedMessage) => [
                    ...prevReactedMessage,
                    { reactType: handleReactBySocket(message), chatId: message.chat.id, ...message }
                ]);
            } else if (message.type === "delete") {
                setScrollType('paginate');
                setTemp({ id: message.id, type: 'delete' });
            } else if (message.type === "edit") {
                setTemp({ id: message.id, type: 'edit', message: message.message });
            }
        } else {
            dispatch(getContacts());
        }
    }, [header.selectedContact, user, dispatch, handleReactBySocket, scrollToBottom]);

    // Fetching chat history
    const fetchChats = useCallback(async (page, type) => {
        dispatch(setMessageFetching(true))
        if (header.selectedContact) {
            const chatUrl = `${process.env.REACT_APP_API_URL}/${header.selectedContact.type === "Group" ? "groups/" : ""
                }chats/${header.selectedContact.contact_id}${page > 0 ? `?page=${page}` : ""
                }`;

            try {
                const response = type === "add" && await axiosInstance.get(chatUrl);

                type === "add" && setMessages((prevData) => [...response.data.data, ...prevData]);
                type === "replace" && setTemp(Math.random());
                totalPagesRef.current = type === "add" && response.data.meta.last_page;
                dispatch(setMessageFetching(false))
            } catch (error) {
                dispatch(setMessageFetching(false))
                console.log(error);
            }
        }
    }, [header.selectedContact]);

    // Determining and setting the chat room ID
    const getChatRoom = useCallback(async () => {
        if (header.selectedContact) {
            if (header.selectedContact.type === "Group") {
                setChatRoomId(`g${header.selectedContact.contact_id}`);
                chatRoomIdRef.current = `g${header.selectedContact.contact_id}`;
            } else {
                try {
                    const chatRoom = await axiosInstance.get(
                        `${process.env.REACT_APP_API_URL}/chats/get-chat-room/${header.selectedContact.contact_id}`
                    );
                    setChatRoomId(`u${chatRoom.data.chat_room_id}`);
                    chatRoomIdRef.current = `u${chatRoom.data.chat_room_id}`;
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }, [header.selectedContact]);

    // Pagination control for chat history
    const gotoPreviousPage = useCallback(() => {
        if (pageRef.current < totalPagesRef.current) {
            pageRef.current = pageRef.current + 1;
            fetchChats(pageRef.current, "add");
        } else {
            dispatch(setMessageFetching(false))
        }
    }, [fetchChats]);

    // search messages
    const searchMessages = useCallback((e) => {
        const { value } = e.target;
        setSearchQuery(value);
    }, []);

    console.log(header.selectedContact)
    // Sending messages
    const sendMessage = useCallback(async (chat, features, repliedMessage, attachedFiles) => {
        console.log('trigered')
        if (!sendingMessage) {
            setSendingMessage(true);
            if (chat || features.length > 0 || attachedFiles.length > 0 || repliedMessage || Object.keys(chatContext.selectedFeature).length > 0) {
                const formData = new FormData();
                formData.append("message", chat ?? null);
                formData.append(
                    header.selectedContact.type === "User" ? "to_id" : "group_id",
                    header.selectedContact.contact_id
                );
                repliedMessage && formData.append("parent_id", repliedMessage);
                formData.append("contact_id", header.selectedContact.id);
                socket.emit("stop typing", chatRoomId);

                for (let file of attachedFiles) {
                    formData.append("attachments[]", file);
                }

                if (Object.keys(chatContext.selectedFeature).length > 0) {
                    Object.keys(chatContext.selectedFeature).forEach((key) => {
                        formData.append(
                            key + "_id",
                            chatContext.selectedFeature[key].id
                        );
                        const collaboratorData = {
                            email: [header.selectedContact.email],
                            collaboratable_id: [chatContext.selectedFeature[key].id],
                            collaboratable_type: [key + 's'],
                            role: ["viewer"]
                        };
                        axiosInstance.post("/collaborators", collaboratorData);
                    });
                }

                try {
                    setScrollType('down');
                    const message = await axiosInstance.post("/chats", formData);
                    messagesRef.current.scrollIntoView();
                    socket.emit("new message", {
                        chat: message.data.data,
                        members: header.selectedContact.members,
                        sender: user[0].user.id,
                        avatar: user[0].user.profile_image,
                        type: 'message',
                    });
                    socket.emit("new_message", {
                        chat: message.data.data,
                        members: header.selectedContact.members,
                        sender: user[0].user.id,
                        avatar: user[0].user.profile_image,
                        type: 'message',
                    });
                    if (!repliedMessage) {
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            { ...message.data.data, is_mine: true },
                        ]);
                    } else {
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            { ...message.data.data.replies[0], is_mine: true, avatar: user[0].user.profile_image },
                        ]);
                    }
                    scrollToBottom();
                    setAttachedFiles([]);
                    chatContext.setSelectedFeature({});
                    setSendingMessage(false);
                    return true;
                } catch (error) {
                    message.error("Message could not be sent.");
                    console.log(error);
                }
                return false;
            }
            setSendingMessage(false);
        }
    }, [sendingMessage, header.selectedContact, chatContext, socket, user, scrollToBottom]);

    // Edit message
    const editMessage = useCallback(async (data) => {
        const { id, message } = data;
        try {
            const response = await axiosInstance.put(`/chats/${id}`, { message });
            if (response.data.success) {
                return true;
            }

            return false;
        } catch (error) {
            console.log(error);
        }
    }, []);

    // Typing indicator handling
    const handleTyping = useCallback(() => {
        if (!socketConnected) return false;
        if (!typing) {
            setTyping(true);
            socket.emit("typing", chatRoomId);
            setTimeout(() => {
                setTyping(false);
                socket.emit("stop typing", chatRoomId);
            }, 2000);
        }
    }, [socketConnected, typing, chatRoomId, socket]);

    // Message deletion handling
    const handleDelete = useCallback((data) => {
        socket.emit("new message", {
            members: header.selectedContact.members,
            sender: user[0].user.id,
            id: data,
            type: 'delete',
        });
        socket.emit("new_message", {
            members: header.selectedContact.members,
            sender: user[0].user.id,
            id: data,
            type: 'delete',
        });
    }, [header.selectedContact, user, socket]);

    // Message editing handling
    const handleEdit = useCallback((data, chat) => {
        socket.emit("new message", {
            members: header.selectedContact.members,
            sender: user[0].user.id,
            id: data,
            message: chat,
            type: 'edit',
        });
        socket.emit("new_message", {
            members: header.selectedContact.members,
            sender: user[0].user.id,
            id: data,
            message: chat,
            type: 'edit',
        });
    }, [header.selectedContact, user, socket]);

    // Logout functionality
    const handleLogout = useCallback(() => {
        setLoading(true);
        localStorage.removeItem("token");
        dispatch(LogoutFunc()).then(() => {
            resetAll();
        }).catch((err) => setLoading(false));
        localStorage.removeItem("authenticated");
        localStorage.removeItem("isModalShown");
    }, [dispatch, resetAll]);

    // Deleting contact
    const deleteContact = useCallback(async () => {
        const response = await axiosInstance.delete(`/contacts/${header.selectedContact.id}`);
        await axiosInstance.get(`delete-all-message/${header.selectedContact.id}`);
        if (response.data.success) {
            header.setSelectedContact(null);
            dispatch(getContacts());
        }
    }, [header, dispatch]);

    // Setting up socket connection and event listeners
    useEffect(() => {
        // if (header.selectedContact) {
            const contact_id = header.selectedContact?.id;
            header.setHeading("Chat");
            const newSocket = io(ENDPOINT);
            setSocket(newSocket);
            setPreviousContactId(contact_id);

            newSocket.on("connected", (users) => {
                setOnlineUsers(users);
                setSocketConnected(true);
            });

            newSocket.on("typing", typingListener);
            newSocket.on("stop typing", () => setIsTyping(false));

            newSocket.on("update online users", (users) => {
                setOnlineUsers(users);
            });

            newSocket.on("message received", newMessageListener);

            return () => {
                newSocket.off("typing", typingListener);
                newSocket.off("stop typing");
                newSocket.off("update online users");
                newSocket.off("message received", newMessageListener);
                newSocket.disconnect();
            };
        // }
    }, [ typingListener, newMessageListener]);

    useEffect(scrollToBottom, [messages, scrollToBottom]);
    console.log(socketConnected)

    useEffect(() => {
        if (socket) {
            const userData = {
                id: user[0].user.id,
                name: user[0].user.name,
                email: user[0].user.email,
            };
            socket.emit("setup", userData);

            return () => {
                socket.off("setup", userData);
            };
        }
    }, [socket, user]);

    useEffect(() => {
        if (userReacted.count > 0) {
            socket.emit("new message", {
                chat: userReacted.message,
                members: header.selectedContact.members,
                sender: user[0].user.id,
                type: 'reaction',
            });
            socket.emit("new_message", {
                chat: userReacted.message,
                members: header.selectedContact.members,
                sender: user[0].user.id,
                type: 'reaction',
            });
        }
    }, [userReacted, header.selectedContact, socket, user]);

    useEffect(() => {
        if (socket) {
            socket.emit("user online", user[0].user.id);
        }
    }, [socketConnected, socket, user]);

    useEffect(() => {
        if (chatRoomId) {
            socket.emit("join chat", chatRoomId);
        }
        return () => {
            if (chatRoomId) {
                socket.emit("leave chat", chatRoomId);
            }
        };
    }, [chatRoomId, socket]);

    useEffect(() => {
        if (header.selectedContact) {
            getChatRoom();
            setEmitType((prevEmitType) => {
                if (prevEmitType !== 'delete') {
                    setMessages([]);
                }
                return prevEmitType;
            });
            pageRef.current = 1;
            if (emitType !== 'delete') {
                fetchChats(pageRef.current, 'add');
            }
        }
    }, [header.selectedContact, getChatRoom, fetchChats, emitType]);

    useEffect(() => {
        const searchMessageService = async () => {
            const res = await axiosInstance.get(`/chats/${header.selectedContact.contact_id}?filter=${searchQuery}`);
            setSearchedMessages(res.data.data.filter((message) => message.message && message.message.toLowerCase().includes(searchQuery.toLowerCase())));
        };

        const debounceSearch = setTimeout(() => {
            if (searchQuery.length > 0) {
                searchMessageService();
            }
        }, 50);

        if (searchQuery.length === 0) {
            setSearchedMessages([]);
        }
        return () => clearTimeout(debounceSearch);
    }, [searchQuery, header.selectedContact]);

    // Clean up state on unmount
    useEffect(() => {
        return () => {
            setMessages([]);
            setSearchedMessages([]);
            setOnlineUsers([]);
            setUserReacted({ count: 0, message: null });
            setSocket(null);
            setSocketConnected(false);
            setChatRoomId(null);
            setTyping(false);
            setIsTyping(false);
            setAttachedFiles([]);
            setIsLeftModal(false);
            setIsRightModal(false);
            setScrollType('down');
            setReactedMessage([]);
            setEmitType('message');
            setTemp([]);
            setSendingMessage(false);
            setPreviousContactId(null);
            setTwoStepVerification(false);
            setNotification(false);
        };
    }, []);

    return {
        modals: {
            isLeftModal,
            isRightModal,
            setIsLeftModal,
            setIsRightModal,
            handleIsLeftModal,
            handleIsRightModal,
        },
        userState: {
            user,
            onlineUsers,
            socketConnected,
            setSocketConnected
        },
        messageState: {
            messages,
            setMessages,
            searchedMessages,
            fetchMessages,
            sendMessage,
            messagesRef,
            attachedFiles,
            setAttachedFiles,
            gotoPreviousPage,
            handleDelete,
            handleEdit,
            editMessage,
            temp,
            setTemp,
        },
        reactionState: {
            userReacted,
            setUserReacted,
            reactedMessage,
            scrollType,
            setScrollType,
        },
        settings: {
            twoStepVerification,
            notification,
            handleTwoStepVerificationToggle,
            handleNotificationToggle,
        },
        utilities: {
            ref,
            checkSubscription,
            chatShouldShow,
            handleLogout,
            handleTyping,
            isTyping,
            header,
            deleteContact,
            searchMessages,
            setSearchedMessages
        }
    };
};

export default useIndex;
