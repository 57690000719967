import { Drawer, Button, message, Form, Modal, Select as AntSelect } from "antd";
import axios from "axios";

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import newBook from "../../assets/images/newbook.png";
import { getColorData } from "../../redux/Slice/ColorSlice";
import Select from "react-select";
import UserGallery from "../gallery/UserGallery";
import TextAreaInput from "../Inputs/TextAreaInput";
import TextInput from "../Inputs/TextInput";
import { CollaborativeShareField } from "../collaborativeShareField";

const AddTimelineDrawer = ({ open, close, books, getTimelinesByBook }) => {
	const selectColor = {
		value: "",
		label: "-- Select Color --",
	};
	const selectBook = {
		value: "",
		label: "-- Select Book --",
	};

	const [timelineName, setTimelineName] = useState("");
	const [timelineDescription, setTimelineDescription] = useState("");
	const [selectedFile, setSelectedFile] = useState(null);
	const [showGallery, setShowGallery] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [currentUser, setCurrentUser] = useState({ email: '', visible: false });

	const [colorOptions, setColorOptions] = useState([]);
	const [selectedColor, setSelectedColor] = useState(selectColor);
	const [selectedBook, setSelectedBook] = useState(selectBook);

	const [error, setError] = useState({});

	//dispatching ACTIONS
	const dispatch = useDispatch();
	const { colors } = useSelector((state) => state.colors);
	const { users } = useSelector((state) => state.user);

	const form = useRef();
	const [forms] = Form.useForm();

	useEffect(() => {
		dispatch(getColorData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const options = colors.map((color) => {
			return {
				value: color.id,
				label: color.color,
			};
		});

		setColorOptions(() => [selectColor, ...options]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colors]);

	const saveTimeline = (e) => {
		const token = localStorage.getItem("token");
		const plotlineData = {
			book_id: selectedBook.value,
			name: timelineName,
			description: timelineDescription,
			color_id: selectedColor.value,
			image_id: selectedFile?.id ?? null,
			shared_with: selectedUsers.map(user => user.email),
		};

		setLoading(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}/timelines`, plotlineData, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			})
			.then((response) => {
				const timelineId = response.data.data.id;
				const collaboratorData = {
					email: selectedUsers.map(user => user.email),
					role: selectedUsers.map(user => user.role),
					collaboratable_type: 'timelines',
					collaboratable_id: timelineId
				};
				axios.post(`${process.env.REACT_APP_API_URL}/collaborators`, collaboratorData, {
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: "application/json",
					},
				})
				.then(() => {
					getTimelinesByBook(selectedBook);
					setSelectedBook(selectBook);
					setSelectedColor(selectColor);
					setSelectedFile(null);
					setTimelineName("");
					setTimelineDescription("");
					setError({});
					setLoading(false);
					setSelectedUsers([])
					forms.resetFields()
					close();
				})
				.catch((error) => {
					message.error("Failed to add collaborators");
					setLoading(false);
				});
			})
			.catch((error) => {
				if (error.response.status === 422) {
					setError(error.response.data.errors);
				} else if (error.response.status === 403) {
					message.error(error.response.data.message);
				} else {
					message.error("Something went wrong!");
				}
				setLoading(false);
			});
	};

	const handleCollaboratorRole = (email) => {
		setCurrentUser({ email: email, visible: true });
	};

	const closeCollaboratorRole = () => {
		setCurrentUser({ email: '', role: '', visible: false });
	};

	return (
		<Drawer
			title={"Add New Timeline"}
			placement="right"
			onClose={close}
			open={open}
			className="p-0 drawer"
			zIndex={1}
			autoFocus={false}
			mask={false}
		>
			<Form
				ref={form}
				form={forms}
				labelCol={{
					span: 24,
				}}
				wrapperCol={{
					span: 24,
				}}
				layout="vertical"
				initialValues={{
					remember: false,
				}}
				onFinish={saveTimeline}
				autoComplete="off"
				className="gx-signin-form gx-form-row0"
				style={{
					position: "relative",
				}}
			>
				<Form.Item label="Artwork">
					{showGallery && (
						<UserGallery
							setSelectedFile={setSelectedFile}
							setShowGallery={setShowGallery}
						/>
					)}
					<div className="artwork mt-3">
						<button
							className="btn-artwork"
							type="button"
							onClick={() => setShowGallery(!showGallery)}
						>
							<img src={selectedFile?.image_url ?? newBook} alt="Book Icon" />
						</button>
						{selectedFile && (
							<div className="artworkContainer mt-2">
								<button
									onClick={(e) => {
										e.preventDefault();
										setSelectedFile(null);
									}}
									type="button"
									className="btn-remove"
								>
									Remove
								</button>
							</div>
						)}
					</div>
					{error.image_url && (
						<div className="error text-danger">{error.image_url}</div>
					)}
				</Form.Item>
				<Form.Item
					label="Select Book"
					name="book_id"
					rules={[
						{
							required: true,
							message: "Please select a book",
						},
					]}
				>
					<Select
						options={[selectBook, ...books]}
						onChange={(e) => {
							setSelectedBook(e);
						}}
						isSearchable={true}
						className="select-book"
						value={selectedBook}
					/>
					{error.book_id && <span className="error">{error.book_id}</span>}
				</Form.Item>
				<Form.Item
					label="Name"
					name="name"
					rules={[
						{
							required: true,
							message: "Name is required",
						},
					]}
				>
					<TextInput
						id="name"
						name="name"
						maxLength={50}
						value={timelineName}
						onChange={(e) => setTimelineName(e.target.value)}
						error={error.name}
					/>
				</Form.Item>
				<Form.Item
					label="Description"
					name="description"
				>
					<TextAreaInput
						id="description"
						name="description"
						maxLength={2048}
						showCount={true}
						value={timelineDescription}
						error={error.description}
						onChange={(e) => setTimelineDescription(e.target.value)}
					/>
				</Form.Item>
				<Form.Item
					label="Select Color"
					name="color_id"
					rules={[
						{
							required: true,
							message: "Color is required",
						},
					]}
				>
					<Select
						options={colorOptions}
						onChange={(e) => {
							setSelectedColor(e);
						}}
						isSearchable={true}
						className="select-book"
						value={selectedColor}
					/>
					{error.color_id && <span className="error">{error.color_id}</span>}
				</Form.Item>
				<CollaborativeShareField
					label="Share Timeline"
					isRequired={false}
					handleCollaboratorRole={handleCollaboratorRole}
					closeCollaboratorRole={closeCollaboratorRole}
					Form={forms}
					users={users}
					selectedUsers={selectedUsers}
					setSelectedUsers={setSelectedUsers}
				/>
				<div className="buttons-container mt-5 d-flex space-between">
					<Button
						type="primary"
						className="mr-2"
						loading={loading}
						disabled={loading}
						htmlType="submit"
					>
						Save
					</Button>
					<Button type="ghost" onClick={() => close()}>
						Cancel
					</Button>
				</div>
			</Form>
			<Modal centered title="Select Collaborator Role" open={currentUser.visible && currentUser.email !== ''} onOk={closeCollaboratorRole} onCancel={closeCollaboratorRole}>
				<Form.Item label="Role" name="role" rules={[{ required: true, message: 'Please select a role' }]}>
					<AntSelect placeholder="Select a role" defaultValue={selectedUsers.find(item => item.email === currentUser.email)?.role} onSelect={(value) => { setSelectedUsers(selectedUsers.map(item => item.email === currentUser.email ? { ...item, role: value } : item)) }}>
						<AntSelect.Option value="viewer">Viewer</AntSelect.Option>
						<AntSelect.Option value="editor">Editor</AntSelect.Option>
					</AntSelect>
				</Form.Item>
			</Modal>
		</Drawer>
	);
};

export default AddTimelineDrawer;
