import React from "react";
import { Card, Button } from "antd";
import { CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom";

const SuccessSubscription = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const planName = new URLSearchParams(location.search).get('plan') || 'your plan';
  const handleGoToDashboard = () => {
    window.location.href = '/dashboard';
  };

  return (
    <div style={{ padding: '40px', backgroundColor: '#f0f2f5', width: '100%', height: "100vh", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Card size="default" style={{ width: '600px', textAlign: 'center', backgroundColor: '#fff', borderRadius: '10px', padding: '40px' }}>
        <CheckCircleOutlined style={{ fontSize: '48px', color: '#52c41a', marginBottom: '20px' }} />
        <h2 style={{ color: '#1890ff' }}>Subscription Successful</h2>
        <p>Thank you for subscribing to the {planName} plan! Your plan has been successfully activated.</p>
        <Button type="primary" style={{ marginTop: '20px' }} onClick={handleGoToDashboard}>Go to Dashboard</Button>
      </Card>
    </div>
  );
};

export default SuccessSubscription;
