import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiServices } from "../../services/apiServices/Api";
import { axiosInstance } from "../../services/apiServices/config";

export const getColorData = createAsyncThunk("colors/getColorData", async() => {
  const colorData = await apiServices.get(`/colors`).then((response) => {
    let data = response.data.map((obj) => ({
      ...obj,
      value: obj.id,
      label: obj.color,
    }));
    return data;
  });

  return colorData;
});

export const getTagsData = createAsyncThunk("colors/getTagsData", async(_, { getState }) => {
  const { colors } = getState().colors;
  const tagsData = await axiosInstance.get(`/tags`).then((response) => {
    let data = response.data.tags.map((item) => ({
      id: item.id,
      label: item.title,
      value: item.title,
      color: colors.find((color) => color.id === item.color_id),
    }));
    return data;
  });

  return tagsData;
});

const initialState = {
  colors: [],
  tags: [],
};

const ColorSlice = createSlice({
  name: "colors",
  initialState,
  reducers: {
    colorSliceReset: (state) => {
      Object.assign(state, initialState);
    },
    updateTags: (state, action) => {
      state.tags = action.payload;
    }
  },
  
  extraReducers: (builder) => {
    builder.addCase(getColorData.pending, (state) => {
      state.loading = true;
    })
    .addCase(getColorData.fulfilled, (state, action) => {
      state.loading = false;
      state.colors = action.payload;
    })
    .addCase(getColorData.rejected, (state, action) => {
      state.loading = true;
      state.error = action.payload;
    })
    .addCase(getTagsData.pending, (state) => {
      state.loading = true;
    })
    .addCase(getTagsData.fulfilled, (state, action) => {
      state.loading = false;
      state.tags = action.payload;
    })
    .addCase(getTagsData.rejected, (state, action) => {
      state.loading = true;
      state.error = action.payload;
    })
  },
});

const ColorReducer = ColorSlice.reducer;
export const { colorSliceReset, updateTags } = ColorSlice.actions;
export default ColorReducer;
