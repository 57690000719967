export const MenuButton = ({ id, onClick, color }) => {
	return (
		<button
			type="button"
			className="btn-transparent"
			onClick={onClick}
			id={id}
			style={{
				backgroundColor: "transparent",
				border: "none",
				outline: "none",
				cursor: "pointer",
				padding: 0,
			}}
		>
			<svg
				height="15px"
				width="15px"
				version="1.1"
				id="Capa_1"
				viewBox="0 0 25.094 25.094"
				transform="rotate(90)"
			>
				<g>
					<path
						style={{ fill: color }}
						d="M11.524,12.484c0,3.182-2.58,5.762-5.762,5.762S0,15.666,0,12.484s2.58-5.761,5.762-5.761 C8.944,6.723,11.524,9.303,11.524,12.484z M19.332,6.85c-3.182,0-5.765,2.577-5.765,5.76c0,3.185,2.584,5.761,5.765,5.761 c3.182,0,5.762-2.576,5.762-5.761C25.093,9.427,22.512,6.85,19.332,6.85z"
					></path>
				</g>
			</svg>
		</button>
	);
};
