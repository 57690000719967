import { Card, Avatar, Tooltip, Modal, Button, Input, Form, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./outline-chapter.css";
import AttachmentIcon from "../../../controls/icons/attachment-icon/AttachmentIcon";
import Comment from "../../../../assets/icons/OutlineComment.png";
import Menuu from "../../../../assets/icons/menu.svg";
import { CheckSquareOutlined, PlusSquareOutlined, UserOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../services/apiServices/config";
import { CiFileOn } from "react-icons/ci";
import { CommentsDrawer } from "../../../comments";
import { getTimeDuration } from "../../../../services/utilServices";

const OutlineChapterCard = ({ open, Data, tasks, desc, pill, showEditorModal = null, mod, hasEditor }) => {
    const { colors } = useSelector((state) => state.colors);
    const [image, setImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState(false);
    const [newComment, setNewComment] = useState("");
    const [comments, setComments] = useState(Data.comments);
    const [commentsCount, setCommentsCount] = useState({
        id: null,
        count: Data.comments?.length
    })

    const getColor = (colorId) => {
        const color = colors.find((c) => c.id === colorId);
        return color ? color.value : "#FFFFFF";
    };

    useEffect(() => {
        if (Data?.image) {
            setImage(`https://charliapp-v2.clickysoft.net/storage/gallery/${Data.image.user_id}/${Data.image.image}`);
        }
    }, [Data?.image]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showCommentDrawer = () => {
        setIsCommentDrawerOpen(true);
    };

    const handleCommentDrawerClose = () => {
        setIsCommentDrawerOpen(false);
    };

    const handleAddComment = async () => {
        if (newComment.trim()) {
            const postComments = await axiosInstance.post('/comments', {
                comment_type: "chapter_card",
                comment: newComment,
                chapter_card_id: Data.id
            })
            if (postComments.data) {
                setComments([...comments, { id: Data.comments.length + 1, author: 'You', comment: newComment }]);
                setCommentsCount({
                    id: Data.id,
                    count: comments.length + 1
                })
                setNewComment("");
            }

        }
    };

    useEffect(()=> {
        setComments(Data.comments)
        setCommentsCount({
            id: Data.id,
            count: Data.comments.length
        })
    },[Data])

    return (
        <>
            <Card
                style={{
                    borderRadius: "5px",
                    cursor: "pointer",
                    backgroundColor: getColor(Data.color_id),
                    marginBottom: "20px",
                    padding: "15px",
                }}
                className="mt-2"
                onClick={Data.viewCard}
            >
                <CardHeader pill={pill} Data={Data} open={open} showEditorModal={showEditorModal} mod={mod} hasEditor={hasEditor}/>
                <CardContent desc={desc} tasks={tasks} Data={Data} image={image} />
                <CardFooter Data={Data} comments={Data.comments} showModal={showModal} showCommentDrawer={showCommentDrawer} commentsCount={commentsCount} />
            </Card>
            <AttachmentsModal isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} attachments={Data.attachments} />
            <CommentsDrawer
                mod={mod} 
                hasEditor={hasEditor}
                isCommentDrawerOpen={isCommentDrawerOpen}
                handleCommentDrawerClose={handleCommentDrawerClose}
                comments={comments}
                newComment={newComment}
                setNewComment={setNewComment}
                handleAddComment={handleAddComment}
            />
        </>
    );
};

const CardHeader = ({ pill, Data, open, showEditorModal, mod, hasEditor }) => (
    <div
        style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px"
        }}
    >
        <p
            style={{ backgroundColor: Data.color_code, color: Data.foreground_color, borderRadius: "5px", fontSize: "12px" }}
            id="tag"
        >
            {pill}
        </p>
       {mod !== 'guest' && <img
            onClick={Data.open}
            src={Menuu}
            alt="Menu Icon"
            id={Data.id}
            style={{ cursor: "pointer", width: "20px", height: "20px" }}
            onClickCapture={showEditorModal ?? open}
        />}
    </div>
);

const CardContent = ({ desc, tasks, Data, image }) => (
    <div>
        <p id="desc" style={{ marginBottom: "10px", fontSize: "14px", color: "#333" }}>{desc}</p>
        {tasks?.length > 0 && (
            <div>
                <p id="task_type" style={{ fontSize: "12px", fontWeight: "bold", marginBottom: "5px" }}>Tasks: {Data.task_type}</p>
                <div className="todos" style={{ marginBottom: "10px" }}>
                    {tasks.map((task) => (
                        <div
                            key={task.id}
                            style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", marginBottom: "5px" }}
                        >
                            <div className="d-flex" style={{ flex: "1 1 auto", minWidth: "200px" }}>
                                <span className="mr-1" style={{ color: task.status === 'in_progress' ? '#1890ff' : '#52c41a', fontSize: '16px' }}>
                                    {task.status === 'in_progress' ? <PlusSquareOutlined /> : <CheckSquareOutlined />}
                                </span>
                                <p className="mt-1" style={{ fontWeight: "bold", wordBreak: "break-word", marginLeft: "5px" }}>
                                    {task?.title}
                                </p>
                            </div>
                            <p id="due_date" style={{ flex: "0 1 auto", minWidth: "100px", textAlign: "right", fontSize: "12px", color: "#666" }}>Due Date: {getTimeDuration(task.due_date)}</p>
                        </div>
                    ))}
                </div>
            </div>
        )}
        <div className="mb-1">
            {Data.image && (
                <img
                    src={image}
                    alt="cardpicture"
                    id="cardphoto"
                    style={{ width: "100%", marginTop: "10px", borderRadius: "5px" }}
                />
            )}
        </div>
    </div>
);

const CardFooter = ({ Data, comments, showModal, showCommentDrawer,commentsCount }) => (
    <div className="d-flex justify-between" style={{ marginTop: "10px" }}>
        <div className="card-icons mt-2 d-flex">
            <div className="d-flex" onClick={showModal} style={{ cursor: "pointer", alignItems: "center", marginRight: "10px" }}>
                <AttachmentIcon />
                <p className="mr-2" style={{ marginLeft: "5px", fontSize: "12px", color: "#666" }}>{Data.attachments.length}</p>
            </div>
            <div className="d-flex" onClick={showCommentDrawer} style={{ cursor: "pointer", alignItems: "center" }}>
                <img src={Comment} alt="comment" style={{ width: "20px", height: "20px" }} />
                <p style={{ marginLeft: "5px", fontSize: "12px", color: "#666" }}>{commentsCount.count ?? comments?.length}</p>
            </div>
        </div>
        <div className="avatars d-flex ml-5 mt-2" style={{ display: 'flex', justifyContent: 'flex-end', gap: '6px' }}>
            {Data.tasks.map(i => i.collaboration[0]).length > 0 && Data.tasks.map(i => i.collaboration[0])?.slice(0, 5)?.map((user) => {
                console.log(user)
                return (
                    <Avatar
                        key={user?.id}
                        size="default"
                        src={user?.profile_image_url}
                        icon={!user?.image && <UserOutlined />}
                    />
                );
            })}
            {Data.tasks.map(i => i.collaboration[0])?.length > 5 && (
                <Tooltip title={`${Data.collaborators.length - 5} more`}>
                    <Avatar size="default" style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }}>
                        +{Data.collaborators.length - 5}
                    </Avatar>
                </Tooltip>
            )}
        </div>
    </div>
);

const getFileDetails = (filePath) => {
    const parts = filePath.split('/');
    const fileName = parts.pop();
    const extension = fileName.split('.').pop().toLowerCase();
    const name = fileName.replace(`.${extension}`, '').replace(/[-_]/g, ' ');
    let type;
    switch (extension) {
        case 'zip':
            type = 'ZIP File';
            break;
        case 'pdf':
            type = 'PDF File';
            break;
        default:
            type = 'File';
    }
    return { name: name.substring(0, 30) + (name.length > 31 ? '...' : ''), type };
};

const AttachmentsModal = ({ isModalOpen, handleOk, handleCancel, attachments }) => (
    <Modal centered title="Attachments" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <div style={{ padding: "20px", backgroundColor: "#f0f2f5", borderRadius: "10px" }}>
            <p style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>Here you can find the attachments:</p>
            <ul style={{ listStyleType: "none", padding: 0 }}>
                {attachments.length > 0 && attachments.map((item) => {
                    const { name, type } = getFileDetails(item.attachment);
                    return (
                        <li style={{ marginBottom: "15px", display: "flex", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "10px" }}>
                            <CiFileOn size={'23px'} />                           
                             <span style={{ flex: 1, fontSize: "14px", color: "#555" }}>{name} ({type})</span>
                            <a href={`${item.attachment_url}`} target="_blank" rel="noopener noreferrer">
                                <button style={{ padding: "8px 15px", borderRadius: "5px", backgroundColor: "#007bff", color: "#fff", border: "none", cursor: "pointer", fontSize: "14px" }}>
                                    Download
                                </button>
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    </Modal>
);

export default OutlineChapterCard;
