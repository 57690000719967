import React, { useEffect, useState } from "react";
import { Card, Button, Switch, message, Spin, Modal } from "antd";
import { axiosInstance } from "../services/apiServices/config";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../redux/Slice/AuthSlice";

const Plains = () => {

  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const [isYearly, setIsYearly] = useState(false);
  const [packagesA, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const purchasedPlan = user[0]?.user?.package; 
  const purchasePlanType = user[0]?.user?.subscription_details || {};

  console.log(purchasePlanType)
  const handleToggle = (checked) => {
    setIsYearly(checked);
  };

  const handlePayment = async (plan) => {
    try {
      if (token) {
        window.open(`https://charliapp-v2.clickysoft.net/goto-subscribe/${token}/${plan}`, '_blank');
      } else {
        navigate('/register');
      }
    } catch (error) {
      message.error("Payment error: " + error.message);
    }
  };

  const handleCancel = async () => {
    try {
      const res = await axiosInstance.post('/user/cancel-subscription', { device_type: "web" });
      if (res.data.success) {
        message.success("Subscription cancelled successfully.");
        await dispatch(getCurrentUser());
      } else {
        message.error("Error cancelling subscription: " + res.data.message);
      }
    } catch (error) {
      message.error("Error cancelling subscription: " + error.message);
    }
  };

  const fetchPackages = async () => {
    try {
      const res = await axiosInstance.get('/packages');
      setPackages(res.data.data);
    } catch (error) {
      message.error("Error fetching packages: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  if (loading) {
    return (
      <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: '40px', backgroundColor: '#f0f2f5', width: '100%', height: "100vh", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      {packagesA.length > 0 && <div style={{ marginBottom: '20px' }}>
        <span>Monthly</span>
        <Switch checked={isYearly} onChange={handleToggle} style={{ margin: '0 10px' }} />
        <span>Yearly</span>
      </div>}
      <div style={{ display: 'flex', justifyContent: 'center', gap: '40px', flexWrap: 'wrap' }}>
        {packagesA.length > 0 && packagesA.map((pkg, index) => (
          <Card key={index} size="default" style={{ width: '300px', textAlign: 'center', backgroundColor: '#fff', borderRadius: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <h2 style={{ color: '#1890ff' }}>{pkg.package_name}</h2>
              <p>{pkg.description}</p>
              <h3>${isYearly ? pkg.price_yearly : pkg.price_monthly}{isYearly ? "/year" : "/month"}</h3>
              <p>{pkg.discount}</p>
              <ul style={{ textAlign: 'left', padding: '0 20px' }}>
                {pkg.options.map((feature, i) => (
                  typeof feature === 'string' ? (
                    <li key={i}>{feature}</li>
                  ) : (
                    <li key={i} style={{ textDecoration: feature.available ? 'none' : 'line-through', color: feature.available ? 'inherit' : '#ccc' }}>{feature.option_name}</li>
                  )
                ))}
              </ul>
              <p>100% money back guarantee if you cancel within 7 days of purchase.</p>
            </div>
            {purchasedPlan === pkg.package_name && (!purchasePlanType.subscription_type || purchasePlanType.subscription_type === (isYearly ? "Yearly" : "Monthly")) ? (
              <Button type="primary" style={{ marginTop: '20px' }} onClick={handleCancel}>
                Cancel Subscription
              </Button>
            ) : (
              <Button type="primary" style={{ marginTop: '20px' }} onClick={() => handlePayment(isYearly ? pkg.paypal_yearly_plan_id : pkg.paypal_monthly_plan_id)} disabled={purchasedPlan !== pkg.package_name && purchasedPlan !== null}>
                Get Started
              </Button>
            )}
          </Card>
        ))}
      </div>
      {Object.keys(purchasePlanType).length > 0 && (
        <>
          <Button type="primary" style={{ marginTop: '20px' }} onClick={showModal}>
            View Subscription Details
          </Button>
          <Modal 
            title={<h2 style={{ color: '#1890ff', textAlign: 'center' }}>Subscription Details</h2>} 
            centered 
            visible={isModalVisible} 
            onCancel={handleModalCancel} 
            footer={null}
            bodyStyle={{ padding: '20px', backgroundColor: '#f0f2f5', borderRadius: '10px' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
             <p style={{ fontSize: '16px', marginBottom: '10px' }}><strong>Package Name:</strong> {purchasedPlan}</p>
              <p style={{ fontSize: '16px', marginBottom: '10px' }}><strong>Status:</strong> {purchasePlanType.status}</p>
              <p style={{ fontSize: '16px', marginBottom: '10px' }}><strong>Subscription Type:</strong> {purchasePlanType.subscription_type}</p>
              <p style={{ fontSize: '16px', marginBottom: '10px' }}><strong>Start Time:</strong> {new Date(purchasePlanType.start_time).toLocaleString()}</p>
              <p style={{ fontSize: '16px', marginBottom: '10px' }}><strong>Next Billing Time:</strong> {new Date(purchasePlanType.next_billing_time).toLocaleString()}</p>
              <p style={{ fontSize: '16px', marginBottom: '10px' }}><strong>Last Payment Amount:</strong> ${purchasePlanType.last_payment.amount.value}</p>
              <p style={{ fontSize: '16px', marginBottom: '10px' }}><strong>Last Payment Time:</strong> {new Date(purchasePlanType.last_payment.time).toLocaleString()}</p>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Plains;
