import React from "react";

const RoundDetail = ({ round }) => {
	return (
		<>
			<p>
				<b>
					{round.title} - {round.playtime}
				</b>
			</p>
			<hr />
			<p style={{ whiteSpace: "pre-wrap" }}>{round.transcript}</p>
		</>
	);
};
export default RoundDetail;
