import React, { useEffect, useState } from "react";
import { getBooks, getOutlines } from "../../helpers/GetList.js";
import { mapFeaturesToButtons } from "../../components/PackageSubscriptionHook.js";
import axios from "axios";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";

const useOutlines = (id) => {
	const dispatch = useDispatch();
	const { outlineData } = useSelector((state) => state.outline);
	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [outlines, setOutlines] = useState([]);
	const [loading, setLoading] = useState(true);
	const defaultSelectBook = {
		value: "",
		label: "-- Any Book --",
	};
	const [selectedBook, setSelectedBook] = useState(defaultSelectBook);
	const [showAddNewDrawer, setShowAddNewDrawer] = useState(false);
	const [showEditDrawer, setShowEditDrawer] = useState(false);
	const [selectedOutline, setSelectedOutline] = useState(null);
	const [books, setBooks] = useState([]);
	const [subscriptionModal, setSubscriptionModal] = useState(false);
	const [controlledButtons, setControlledButtons] = useState({
		createOutline: false,
		feature: "",
	});
	const [clickedButton, setClickedButton] = useState([]);
	const token = localStorage.getItem("token");

	useEffect(() => {
		getBooks(id, setSelectedBook, setBooks);
		setLoading(true);
		getOutlines(id, 1, setOutlines, setTotalRecords, dispatch).then(()=> setLoading(false))
	}, [id]);

	const getOutlineByBook = (book) => {
		setSelectedBook(book);
		setLoading(true);
		getOutlines(book.value, 1, setOutlines, setTotalRecords, dispatch)
		setCurrentPage(1);
	};

	const handlePageClick = (page) => {
		setCurrentPage(page);
		setLoading(true);
		getOutlines(selectedBook?.value, page, setOutlines, setTotalRecords, dispatch)
	};

	const editOutline = (id) => {
		if (controlledButtons.createOutline) {
			axios(`${process.env.REACT_APP_API_URL}/outlines/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			})
				.then((response) => {
					setSelectedOutline(response.data.data);
					setShowEditDrawer(true);
				})
				.catch((error) => console.log(error));
		} else {
			setClickedButton([{ feature: "outline-feature" }]);
			openSubscriptionModal();
		}
	};

	const deleteOutline = (id) => {
		if (controlledButtons.createOutline) {
			axios
				.delete(`${process.env.REACT_APP_API_URL}/outlines/${id}`, {
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: "application/json",
					},
				})
				.then(() => {
					setLoading(true);
					getOutlines(selectedBook?.value, 1, setOutlines, setTotalRecords, dispatch)
					setCurrentPage(1);
				})
				.catch(() => message.error("Could not delete timeline."));
		} else {
			setClickedButton([{ feature: "outline-feature" }]);
			openSubscriptionModal();
		}
	};

	const checkSubscription = (action) => {
		const featureMap = {
			"outline-feature": "createOutline",
		};
		const updatedButtons = mapFeaturesToButtons(featureMap, action);
		setControlledButtons(updatedButtons);
	};

	const openSubscriptionModal = () => {
		setSubscriptionModal(true);
	};

	return {
		totalRecords,
		currentPage,
		outlines,
		outlineData,
		selectedBook,
		showAddNewDrawer,
		showEditDrawer,
		selectedOutline,
		books,
		subscriptionModal,
		controlledButtons,
		clickedButton,
		loading,
		setShowAddNewDrawer,
		setShowEditDrawer,
		setSubscriptionModal,
		setClickedButton,
		getOutlineByBook,
		handlePageClick,
		editOutline,
		deleteOutline,
		checkSubscription,
		openSubscriptionModal,
	};
};

export { useOutlines };