    import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
    import { axiosInstance } from "../../services/apiServices/config";

    const initialState = {
        sharedWorks: [],
        outlines: {},
        plotlines: {},
        brainStorms: {},
        timelines: {},
        loading: false,
        error: null,
    };

    export const hasEditorAccess = (items, id, sharedId) => {
        if (items && items[sharedId]) {
            const existInShared = items[sharedId].data?.filter(i => i.id == id);
            if (existInShared) {
                return existInShared.some(item => item.editor);
            }
        }
        return true;
    };

    export const getSharedWorks = createAsyncThunk('sharedWork/getSharedWorks', async (page = 1 ) => {
        const response = await axiosInstance.get(`/collaborators/shared/listing?page=${page}`);
        return response.data;
    });

    export const getSharedSpecific = createAsyncThunk('sharedWork/getSharedSpecific', async ({ type, collaborator_id, page }) => {
        const response = await axiosInstance.get(`/collaborators/shared/specific/listing?page=${page}&book_id=${collaborator_id}&type=${type}`);
        console.log(response.data.data, type  );
        return { type, data: response.data, collaborator_id };
    });

    const sharedWorkSlice = createSlice({
        name: 'sharedWork',
        initialState,
        reducers: {
            setSharedWorks: (state, action) => {
                state.sharedWorks = action.payload;
            },
        },
        extraReducers: (builder) => {
            builder.addCase(getSharedWorks.pending, (state) => {
                state.loading = true;
            });
            builder.addCase(getSharedWorks.fulfilled, (state, action) => {
                state.loading = false;
                state.sharedWorks = action.payload;
            });
            builder.addCase(getSharedWorks.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
            builder.addCase(getSharedSpecific.pending, (state) => {
                state.loading = true;
            });
            builder.addCase(getSharedSpecific.fulfilled, (state, action) => {
                state.loading = false;
                const { type, data, collaborator_id } = action.payload;
                const editorAccess = data.editor;
                switch (type) {
                    case 'outlines':
                        state.outlines[collaborator_id] = { data: data.data, editor: editorAccess };
                        break;
                    case 'plot_planners':
                        state.plotlines[collaborator_id] = { data: data.data, editor: editorAccess };
                        break;
                    case 'brainstorms':
                        state.brainStorms[collaborator_id] = { data: data.data, editor: editorAccess };
                        break;
                    case 'timelines':
                        state.timelines[collaborator_id] = { data: data.data, editor: editorAccess };
                        break;
                    default:
                        break;
                }
            });
            builder.addCase(getSharedSpecific.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        }
    });

    export const { setSharedWorks } = sharedWorkSlice.actions;
    const SharedWorkReducer = sharedWorkSlice.reducer;
    export default SharedWorkReducer;