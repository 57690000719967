import React, { useState, useCallback } from "react";
import PropTypes from 'prop-types';
import ContactItem from "./ContactItem";
import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CreateNewGroup from "../CreateNewGroup";

const ContactList = ({ contacts, onlineUsers, styles }) => {
	const [showCreateGroup, setShowCreateGroup] = useState(false);

	const handleCreateGroupClick = useCallback(() => {
		setShowCreateGroup(true);
	}, []);

	const handleCancel = useCallback(() => {
		setShowCreateGroup(false);
	}, []);

	return (
		<>
			<div className={styles.contactsList}>
				{contacts.length > 0 ? (
					contacts.map((contact) => (
						<ContactItem
							styles={styles}
							key={contact.id}
							isOnline={
								contact.contact_type === "User" &&
								onlineUsers.includes(contact.contact_id.toString())
							}
							contact={contact}
						/>
					))
				) : (
					<p>No contacts available</p>
				)}
			</div>
			{showCreateGroup && (
				<CreateNewGroup styles={styles} onCancel={handleCancel} />
			)}
		</>
	);
};

ContactList.propTypes = {
	contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
	onlineUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
	styles: PropTypes.object.isRequired,
};

export default ContactList;
