import { ACTIVITY_LOGS } from "../../constant/constant";
import "./index.css";
import ListCard from "../../components/ListCard";
import { ActivityLog } from "../../components/cards/ActivityLog";
import { Col, Row, Pagination, Spin } from "antd";
import { useShared } from "../../hooks/shared/useShared";
import { useState } from "react";
import { DashboardActivityLog } from "../../components/cards/DashboardActivityLog";
import { axiosInstance } from "../../services/apiServices/config";
import { setBookSelice } from "../../redux/Slice/BookSlice";
import { CommentsDrawer } from "../../components/comments";
import { setSharedWorks } from "../../redux/Slice/SharedWork";
import FullPageSpinner from "../../components/loader/FullPageSpinner";
const Shared = () => {
    const {
        dispatch,
        book_data,
        sharedWorks,
        handleBookClick,
        currentPage,
        itemsPerPage,
        handlePageChange,
        totalPages,
        loading,
        activityLogs,
        loadingActivityLogs
    } = useShared();

    const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState({ isOpen: false, id: null });
    const [commentsState, setCommentsState] = useState({});
    const [newCommentState, setNewCommentState] = useState("");
  
    const showCommentDrawer = (comments, id) => {
      setCommentsState(prevState => ({
        ...prevState,
        [id]: comments.map(i => ({ author: 'you', comment: i.comment }))
      }));
      setIsCommentDrawerOpen({ id, isOpen: true });
    };
  
    const handleCommentDrawerClose = () => {
      setIsCommentDrawerOpen({ isOpen: false, id: null });
    };
  
    const handleAddComment = async () => {
      if (newCommentState.trim()) {
        const postComments = await axiosInstance.post('/comments', {
          comment_type: "book",
          comment: newCommentState,
          book_id: isCommentDrawerOpen.id
        });
        if (postComments.data) {
          const item = sharedWorks.data.find(i => i.id == isCommentDrawerOpen.id);
          if (item) {
            const updatedComments = [...item.comments, { id: item.comments.length + 1, author: 'You', comment: newCommentState }];
            const updatedItem = { ...item, comments: updatedComments };
            const updatedOutlineData = sharedWorks.data.map(i => i.id === isCommentDrawerOpen.id ? updatedItem : i);
            const updatedSharedWorks = { ...sharedWorks, data: updatedOutlineData };
            dispatch(setSharedWorks(updatedSharedWorks));
          }
          setCommentsState(prevState => ({
            ...prevState,
            [isCommentDrawerOpen.id]: [...prevState[isCommentDrawerOpen.id], { id: prevState[isCommentDrawerOpen.id].length + 1, author: 'You', comment: newCommentState }]
          }));
          setNewCommentState("");
        }
      }
    };

    const booksContentSection = () => (
        <>
            <h2 className="sub-title">Books:</h2>
            <div className="p-3">
                {loading && sharedWorks?.data?.length === 0 ? <FullPageSpinner/>
                : sharedWorks?.data?.length > 0 ? sharedWorks?.data?.map((book) => (
                    <div
                        onClick={() => handleBookClick(book.id)}
                        style={{ cursor: "pointer" }}
                        key={book.id}
                    >
                        <ListCard
                            isShared={true}
                            user={book.user}
                            style={{
                                backgroundColor: book.color_code,
                                color: book.foreground_color,
                            }}
                            listType="activity"
                            foreground_color={book.foreground_color}
                            color_code={book.color_code}
                            id={book.id}
                            name={book.book_name}
                            description={book.book_description}
                            type="book"
                            icon={book.image_url}
                            updated={book.updated_at}
                            image_url={book.user.profile_image_url}
                            comments={book.comments}
                            showCommentDrawer={() => showCommentDrawer(book.comments, book.id)}
                        />
                    </div>
                )) : (!loading && <div className="no-activity-message" style={{ padding: '20px 34px' }}>
                    <p>No Shared available.</p>
                </div>)}
            </div>
            {sharedWorks.data?.length > 0 && <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={totalPages * itemsPerPage}
                onChange={handlePageChange}
                disabled={loading}
            />}
            <CommentsDrawer
				mod="auther"
				hasEditor={true}
				isCommentDrawerOpen={isCommentDrawerOpen.isOpen}
				handleCommentDrawerClose={handleCommentDrawerClose}
				comments={commentsState[isCommentDrawerOpen.id] || []}
				newComment={newCommentState}
				setNewComment={setNewCommentState}
				handleAddComment={handleAddComment}
			/>
        </>
    );
    const activityLogSection = () => (
        <>
            <div className="display-flex space-between ml-5 mr-5">
                <h2 className="sub-title">Activity log</h2>
            </div>
            {loadingActivityLogs ? (
                <Spin
                    tip="Loading"
                    size="large"
                    className="display-flex center"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100px",
                    }}
                ></Spin>
            ) : activityLogs.length === 0 && !loadingActivityLogs ? (
                <div className="no-activity-message" style={{ padding: '20px 34px' }}>
                    <p>No activity logs available.</p>
                </div>
            ) : (
                activityLogs.map((item, index) => (
                    <DashboardActivityLog logsData={item} key={index} />
                ))
            )}
        </>
    );
    return (
        <div className="main-container">
            <div className="display-flex">
                <div className="screen-title dark-black mr-3">Collaborative Share</div>
            </div>
            <Row>
                <Col xs={24} sm={24} md={17} lg={17} xl={17} xxl={17}>
                    <div className="left-section">{booksContentSection()}</div>
                </Col>
                <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                    <div className="activity-section">{activityLogSection()}</div>
                </Col>
            </Row>
        </div>
    );
};
export default Shared;