import React, { useContext, useRef, useState } from "react";

import "../../../components/cards/index.scss";
import Select from "react-select";
import { useParams } from "react-router-dom";
import CustomButton from "../../../components/button/Button.js";
import CreateBookIcon from "../../../assets/icons/create-book.svg";
import OutlineCard from "../../../components/outlines/OutlineCard.js";
import PackageSubscriptionHook from "../../../components/PackageSubscriptionHook.js";
import { HeaderDataContext } from "../../../context/HeaderContext.js";
import AddOutlineDrawer from "../../../components/outlines/AddOutlineDrawer.js";
import EditOutlineDrawer from "../../../components/outlines/EditOutlineDrawer.js";
import { Pagination } from "antd";
import { SubscriptionModal } from "../../../components/subscriptionodal.js";
import { useOutlines } from "../../../hooks/outline/useOutlines.js";
import { CommentsDrawer } from "../../../components/comments";
import { axiosInstance } from "../../../services/apiServices/config";
import { useDispatch } from "react-redux";
import { setOutlinesBySeliece } from "../../../redux/Slice/OutlineSlice.js";
import FullPageSpinner from "../../../components/loader/FullPageSpinner.js";

const Outlines = () => {
	const ref = useRef(null);
	const header = useContext(HeaderDataContext);
	header.setHeading("Outlines");
	const { id } = useParams();
	const {
		totalRecords,
		currentPage,
		outlines,
		outlineData,
		selectedBook,
		showAddNewDrawer,
		showEditDrawer,
		selectedOutline,
		books,
		subscriptionModal,
		controlledButtons,
		clickedButton,
		loading,
		setShowAddNewDrawer,
		setShowEditDrawer,
		setSubscriptionModal,
		setClickedButton,
		getOutlineByBook,
		handlePageClick,
		editOutline,
		deleteOutline,
		checkSubscription,
		openSubscriptionModal
	} = useOutlines(id);

	const defaultSelectBook = {
		value: "",
		label: "-- Any Book --",
	};

	const OutlineToDisplay = selectedBook.value === '' ? outlineData : outlines;

	const dispatch = useDispatch();
	const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState({isOpen: false, id: null});
	const [commentsState, setCommentsState] = useState({});
	const [newCommentState, setNewCommentState] = useState("");

	const showCommentDrawer = (comments, id) => {
		setCommentsState(prevState => ({
			...prevState,
			[id]: comments.map(i => ({author: 'you', comment: i.comment}))
		}));
		setIsCommentDrawerOpen({id, isOpen: true});
	};

	const handleCommentDrawerClose = () => {
		setIsCommentDrawerOpen({isOpen: false, id: null});
	};

	const handleAddComment = async () => {
		if (newCommentState.trim()) {
			const postComments = await axiosInstance.post('/comments', {
				comment_type: "outline",
				comment: newCommentState,
				outline_id: isCommentDrawerOpen.id
			});
			if (postComments.data) {
				const item = outlineData.find(i => i.id == isCommentDrawerOpen.id);
				if (item) {
					const updatedComments = [...item.comments, { id: item.comments.length + 1, author: 'You', comment: newCommentState }];
					const updatedItem = { ...item, comments: updatedComments };
					const updatedOutlineData = outlineData.map(i => i.id === isCommentDrawerOpen.id ? updatedItem : i);
					dispatch(setOutlinesBySeliece(updatedOutlineData));
				}
				setCommentsState(prevState => ({
					...prevState,
					[isCommentDrawerOpen.id]: [...prevState[isCommentDrawerOpen.id], { id: prevState[isCommentDrawerOpen.id].length + 1, author: 'You', comment: newCommentState }]
				}));
				setNewCommentState("");
			}
		}
	};

	return (
		<div className="main-container">
			<PackageSubscriptionHook
				feature={["outline-feature"]}
				ref={ref}
				title="Outline"
				checkPackageSubscription={checkSubscription}
			/>
			<div className="list-actions">
				<div className="addnew">
					<CustomButton
						type="primary"
						buttonTitle="Add Outline"
						onClick={() => controlledButtons.createOutline ? setShowAddNewDrawer(true) : (setClickedButton([{feature: "outline-feature"}]), openSubscriptionModal())}
						icon={<img src={CreateBookIcon} alt="New Outline" />}
					/>
				</div>
				{books.length > 0 && (
					<div className="filter">
						<label>Select book: </label>
						<Select
							options={[defaultSelectBook, ...books]}
							onChange={getOutlineByBook}
							isSearchable={true}
							className="select-book"
							value={selectedBook}
						/>
					</div>
			)}
			</div>
			<div className="card-container mt-2">
				<div className="p-3">
					{loading && OutlineToDisplay.length === 0 ? <FullPageSpinner/>
					:   OutlineToDisplay.length === 0 ? (
						<h4 style={{ textAlign: "center" }}>
							No outline to display, click on Add Outline to add new.
						</h4>
					) : (
						OutlineToDisplay.map((outline) => {
							return (
								<OutlineCard
									key={outline.id}
									outline={outline}
									style={{
										backgroundColor: outline.color_code,
										color: outline.foreground_color,
										cursor: "pointer",
									}}
									editOutline={editOutline}
									deleteOutline={deleteOutline}
									showCommentDrawer={() => showCommentDrawer(outline.comments, outline.id)}
									comments={outline.comments}
									commentsCount={outline.comments.length ?? 0}
								/>
							);
						})
					)}
				</div>
				<Pagination
					rootClassName="list-pagination"
					total={totalRecords}
					current={currentPage}
					onChange={handlePageClick}
				/>
			</div>

			<AddOutlineDrawer
				open={showAddNewDrawer}
				close={() => setShowAddNewDrawer(false)}
				books={books}
				getOutlineByBook={getOutlineByBook}
			/>
			<EditOutlineDrawer
				outline={selectedOutline}
				open={showEditDrawer}
				close={() => setShowEditDrawer(false)}
				books={books}
				getOutlineByBook={getOutlineByBook}
			/>
			<SubscriptionModal
				open={subscriptionModal}
				ok={openSubscriptionModal}
				features={clickedButton}
				cancel={() => setSubscriptionModal(false)}
			/>
			<CommentsDrawer
				mod="auther"
				hasEditor={true}
				isCommentDrawerOpen={isCommentDrawerOpen.isOpen}
				handleCommentDrawerClose={handleCommentDrawerClose}
				comments={commentsState[isCommentDrawerOpen.id] || []}
				newComment={newCommentState}
				setNewComment={setNewCommentState}
				handleAddComment={handleAddComment}
			/>
		</div>
	);
};

export default Outlines;
